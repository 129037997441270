
import React, { useState } from "react";
import image from '../images/Krece-logo_white.png';
import { AppBar, Toolbar, Typography, Container, Button, TextField, makeStyles, MenuItem } from '@material-ui/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import auth from '../auth'
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { BeatLoader } from 'react-spinners';



const LoginForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  let [authMode, setAuthMode] = useState("signin")
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginData, setLoginData] = useState({
    storeEmail: '',
    password: '',
    password2: ''
  });
  const [loginError, setLoginError] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState('')

  const changeAuthModeToSignin = () => {
    setAuthMode("signin")
  }

  const changeAuthModeToRegister = () => {
    setAuthMode("signup")
  }


  const changeAuthModeToForgotPassword = () => {
    setAuthMode("password_recovery")
  }
  const handleLoginInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSignUp = () => {
    try {
      if (!loginData.storeEmail || !loginData.password || !loginData.password2) {
        setLoginError('Por favor, rellene todas las casillas.');
        return;
      } else if (loginData.password !== loginData.password2) {
        setLoginError('Las contraseñas no coinciden.');
        return;
      }
      setLoginError('')
      setIsLoading(true)
      const storeRef = firestore.collection('Stores').doc(loginData.storeEmail.toLowerCase())

      storeRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            auth.createUserWithEmailAndPassword(loginData.storeEmail.toLowerCase(), loginData.password)
              .then((userCredential) => {
                navigate("/");
                setIsLoading(false)
                console.log('signed in')
                // ...
              })
              .catch((error) => {
                const errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorMessage)
                // ..
                if (errorMessage.includes('another')) {
                  errorMessage = "Ya existe una tienda con este email. Por favor inicie sesión."
                } else {
                  errorMessage = "Tiene problemas de conexión, vuelva a intentar más tarde."
                }
                setIsLoading(false)
                setLoginError(errorMessage);
              });
          } else {
            setLoginError("Esta tienda no ha sido autorizada por Krece. Para afiliar su tienda por favor comuníquese con nosotros al +58 4149022115.");
            setIsLoading(false)
          }
        });
    } catch (err) {
      setLoginError("Ocuriió un problema, probablemente tenga mala conexión.")
      setIsLoading(false)
    }
  }

  const handleLogin = () => {
    try {
      if (!loginData.storeEmail || !loginData.password) {
        setLoginError('Por favor, rellene todas las casillas.');
        return;
      }
      setLoginError('');
      setIsLoading(true)
      const storeRef = firestore.collection('Stores').doc(loginData.storeEmail.toLowerCase())

      storeRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            auth.signInWithEmailAndPassword(loginData.storeEmail.toLowerCase(), loginData.password)
              .then((userCredential) => {
                console.log('signed in');
                navigate("/");
                setIsLoading(false);
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setIsLoading(false)
                if (errorMessage.includes('network')) {
                  setLoginError("Mala conexión: Por favor chequee su conexión a internet.")
                } else {
                  setLoginError("Email o contraseña incorrecta.")
                }
              });
          } else {
            setLoginError("Esta tienda no tiene cuenta con Krece, por favor cree una cuenta.");
            setIsLoading(false)
          }
        });
    } catch (err) {
      setLoginError("Ocurrió un problema, probablemente tenga mala conexión.")
      setIsLoading(false)
    }
  };

  const handleResetPassword = () => {
    if (!loginData.storeEmail) {
      setLoginError("Por favor, rellene todas las casillas");
      return;
    }
    setLoginError('')
    try {
      auth.sendPasswordResetEmail(loginData.storeEmail.toLowerCase()).then(function () {
        setInfoMessage('Le enviamos un email para resetear su contraseña.');
      }).catch(function (error) {
        console.log(error);
        setLoginError("No existe una cuenta con este email.");
      });
    } catch (err) {
      console.log(err);
      setLoginError("Ocurrió un problema, probablemente tenga mala conexión.");
    }
  }

  const goToPage = (x) => {
    setInfoMessage('')
    setLoginError('')
    setLoginData({
      storeEmail: '',
      password: '',
      password2: ''
    });
    setPage(x);
  }

  if (page == 1) {
    return (
      <div>
        <Container maxWidth="sm" style={{
          marginTop: 50,
          marginBottom: 50,
          marginHorizontal: 10,
          boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
          paddingTop: '30px',
          paddingBottom: '20px',
          borderRadius: '8px',
          backgroundColor: '#1C1C1E',
          alignItems: 'center'
        }}>
          <div style={{
            padding: 20, justifyContent: 'center', marginBottom: 30, display: 'flex'
          }}>
            <img style={{ alignSelf: 'center', width: 250 }} src={image} alt="Krece logo" />
          </div>
          <Typography variant="h4" align="center" className={classes.title} gutterBottom>
            Iniciar Sesión
          </Typography>
          <form>
            <TextField
              label="Email de la tienda"
              type="storeEmail"
              name="storeEmail"
              value={loginData.storeEmail}
              onChange={handleLoginInputChange}
              // variant="outlined"
              className={classes.textFields}
              margin="normal"
              fullWidth
              error={loginError !== ''}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Contraseña"
              type="password"
              name="password"
              value={loginData.password}
              onChange={handleLoginInputChange}
              // variant="outlined"
              className={classes.textFields}
              margin="normal"
              fullWidth
              error={loginError !== ''}
              helperText={loginError}
              InputLabelProps={{ shrink: true }}
            />
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleLogin} gutterBottom>
              Entrar
            </Button>
            <Button variant="text" className={classes.welcomeButtonsNoBorder} style={{ color: '#ffffff' }} fullWidth onClick={() => goToPage(3)} gutterBottom>
              Crear una cuenta
            </Button>
            <Button variant="text" className={classes.welcomeButtonsNoBorder} style={{ color: '#45C4D6' }} fullWidth onClick={() => goToPage(2)} gutterBottom>
              ¿Olvidó su contraseña?
            </Button>
            {isLoading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
              <BeatLoader color="#45C4D6" />
            </div> : []}
          </form>
        </Container>
      </div>
    )
  }

  else if (page === 2) {
    return (
      <div>
        <div style={{ padding: 20 }}>
          <img className="w-64" src={image} alt="Krece logo" />
        </div>
        <Container maxWidth="sm" style={{
          marginTop: 50,
          marginBottom: 50,
          marginHorizontal: 10,
          boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
          paddingTop: '30px',
          paddingBottom: '20px',
          borderRadius: '8px',
          backgroundColor: '#1C1C1E',
        }}>
          <Typography variant="h4" align="center" className={classes.title} gutterBottom>
            Recuperación de cuenta
          </Typography>
          <form>
            <TextField
              label="Email de la tienda"
              type="storeEmail"
              name="storeEmail"
              value={loginData.storeEmail}
              onChange={handleLoginInputChange}
              // variant="outlined"
              className={classes.textFields}
              margin="normal"
              fullWidth
              error={loginError !== ''}
              helperText={loginError}
              InputLabelProps={{ shrink: true }}
            />
            {infoMessage && <p className={classes.infoMessage}>{infoMessage}</p>}
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleResetPassword} gutterBottom>
              Enviar
            </Button>
            <Button variant="text" className={classes.welcomeButtonsNoBorder} fullWidth onClick={() => goToPage(1)} gutterBottom>
              Volver
            </Button>
            {isLoading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
              <BeatLoader color="#45C4D6" />
            </div> : []}
          </form>
        </Container>
      </div>
    )
  } else if (page == 3) {
    return (
      <div>
        <div style={{ padding: 20 }}>
          <img className="w-64" src={image} alt="Krece logo" />
        </div>
        <Container maxWidth="sm" style={{
          marginTop: 50,
          marginBottom: 50,
          marginHorizontal: 10,
          boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
          paddingTop: '30px',
          paddingBottom: '20px',
          borderRadius: '8px',
          backgroundColor: '#1C1C1E',
        }}>
          <Typography variant="h4" align="center" className={classes.title} gutterBottom>
            Registro de cuenta
          </Typography>
          <form>
            <TextField
              label="Email de la tienda"
              type="storeEmail"
              name="storeEmail"
              value={loginData.storeEmail}
              onChange={handleLoginInputChange}
              // variant="outlined"
              className={classes.textFields}
              margin="normal"
              fullWidth
              error={loginError !== ''}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Contraseña"
              type="password"
              name="password"
              value={loginData.password}
              onChange={handleLoginInputChange}
              // variant="outlined"
              className={classes.textFields}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Confirmación de la contraseña"
              type="password"
              name="password2"
              value={loginData.password2}
              onChange={handleLoginInputChange}
              // variant="outlined"
              className={classes.textFields}
              margin="normal"
              fullWidth
              error={loginError !== ''}
              helperText={loginError}
              InputLabelProps={{ shrink: true }}
            />
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleSignUp} gutterBottom>
              Registrarse
            </Button>
            <Button variant="text" className={classes.welcomeButtonsNoBorder} fullWidth onClick={() => goToPage(1)} gutterBottom>
              Ya tengo cuenta
            </Button>
            {isLoading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
              <BeatLoader color="#45C4D6" />
            </div> : []}
          </form>
        </Container>
      </div>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  approvalMsg: {
    marginBottom: theme.spacing(2), // Adjust the padding as needed
    textAlign: 'center',
    color: 'white'
  },
  welcomeButtons: {
    backgroundColor: '#45C4D6',
    color: '#ffffff',
    marginTop: 10,
    marginBottom: 10
  },
  welcomeButtonsNoBorder: {
    backgroundColor: '#1C1C1E',
    color: '#45C4D6',
    marginTop: 10,
    marginBottom: 10
  },
  textFields: {
    backgroundColor: 'white',
    color: '#1C1C1E',
    borderColor: "white",
    borderBottomColor: "white",
    borderRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    paddingLeft: 5
  },
  paymentItem: {
    width: '100%',
    paddingTop: 5,
    paddingBottom: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 10,
  },
  containerWelcome: {
    display: 'flex',
    flexDirection: 'column',
    height: 700,
  },
  topButton: {
    flex: 1
  },
  infoMessage: {
    color: "white"
  },
  title: {
    color: '#ffffff',
    marginBottom: 20,
    fontSize: 26,
    fontWeight: 'bold'
  }
}));

export default LoginForm;
