import React, { ReactNode, useContext } from 'react';
import Navigation from '../components/Navigation';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from './Spinner';
import { Navigate, useLocation } from 'react-router-dom';

export default function Layout({ children }: { children: ReactNode }) {
	const location = useLocation();

	const { authenticated, userData, shouldLogin } = useContext(GlobalContext);

	if (shouldLogin && location.pathname !== '/login') Navigate({ to: '/login' });
	else if (!shouldLogin && location.pathname === '/login') Navigate({ to: '/' });

	return (
		<div className="relative flex h-svh w-full flex-col-reverse items-center justify-between gap-4 bg-background text-neutral-100 md:flex-row md:gap-0">
			{location.pathname !== '/login' && location.pathname !== '/internal' && !userData?.cobranza_access && authenticated && (
				<Navigation
					masterUser={userData?.master_user || false}
					selfFinanced={!userData?.financed_by_krece || false}
					country={userData?.country || null}
				/>
			)}

			{['/login', '/logout', '/internal'].includes(location.pathname) || userData ? (
				<div className="h-full w-full overflow-auto md:mx-6 md:h-[calc(100%-3rem)] md:rounded-xl md:bg-foreground">
					{children}
				</div>
			) : (
				<ContainerSpinner size={3} />
			)}
		</div>
	);
}
