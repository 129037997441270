import { useEffect, useState } from 'react';
import useAuth from './auth';
import useFetch from './api';

export default function useUserAlerts() {
	const { authenticated, userData } = useAuth();

	const [shouldAddPaymentMethods, setShouldAddPaymentMethods] = useState(false);
	const [shouldAddBranches, setShouldAddBranches] = useState(false);
	const [reevaluateAlerts, setReevaluateAlerts] = useState(0);

	const [shouldFetchBrances, setShouldFetchBrances] = useState(false);
	const { data: branches, setPayload: setBranchesPayload } = useFetch(
		'https://api.krece.app/storeprofile/branch/?GET',
		'GET',
		shouldFetchBrances
	);

	const [shouldFetchPaymentMethods, setShouldFetchPaymentMethods] = useState(false);
	const { data: paymentMethods, setPayload: setPaymentMethodsPayload } = useFetch(
		'https://api.krece.app/storeprofile/store_payment_methods/',
		'GET',
		shouldFetchPaymentMethods
	);

	useEffect(() => {
		if (authenticated && userData?.master_user && userData?.master_store?.id) {
			if (userData?.country === 'venezuela' && userData?.self_financed) {
				setShouldFetchPaymentMethods(true);
				setPaymentMethodsPayload({ email: userData.master_store.id });
			}

			setShouldFetchBrances(true);
			setBranchesPayload({ email: userData.master_store.id });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authenticated, userData, reevaluateAlerts]);

	useEffect(() => {
		if (
			branches &&
			(!Object.keys(branches).length ||
				!Object.keys(branches).reduce((acc, e) => (acc += branches[e]?.sucursales?.length || 0), 0))
		)
			setShouldAddBranches(true);
		else setShouldAddBranches(false);

		if (
			paymentMethods &&
			!paymentMethods?.transferBs?.account &&
			!paymentMethods?.pagomovil?.phoneNumber &&
			userData?.country === 'venezuela' &&
			userData?.self_financed
		)
			setShouldAddPaymentMethods(true);
		else setShouldAddPaymentMethods(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branches, paymentMethods]);

	return { shouldAddBranches, shouldAddPaymentMethods, reevaluateAlerts: () => setReevaluateAlerts((value) => value + 1) };
}
