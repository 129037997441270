import React from 'react';
import ReactDOM from 'react-dom/client';
import './tailwind.css';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { GlobalProvider } from './context/GlobalContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode> // Should only be enabled during development
	<GlobalProvider>
		<App />
	</GlobalProvider>
	// </React.StrictMode>
);