import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Button, TextField, makeStyles, MenuItem, withStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';
import { BeatLoader } from 'react-spinners';


// Initialize Firebase
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

const CustomTextField = withStyles({
    root: {
        '& input[type="file"]': {
            display: 'none', // Hide the default file input
        },
        '& label': {
            backgroundColor: '#45C4D6', // Background color for the button
            color: '#2A2E32', // Text color for the button
            padding: '10px 15px', // Adjust padding as needed
            borderRadius: 4,
            cursor: 'pointer',
            width: 150
        },
    },
})(TextField);

const DeleteSale = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    //User creation data
    const [cedula, setCedula] = useState('');
    const [storeUser, setStoreUser] = useState('');

    const [imeiDelete, setImeiDelete] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [storeEmail, setStoreEmail] = useState('');

    //user application data

    // device registration data
    const [loginError, setLoginError] = useState('');
    const [formError, setFormError] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);

    //webapp data
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [needs2FA, setNeeds2FA] = useState(false);
    const [isStoreFinanced, setIsStoreFinanced] = useState(false);
    const [productType, setProductType] = useState('');

    //changeEmail data
    const [isAccountInactive, setIsAccountInactive] = useState('');

    var currentDay = new Date().getDate();
    if (currentDay === 31) {
        currentDay = 30;
    }

    useEffect(() => {
        setIsLoading(true);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                const storeRef = firestore.collection('Stores').doc(user.email);
                setStoreUser(user.email)
                storeRef.get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.cobranza_access) {
                            navigate('/');
                            setIsLoading(false);
                            return;
                        }
                        const masterStoreRef = data.master_store;
                        setStoreEmail(masterStoreRef.id);
                        const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
                        // const userName = data.store_name ? data.store_name : "Master"
                        // const storeRef = firestore.collection('Stores').doc(masterStore);
                        masterStoreRef.get()
                            .then((doc) => {
                                const data = doc.data();
                                setCountry(data.country)
                                const storeName = data.store_name;
                                if (data.store_financed) {
                                    setIsStoreFinanced(true);
                                }
                                setIsAccountInactive(!data.is_active);
                                setIsLoading(false);
                                setNeeds2FA(data['2fa_needed']);
                            });
                        setIsLoading(false);
                    });
            } else {
                // User is signed out
                // ...
                navigate("/login");
                console.log("user is logged out");
                setIsLoading(false);
            }
        });

    }, [])

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': storeEmail,
                    'x-user-email': storeUser
                },
                body: JSON.stringify({ ...data, country: country })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)
                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleContinueDelete = async () => {
        const result = window.confirm("Estas seguro que deseas eliminar este registro de venta?")
        if (!result) {
            return;
        }
        setIsLoading(true);
        setFormError('')
        if (!cedula || !imeiDelete) {
            setFormError("Por favor rellene todas las casillas");
            setIsLoading(false);
            return;
        }
        try {
            if (productType !== 'other') {
                const deleteData = {
                    identification_number: cedula,
                    imei_no: imeiDelete,
                    store_user: storeUser
                }
                const response = await sendPostRequest(deleteData, 'administration/deletedevice/', token) //status, success, data
                if (response?.device_deleted) {
                    //needs work
                    alert('Registro eliminado exitosamente.')
                    setIsLoading(false)
                    setTimeout(() => {
                        setInfoMessage('');
                        setCedula('');
                        setImeiDelete('');
                    }, 5000);
                } else if (response?.success === true) {
                    setFormError(response.status);
                    setIsLoading(false);
                } else if (response?.success === false) {
                    setFormError(response.status);
                    setIsLoading(false);
                } else {
                    setFormError('Ocurrió un problema intente más tarde.');
                    setIsLoading(false);
                }
            } else {
                const deleteData = {
                    identification_number: cedula,
                    confirmation_id: imeiDelete,
                    store_user: storeUser
                }
                const response = await sendPostRequest(deleteData, 'administration/deleteotherdevice/', token) //status, success, data
                if (response?.device_deleted) {
                    //needs work
                    alert('Registro eliminado exitosamente.')
                    setIsLoading(false)
                    setTimeout(() => {
                        setInfoMessage('');
                        setCedula('');
                        setImeiDelete('');
                    }, 5000);
                } else if (response?.success === true) {
                    setFormError(response.status);
                    setIsLoading(false);
                } else if (response?.success === false) {
                    setFormError(response.status);
                    setIsLoading(false);
                } else {
                    setFormError('Ocurrió un problema intente más tarde.');
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log(error.message)
            setFormError('Ocurrió un problema intente más tarde.');
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
                marginTop: 100
            }}>
                <div className={classes.topButton}>
                    <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                        {"Eliminar Venta"}
                    </Typography>
                    <p className={classes.approvalMsg}>Ingresa los siguientes datos para eliminar la venta realizada</p>
                    <TextField
                        select
                        label="Tipo de producto"
                        name="productType"
                        value={productType}
                        onChange={(e) => setProductType(e.target.value)}
                        className={classes.textFields}
                        style={{ width: 200 }}
                        margin="normal"
                        InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    >
                        <MenuItem value={'phone'}>Celular</MenuItem>
                        <MenuItem value={'other'}>Otro Producto</MenuItem>
                    </TextField>
                    <> <TextField
                        label="Cédula del cliente"
                        name="cedula"
                        value={cedula}
                        onChange={(e) => setCedula(e.target.value)}
                        // variant="outlined"
                        className={classes.textFields}
                        margin="normal"
                        fullWidth
                        error={loginError !== ''}
                        helperText={loginError}
                        InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                        {productType !== 'other' ? <TextField
                            label="Número IMEI del equipo"
                            name="imeiDelete"
                            value={imeiDelete}
                            onChange={(e) => setImeiDelete(e.target.value)}
                            // variant="outlined"
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            error={loginError !== ''}
                            helperText={loginError}
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        /> : <TextField
                            label="Código de confirmación (7 digitos)"
                            name="imeiDelete"
                            value={imeiDelete}
                            onChange={(e) => setImeiDelete(e.target.value)}
                            // variant="outlined"
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            error={loginError !== ''}
                            helperText={loginError}
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />}
                        <Button variant="contained" size="small" margin="normal" fullWidth className={classes.welcomeButtons} onClick={handleContinueDelete}>
                            Eliminar Registro
                        </Button>
                        {formError && <p className={classes.formError}>{formError}</p>}
                        {infoMessage && <p style={{ textAlign: 'center', color: "white", fontSize: 16 }}>{infoMessage}<CheckCircle style={{ color: "#45C4D6" }} /></p>}</>
                </div>
                {isLoading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
                    <BeatLoader color="#45C4D6" />
                </div>}
            </Container>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    approvalMsg: {
        textAlign: 'center', color: "silver", fontSize: 16
    },

    switch_track: {
        backgroundColor: "gray",
    },
    switch_base: {
        color: "gray",
        "&.Mui-disabled": {
            color: "gray"
        },
        "&.Mui-checked": {
            color: "gray"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "gray",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#65C466",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#65C466",
        },
    },
    totalAmt: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtons2: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 15,
        marginLeft: 15,
        height: 45,
        width: 200
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    textFields2: {
        color: 'white',
    },
    textLabel: {
        color: 'white'
    },
    textInput: {
        backgroundColor: '#2A2E32',
        color: 'white',
        border: "1px solid #ccc",
        borderRadius: '8px',
        padding: 8,
        fontSize: 16,
        width: 100
    },
    paymentItem: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 10,
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        // height: 700,
    },
    topButton: {
        flex: 1
    },
    formError: {
        color: "#e84e4f"
    },
    title: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    title2: {
        color: 'white',
        fontSize: 16,
        marginBottom: 10
    },
    highlighted: {
        color: '#45C4D6',
    },
    appBarButton: {
        width: 200
    },
    border: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        padding: "10px",
        marginBottom: 20,
    },
    title: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    border2: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingBottom: 5,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#1C1C1E',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
}));

export default DeleteSale;
