import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SummaryCardsPorCobrar from './SummaryCardsPorCobrar';
import PendientePorCobrarTable from './PendientePorCobrarTable';
import { InteractiveBarChart, PendingSummary } from './PendientePorCobrarChart';
import { GlobalContext } from '../context/GlobalContext';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2), // Adjust the gap between grid items
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: '100% !important',
    minHeight: '100vh', // Ensure the grid fills the viewport height
    background: '#2A2E32',
    alignItems: 'center',
    justifyContent: 'center'
  },
  middleSection: {
    maxWidth: '100% !important',
    display: 'flex',
    gap: theme.spacing(2), // Adjust the gap between the two components
    width: '100%',
    height: '400px',
  },
  lowerSection: {
    maxWidth: '100% !important',
    marginBottom: '30px',
    width: '100%'
  },
}));

function CuentasPorCobrar(props) {
  const classes = useStyles();
  const { authenticated, userData } = useContext(GlobalContext);
  const navigate = useNavigate();

  if (userData?.financed_by_krece || !userData?.master_user || userData.cobranza_access) navigate({ to: '/' });

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, []);

  return (
    <div className={classes.root}>
      <Container className="!max-w-full">
        <h1 className="text-2xl font-bold mb-6" style={{ color: 'white', padding: 20 }}>Cuentas por cobrar</h1>
        <SummaryCardsPorCobrar storeEmail={userData.master_store.id} />
      </Container>
      <Container className={classes.middleSection}>
        <InteractiveBarChart storeEmail={userData.master_store.id} />
        <PendingSummary storeEmail={userData.master_store.id} />
      </Container>
      <Container className={classes.lowerSection}>
        <PendientePorCobrarTable storeEmail={userData.master_store.id} />
      </Container>
    </div>
  );
}

export default CuentasPorCobrar;
