import * as RDialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { ReactNode } from 'react';

export function Dialog({
	title,
	children,
	closable = true,
	onClose,
	zIndex
}: {
	title: string;
	children: ReactNode;
	closable?: boolean;
	onClose?: () => any;
	zIndex?: number;
}) {
	return (
		<RDialog.Root
			defaultOpen={true}
			onOpenChange={(open) => {
				if (!open && onClose) onClose();
			}}
		>
			<RDialog.Portal>
				<RDialog.Overlay
					className="fixed inset-0 z-40 bg-black/70 backdrop-blur-[1.5px] data-[state=open]:animate-overlayShow"
					style={{ ...(zIndex && { zIndex }) }}
				/>
				<RDialog.Content
					className="fixed left-[50%] top-[50%] z-50 flex max-h-[85vh] w-[90vw] max-w-[550px] translate-x-[-50%] translate-y-[-50%] flex-col gap-4 rounded-xl border-[2px] border-neutral-900/65 bg-background p-6 text-neutral-100 focus:outline-none data-[state=open]:animate-contentShow"
					style={{ ...(zIndex && { zIndex: zIndex + 1 }) }}
					onInteractOutside={(e) => !closable && e.preventDefault()}
				>
					<RDialog.Title className="select-none text-[1.1rem] font-medium">{title}</RDialog.Title>
					<RDialog.Description className="hidden"></RDialog.Description>

					<div className="leading-normal text-neutral-200">{children}</div>

					{closable && (
						<>
							<div className="flex justify-end">
								<RDialog.Close asChild>
									<button className="inline-flex h-[35px] items-center justify-center rounded-lg px-[15px] font-medium leading-none text-neutral-600 transition-colors hover:bg-neutral-900/50 focus:outline-none disabled:pointer-events-none disabled:opacity-50">
										Cerrar
									</button>
								</RDialog.Close>
							</div>
							<RDialog.Close asChild>
								<button
									className="absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full text-neutral-300 hover:bg-neutral-900/50 focus:outline-none"
									aria-label="Close"
								>
									<X className="size-3.5" strokeWidth={2.5} />
								</button>
							</RDialog.Close>
						</>
					)}
				</RDialog.Content>
			</RDialog.Portal>
		</RDialog.Root>
	);
}
