import React, { useState } from 'react';

import ArrowDownIcon from '@heroicons/react/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';

import Stack from '@mui/material/Stack';

import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Divider,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';




const DeleteMyAccount = () => {
    const [difference, setdifference] = useState('');
    const [positive, setpositive] = useState('');
    return (
        <div>
            <text>Para que eliminemos su cuenta y sus datos por favor comuníquese con nosotros a info@krece.app o al 04149022115</text>
        </div>
    );
};
export default DeleteMyAccount;