import { useState, useEffect } from 'react';
import auth from '../auth';
import { onAuthStateChanged } from 'firebase/auth';
import firestore from '../firestore';

export interface UserData {
	firebase_token: string;
	email: string;
	limit_reached: boolean;
	flexible_installments: boolean;
	last_modified_date: {
		seconds: number;
		nanoseconds: number;
	};
	is_active: boolean;
	master_user: boolean;
	only_allow_tonic: boolean;
	krece_fixed_fee: number;
	allow_any_product: boolean;
	master_store: { id: string; [key: string]: any };
	'2fa_needed': boolean;
	store_financed: boolean;
	down_payment: number;
	security_provider: string;
	subStoreAdminPhoneNumbers: { [key: string]: string };
	krece_fee: number;
	self_financed: boolean;
	sub_stores: string[];
	principal_store: string;
	allow_online: boolean;
	store_name: string;
	zelleAccounts: string[];
	financed_by_krece: boolean;
	cobranza_access: boolean;
	country: 'venezuela' | string;
	alerts?: {
		should_add_branch?: boolean;
		should_set_payment_method?: boolean;
	};
}

export default function useAuth() {
	const [authenticated, setAuthenticated] = useState(false);
	const [userData, setUserData] = useState<UserData | null>(null);
	const [shouldLogin, setShouldLogin] = useState(false);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			try {
				if (!user?.email) throw new Error();

				const data = (await firestore.collection('Stores').doc(user.email).get())?.data() as
					| undefined
					| Omit<UserData, 'email' | 'firebase_token'>;
				if (!data) throw new Error();

				setShouldLogin(false);
				setAuthenticated(true);
				setUserData({
					firebase_token: await user.getIdToken(),
					email: user.email,
					...data
				} satisfies UserData);
			} catch (error) {
				setShouldLogin(true);
				setAuthenticated(false);
				setUserData(null);
			}
		});

		return () => unsubscribe();
	}, []);

	return { authenticated, userData, shouldLogin };
}
