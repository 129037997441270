import { useNavigate } from 'react-router-dom';
import { Dialog } from './Dialog';

export default function ErrorRedirectDialog() {
	const navigate = useNavigate();

	return (
		<Dialog title="¡Error!" onClose={() => navigate('/')}>
			Ha ocurrido un error cargando esta página. Será redirigido a Inicio.
		</Dialog>
	);
}
