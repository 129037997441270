import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Button, TextField, makeStyles, MenuItem, CircularProgress, withStyles, Switch, IconButton } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';

// Initialize Firebase
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

// el flow de cuando la persona va a financiar un segundo celular: 
//      no dejar que financie un segundo celular cuando ya tiene financiado uno.
//      Lo primero que se va a hacer es agregar la cedula y mandarla a registration/usercheck el cual va a devolver satus, success, data { is_user_registered: tru or false, can_be_financed, true or false }.
//          if is_user_registered === false --> entonce ssigue as usual
//          if is_user_registered === true && can_be_financed === false --> entonces se le da un mensaje que ya tiene un celular financiado
//          if is_user_registered === true && can_be_financed === true --> entonces se le rellenan los datos para que confirme y después se sigue as usual
//Tambien cuando hay un telefono con garantia:
//          Hay un boton especial que va a decir "celular aplicar garantia"
//          Lo primero que se va a hacer es agregar la cedula y mandarla a registration/usercheck y va a devolver satus, success, data { is_user_registered: tru or false, can_be_financed, true or false }.
//          if is_user_registered === true && can_be_financed === false --> entonces lo manda a un screen para registrar el device nuevo y solo se le va a pedir el IMEI y llama a replace_old_device. 
//              replace_old_device va a devolver lo mismo que devuelve el device_registration endpoint
//          if is_user_registered === true && can_be_financed === true --> entonces se le rellenan los datos para que confirme y después se sigue as usual

const CustomTextField = withStyles({
    root: {
        '& input[type="file"]': {
            display: 'none', // Hide the default file input
        },
        '& label': {
            backgroundColor: '#45C4D6', // Background color for the button
            color: '#1C1C1E', // Text color for the button
            padding: '10px 15px', // Adjust padding as needed
            borderRadius: 4,
            cursor: 'pointer',
            width: 150
        },
    },
})(TextField);

const Simulator = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [applicationApprovalData, setApplicationApprovalData] = useState({
        threeMonthAmount: 150,
        sixMonthAmount: '',
    });
    const [maxLoan3MonthsKrecePlus, setMaxLoan3MonthsKrecePlus] = useState('');
    const [downPaymentPerc, setDownPaymentPerc] = useState(null);
    //User creation data
    const [formError, setFormError] = useState('');
    const [allowAnyProduct, setAllowAnyProduct] = useState(false);

    //user application data
    const [level, setLevel] = useState('level_1');

    // device registration data
    const [loginError, setLoginError] = useState('');
    const [timeToPay, setTimeToPay] = useState(3);
    const [interestFactor, setInterestFactor] = useState(0.1);

    //webapp data
    const [isLoading, setIsLoading] = useState(true);
    const [amountSlider, setAmountSlider] = useState(50);
    const [productType, setProductType] = useState('');
    const [switchChecked, setSwitchChecked] = useState(false);
    const [switchChecked2, setSwitchChecked2] = useState(false);
    const [country, setCountry] = useState('')
    const [countrySettings, setCountrySettings] = useState('')

    //changeEmail data
    const fileInputRef = useRef(null);

    var currentDay = new Date().getDate();
    if (currentDay === 31) {
        currentDay = 30;
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                const storeRef = firestore.collection('Stores').doc(user.email);
                storeRef.get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.cobranza_access) {
                            navigate('/internal');
                            setIsLoading(false);
                            return;
                        }
                        const masterStoreRef = data.master_store;
                        const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
                        // const storeRef = firestore.collection('Stores').doc(masterStore);
                        masterStoreRef.get()
                            .then((doc) => {
                                const data = doc.data();
                                setCountry(data.country);
                                if (data.country === 'rd') {
                                    setApplicationApprovalData({ threeMonthAmount: 12000 })
                                }
                                const ref = firestore.collection('AppSetting').doc('CountrySettings');
                                ref.get()
                                    .then((doc) => {
                                        try {
                                            const dat = doc.data();
                                            setCountrySettings(dat[data.country]);
                                            setInterestFactor(dat[data.country].payment_options.level_1[0].factor)
                                            setTimeToPay(dat[data.country].payment_options.level_1[0].installments / 2)
                                            setDownPaymentPerc(dat[data.country].down_payment.level_1);
                                            if (data.allow_any_product === true) {
                                                setAllowAnyProduct(true);
                                            }
                                        } catch (err) {
                                            setIsLoading(false);
                                        } finally {
                                            setIsLoading(false);
                                        }

                                    })

                                // setIsLoading(false);
                                // setDownPaymentPerc(data['down_payment']);
                            });
                        // setIsLoading(false);
                    });
            } else {
                // User is signed out
                // ...
                navigate("/login");
                console.log("user is logged out");
                setIsLoading(false);
            }
        });

    }, [])

    const getFactorByInstallment = (installment, data) => {
        for (const level in data) {
            for (const entry of data[level]) {
                if (entry.installments === installment) {
                    return entry.factor;
                }
            }
        }
        return null; // return null if the installment is not found
    };

    function changeTimeToPay(ttp) {
        setTimeToPay(ttp)
        const factor = getFactorByInstallment(ttp * 2, countrySettings.payment_options)
        setInterestFactor(factor)
    }

    const extractLevelNumber = (levelString) => {
        const match = levelString.match(/level_(\d+)/);
        return match ? parseInt(match[1], 10) : null;
    };

    const renderPaymentOptions = (userLevel, data) => {
        console.log(userLevel, data)
        const levelNum = extractLevelNumber(userLevel)
        let componentsToRender = [];

        for (let level = 1; level <= levelNum; level++) {
            const levelKey = `level_${level}`;
            if (data[levelKey]) {
                data[levelKey].forEach(item => {
                    componentsToRender.push(
                        <MenuItem key={`${levelKey}-${item.installments}`} value={item.installments / 2}>{item.installments}</MenuItem>
                    );
                });
            }
        }

        return componentsToRender;
    };

    function renderDownPayments(start) {
        const result = [];
        for (let i = start; i <= 50; i += 5) {
            result.push(i);
        }
        return result;
    }

    if (isLoading) {
        return (
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
                marginTop: 100
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
                    <BeatLoader color="#45C4D6" />
                </div>
            </Container>)
    }

    return (
        <div>
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
                marginTop: 100
            }}>

                <div>
                    {country === 'venezuela' && <div style={{ flexDirection: 'row' }}>
                        <span className={classes.approvalMsg}>Activar promoSAMSUNG</span>
                        <Switch
                            checked={switchChecked}
                            onChange={() => {
                                setSwitchChecked(!switchChecked)
                                setSwitchChecked2(false)
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            // color="default"
                            // classes={{ root: classes.switchColor }}
                            classes={{
                                track: classes.switch_track,
                                switchBase: classes.switch_base,
                                colorPrimary: classes.switch_primary,
                            }}
                            color={switchChecked ? "primary" : "default"}></Switch>
                    </div>}
                    {/* 
                    {country === 'venezuela' && <div style={{ flexDirection: 'row' }}>
                        <span className={classes.approvalMsg}>Activar promoINFINIX</span>
                        <Switch
                            checked={switchChecked2}
                            onChange={() => {
                                setSwitchChecked2(!switchChecked2)
                                setSwitchChecked(false)
                                setDownPaymentPerc(switchChecked2 ? 0.4 : 0.25)
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            // color="default"
                            // classes={{ root: classes.switchColor }}
                            classes={{
                                track: classes.switch_track,
                                switchBase: classes.switch_base,
                                colorPrimary: classes.switch_primary,
                            }}
                            color={switchChecked2 ? "primary" : "default"}></Switch>
                    </div>} */}
                    <Typography variant="h5" align="center" color="textPrimery" className={classes.highlighted} gutterBottom>
                        Simule su inicial y cuotas
                    </Typography>
                    <div className={classes.border} style={{ padding: 20 }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 20, justifyContent: 'space-between' }}>
                            <TextField
                                select
                                label="Nivel"
                                name="level"
                                value={level}
                                onChange={(e) => {
                                    setLevel(e.target.value)
                                    setDownPaymentPerc(countrySettings.down_payment[e.target.value])
                                }}
                                className={classes.textFields}
                                style={{ width: 200 }}
                                margin="normal"
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            >
                                <MenuItem value={'level_1'}>Azul</MenuItem>
                                <MenuItem value={'level_2'}>Plata</MenuItem>
                                <MenuItem value={'level_3'}>Oro</MenuItem>
                                <MenuItem value={'level_4'}>Platino</MenuItem>
                            </TextField>
                            <TextField
                                label="Línea de compra"
                                name="amountSlider"
                                type="number"
                                value={applicationApprovalData.threeMonthAmount}
                                onChange={(e) => setApplicationApprovalData({ threeMonthAmount: e.target.value })}
                                className={classes.textFields}
                                style={{ width: 200 }}
                                margin="normal"
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <style>
                                {`
                          input[type="range"]::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            width: 15px;
                            height: 15px;
                            background-color: #45C4D6;
                            border-radius: 50%;
                            cursor: pointer;
                            border: 1px;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5)
                          }
                          `}
                            </style>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 20, justifyContent: 'space-between' }}>
                            <TextField
                                select
                                label="Número de cuotas"
                                name="timeToPay"
                                value={timeToPay}
                                onChange={(e) => changeTimeToPay(e.target.value)}
                                className={classes.textFields}
                                style={{ width: 200 }}
                                margin="normal"
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            >
                                {productType === 'other' && <MenuItem value={2}>4</MenuItem>}
                                {productType !== 'other' && renderPaymentOptions(level, countrySettings.payment_options)}
                            </TextField>
                            <TextField
                                label="Precio del equipo"
                                name="amountSlider"
                                type="number"
                                value={amountSlider}
                                onChange={(e) => setAmountSlider(e.target.value)}
                                className={classes.textFields}
                                style={{ width: 200 }}
                                margin="normal"
                                error={switchChecked && amountSlider < 100}
                                helperText={switchChecked && amountSlider < 100 ? "El monto debe ser mayor a 100" : ""}
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                        </div>
                        {!switchChecked && !switchChecked2 && <TextField
                            select
                            label="Inicial deseada"
                            name="downPayment"
                            value={downPaymentPerc}
                            onChange={(e) => setDownPaymentPerc(e.target.value)}
                            className={classes.textFields}
                            style={{ width: 200 }}
                            margin="normal"
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        >
                            {renderDownPayments(countrySettings.down_payment[level] * 100).map((item, index) => (
                                <MenuItem value={item / 100}>{item + "%"}</MenuItem>
                            ))}
                        </TextField>}
                        {!switchChecked ? <div style={{ marginTop: 20, display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                                {productType !== 'other' ? <>
                                    <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{"Cuota inicial (" + (((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider ? (amountSlider * downPaymentPerc) : (((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * downPaymentPerc) + (amountSlider - (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))) * 100 / amountSlider).toFixed(0) + "%):"}</Typography>
                                    <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider ? (amountSlider * downPaymentPerc) : (((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * downPaymentPerc) + (amountSlider - (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))).toFixed(0)}</Typography>
                                </> : <>
                                    <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{"Cuota inicial (" + ((maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider ? (amountSlider * 0.5) : ((maxLoan3MonthsKrecePlus / (1 - 0.5) * 0.5) + (amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5)))) * 100 / amountSlider).toFixed(0) + "%):"}</Typography>
                                    <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + (maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider ? (amountSlider * 0.5) : ((maxLoan3MonthsKrecePlus / (1 - 0.5) * 0.5) + (amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5)))).toFixed(2)}</Typography>
                                </>}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                                {productType !== 'other' ? <>
                                    <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{timeToPay * 2 + ' cuotas quincenales de:'}</Typography>
                                    <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + Math.round(((((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider ? (amountSlider * (1 - downPaymentPerc)) : ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * (1 - downPaymentPerc)))) * interestFactor) / (parseInt(timeToPay) * 2)).toFixed(0)}</Typography>
                                </> : <>
                                    <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{timeToPay * 2 + ' cuotas quincenales de:'}</Typography>
                                    <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + ((((maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider ? (amountSlider * (1 - 0.5)) : (maxLoan3MonthsKrecePlus / (1 - 0.5) * (1 - 0.5))))) / (parseInt(timeToPay) * 2)).toFixed(2)}</Typography>
                                </>}
                            </div>
                        </div> :
                            <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                                    <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{"Cuota inicial (50%)"}</Typography>
                                    <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + Math.round(amountSlider / 2).toFixed(0)}</Typography>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                                    <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{timeToPay * 2 + ' cuotas quincenales de:'}</Typography>
                                    <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + Math.round((amountSlider / 2) * (1.25) / (timeToPay * 2))}</Typography>
                                </div>
                            </div>}
                    </div>
                </div>
            </Container>
            {/* {
          isAuthenticated && page === 2 && !isLoading && storeEmail === "test@store.com" && (
            <div>
              <Button
                variant="contained"
                className={classes.welcomeButtons}
                startIcon={<Delete />}
                onClick={deleteTestData}
                style={{ marginTop: '10px', marginLeft: 20 }}
              >
                Delete test data
              </Button>
            </div>
          )
        } */}
        </div >
    );
};

const useStyles = makeStyles((theme) => ({
    approvalMsg: {
        textAlign: 'center', color: "silver", fontSize: 16
    },

    switch_track: {
        backgroundColor: "gray",
    },
    switch_base: {
        color: "gray",
        "&.Mui-disabled": {
            color: "gray"
        },
        "&.Mui-checked": {
            color: "gray"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "gray",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#65C466",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#65C466",
        },
    },
    totalAmt: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtons2: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 15,
        marginLeft: 15,
        height: 45,
        width: 200
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    textFields2: {
        color: 'white',
    },
    textLabel: {
        color: 'white'
    },
    textInput: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        border: "1px solid #ccc",
        borderRadius: '8px',
        padding: 8,
        fontSize: 16,
        width: 100
    },
    paymentItem: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 10,
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        // height: 700,
    },
    topButton: {
        flex: 1
    },
    formError: {
        color: "#e84e4f"
    },
    title: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    title2: {
        color: 'white',
        fontSize: 16,
        marginBottom: 10
    },
    highlighted: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    appBarButton: {
        width: 200
    },

    welcomeButtonsNoBorder: {
        backgroundColor: '#1C1C1E',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
}));

export default Simulator;
