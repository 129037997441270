import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Button, TextField, makeStyles, MenuItem, withStyles } from '@material-ui/core';
import { ArrowBack, CheckCircle } from '@material-ui/icons';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';
import { useNavigate } from "react-router-dom";
import { BeatLoader } from 'react-spinners';

// Initialize Firebase
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

const CustomTextField = withStyles({
    root: {
        '& input[type="file"]': {
            display: 'none', // Hide the default file input
        },
        '& label': {
            backgroundColor: '#45C4D6', // Background color for the button
            color: '#1C1C1E', // Text color for the button
            padding: '10px 15px', // Adjust padding as needed
            borderRadius: 4,
            cursor: 'pointer',
            width: 150
        },
    },
})(TextField);

const ReceivePayment = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [storeEmail, setStoreEmail] = useState('');
    const [storeName, setStoreName] = useState('');
    //User creation data
    const [cedula, setCedula] = useState('');
    const [formError, setFormError] = useState('');
    const [storeUser, setStoreUser] = useState('');

    //user application data
    const [subStore, setSubStore] = useState('');
    const [subStore2, setSubStore2] = useState('');
    const [subStoreList, setSubStoreList] = useState([]);
    const [subStorePhoneNumberMap, setSubStorePhoneNumberMap] = useState({});

    // device registration data
    const [loginError, setLoginError] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);

    //webapp data
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [infoMessage, setInfoMessage] = useState('');
    const [showAuthCodeScreen, setShowAuthCodeScreen] = useState(false);
    const [showInputAuthCode, setShowInputAuthCode] = useState(true);
    const [authCode, setAuthCode] = useState('');
    const [countrySettings, setCountrySettings] = useState('');

    //payment data
    const [amountReceived, setAmountReceived] = useState('');

    const fileInputRef = useRef(null);

    var currentDay = new Date().getDate();
    if (currentDay === 31) {
        currentDay = 30;
    }

    useEffect(() => {
        setIsLoading(true);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                const storeRef = firestore.collection('Stores').doc(user.email);
                setStoreUser(user.email)
                storeRef.get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.cobranza_access) {
                            navigate('/');
                            setIsLoading(false);
                            return;
                        }
                        const masterStoreRef = data.master_store;
                        const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
                        // const userName = data.store_name ? data.store_name : "Master"
                        if (masterStoreRef.id !== user.email) {
                            setSubStoreList([data.store_name]);
                        }
                        setStoreEmail(masterStoreRef.id);
                        // const storeRef = firestore.collection('Stores').doc(masterStore);
                        masterStoreRef.get()
                            .then((doc) => {
                                const data = doc.data();
                                setCountry(data.country)
                                const ref = firestore.collection('AppSetting').doc('CountrySettings');
                                ref.get()
                                    .then((doc) => {
                                        const dat = doc.data();
                                        setCountrySettings(dat[data.country]);
                                    })
                                const storeName = data.store_name;
                                setStoreName(storeName);
                                if (masterStoreRef.id === user.email) {
                                    setSubStoreList(data.sub_stores);
                                    setSubStorePhoneNumberMap(data.subStoreAdminPhoneNumbers);
                                } else {
                                    if (data.subStoreAdminPhoneNumbers.hasOwnProperty('Administrador')) {
                                        setSubStorePhoneNumberMap({ [userName]: data.subStoreAdminPhoneNumbers[userName], Administrador: data.subStoreAdminPhoneNumbers['Administrador'] });
                                    } else {
                                        setSubStorePhoneNumberMap({ [userName]: data.subStoreAdminPhoneNumbers[userName] });
                                    }
                                }
                                setIsLoading(false);
                                if (data['2fa_needed']) {
                                    setShowAuthCodeScreen(true);
                                }
                            });
                        setIsLoading(false);
                    });
            } else {
                // User is signed out
                // ...
                navigate("/login");
                console.log("user is logged out");
                setIsLoading(false);
            }
        });

    }, [])

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': storeEmail,
                    'x-user-email': storeUser
                },
                body: JSON.stringify({ ...data, country: country })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)
                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const isNumber = async (value) => {
        return /^[+-]?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?(,\d+)?$/.test(value);
    }

    const sendPaymentNotice = async () => {
        setLoginError('');
        setFormError('');
        if (!amountReceived || !cedula || !subStore) {
            setLoginError('Llene todos los datos para continuar');
            return;
        } else if (await isNumber(amountReceived) === false) {
            setFormError('El monto a pagar debe ser expresado en números.');
            return;
        } else if (amountReceived > countrySettings.max_cuota_payment) {
            setFormError('El monto máximo por registro es de ' + countrySettings.currency_code + countrySettings.max_cuota_payment + ', si desea registrar más del máximo puede realizarlo en dos (o más) registros.');
            return;
        }
        const result = window.confirm("Deseas continuar con este registro de " + countrySettings.currency_code + amountReceived + "?")
        if (!result) {
            return;
        }
        setIsLoading(true);
        try {
            await confirmPayment();
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const sendPaymentConfirmation = async (doc) => {
        const data = {
            payment_object_id: doc,
            payment_amount: parseFloat(convertToDotDecimal(amountReceived)),
            store_name: storeName,
            sub_store: subStore,
            identification_number: cedula,
            store_user: storeUser
        }
        return await sendPostRequest(data, 'payments/processstorepayment/', token) //status, success, data 
    }

    const sendEmailConfirmation = async (amount_paid, payment_confirmation, store_name, store_email, client_email) => {
        const data = {
            amount_paid: amount_paid,
            payment_confirmation: payment_confirmation,
            store_name: store_name,
            store_email: store_email,
            sub_store: subStore,
            client_email: client_email,
            store_user: storeUser
        }
        return await sendPostRequest(data, 'confirm_store_payment/', token) //status, success, data 
    }


    function convertToDotDecimal(str) {
        if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
            return str.replace(/,/g, ".");
        } else {
            return str;
        }
    }

    function generateRandomHash() {
        const randomNumber = Math.floor(Math.random() * 1000000);

        // Ensure the number has exactly 6 digits by adding leading zeros if needed
        const sixDigitNumber = randomNumber.toString().padStart(6, '0');

        return sixDigitNumber;
    }

    const updatePaymentDoc = async () => {

        const paymentsCollection = firestore.collection('Payments');

        // Get a reference to the Firestore collection for users
        const usersCollection = firestore.collection('Users');

        const queryUser = usersCollection
            .where('identification_number', '==', cedula)
            .limit(1);
        await queryUser.get().then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
                setLoginError('No existe ningun cliente asociado a esta cédula')
                setIsLoading(false);
                return;
            }
            querySnapshot.forEach((doc1) => {
                const query = paymentsCollection
                    .where('user', '==', usersCollection.doc(doc1.id))
                    .where('paid', '==', false)
                    .where('is_active', '==', true)
                    .orderBy('dueDate', 'asc')
                    .limit(1);
                query.get().then((querySnapshot) => {

                    if (querySnapshot.docs.length === 0) {
                        setLoginError('Este cliente no tiene cuotas pendientes')
                        setIsLoading(false);
                        return;
                    }

                    querySnapshot.forEach(async (doc) => {
                        const amountReceivedInStore = convertToDotDecimal(amountReceived)
                        var randHash = authCode ? authCode : generateRandomHash();

                        const response = await sendPaymentConfirmation(doc.id);
                        if (response?.success) {
                            const response2 = await sendEmailConfirmation(amountReceivedInStore, response.payment_confirmation_code, storeName, storeEmail, doc.data()['user'].id);
                            setInfoMessage('Información de pago recibida exitosamente')
                            setCedula('');
                            setAmountReceived('');
                            setIsLoading(false)
                            setTimeout(() => {
                                window.location.reload();
                            }, 5000);
                        } else if (response?.success == false) {
                            setIsLoading(false);
                            setFormError(response.status)
                        } else {
                            setIsLoading(false)
                            setFormError("Ocurrió un problema")
                        }
                    });
                }).catch((error) => {
                    console.log('Error querying payments:', error);
                    setLoginError('Ocurrió un problema, intente de nuevo más tarde.')
                    setIsLoading(false);
                });
            })
        })
    }

    const confirmPayment = async () => {
        await updatePaymentDoc(amountReceived);
    }

    const confirmAuthCode = async () => {
        setIsLoading(true);
        try {
            const codeData = {
                confirmation_code: authCode
            }
            const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token) //status, success, data
            if (response?.success) {
                setShowAuthCodeScreen(false);
                setIsLoading(false);
            } else if (response?.success === false) {
                setFormError(response.status);
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const sendAuthCode = async () => {
        setIsLoading(true);
        try {
            const codeData = {
                phone_number: subStorePhoneNumberMap[subStore2],
                code_type: 'sms'
            }
            console.log(codeData)
            const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token) //status, success, data
            console.log(response)
            if (response?.success) {
                setShowInputAuthCode(false);
                setIsLoading(false);
            } else if (response?.success === false) {
                setFormError(response.status);
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const goBackFromAuth = async () => {
        setShowInputAuthCode(true);
        setFormError('');
    }

    //Logic to hide pago en tienda 
    return (
        <div>
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginTop: 100,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
            }}>

                {showAuthCodeScreen ?
                    <>
                        {showInputAuthCode ? <>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                Registrar pago
                            </Typography>
                            <p className={classes.approvalMsg}>Le enviaremos un código de verificación por SMS al gerente del local para que autorice esta acción</p>
                            <TextField
                                select
                                label="Local"
                                name="subStore2"
                                value={subStore2}
                                onChange={(e) => setSubStore2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            >
                                {Object.keys(subStorePhoneNumberMap).map((item, index) => (
                                    <MenuItem value={item}>{item + " - ******" + subStorePhoneNumberMap[item].substring(subStorePhoneNumberMap[item].length - 4)}</MenuItem>
                                ))}
                            </TextField>
                            {formError && <p className={classes.formError}>{formError}</p>}
                            <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={sendAuthCode} fullWidth>
                                Siguiente
                            </Button>
                        </> :
                            <>
                                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                    Registrar Pago
                                </Typography>
                                <p className={classes.approvalMsg}>{"Por favor introduzca el código enviado"}</p>
                                <TextField
                                    label="Código de verificación"
                                    name="authCode"
                                    value={authCode}
                                    onChange={(e) => setAuthCode(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                ></TextField>
                                {formError && <p className={classes.formError}>{formError}</p>}
                                <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={confirmAuthCode} fullWidth>
                                    Siguiente
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<ArrowBack />}
                                    onClick={goBackFromAuth}
                                    className={classes.welcomeButtons}
                                >
                                    Atrás
                                </Button>
                            </>}
                    </> :
                    <>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Registrar Pago
                        </Typography>
                        <p className={classes.approvalMsg}>{"Por favor registra el monto recibido en " + countrySettings.currency_name}</p>
                        <TextField
                            label="Cédula del cliente"
                            name="cedula"
                            value={cedula}
                            onChange={(e) => setCedula(e.target.value.toLowerCase().trim())}
                            id="outlined-number"
                            margin="normal"
                            className={classes.textFields}
                            fullWidth
                            error={loginError !== ''}
                            helperText={loginError}
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />
                        <TextField
                            label={"Monto recibido (en " + countrySettings.currency_name + ")"}
                            name="amountReceived"
                            value={amountReceived}
                            onChange={(e) => setAmountReceived(e.target.value)}
                            id="outlined-number"
                            margin="normal"
                            className={classes.textFields}
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />
                        <TextField
                            select
                            label="Local"
                            name="subStore"
                            value={subStore}
                            onChange={(e) => setSubStore(e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        >
                            {subStoreList.map((item, index) => (
                                <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </TextField>
                        {formError && <p className={classes.formError}>{formError}</p>}
                        {infoMessage && <p style={{ textAlign: 'center', color: "white", fontSize: 16 }}>{infoMessage}<CheckCircle style={{ color: "#45C4D6" }} /></p>}
                        <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={sendPaymentNotice} fullWidth>
                            Enviar
                        </Button>
                    </>
                }
                {isLoading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
                    <BeatLoader color="#45C4D6" />
                </div>}
            </Container>
        </div >
    );
};

const useStyles = makeStyles((theme) => ({
    approvalMsg: {
        textAlign: 'center', color: "silver", fontSize: 16
    },

    switch_track: {
        backgroundColor: "gray",
    },
    switch_base: {
        color: "gray",
        "&.Mui-disabled": {
            color: "gray"
        },
        "&.Mui-checked": {
            color: "gray"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "gray",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#65C466",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#65C466",
        },
    },
    totalAmt: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtons2: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 15,
        marginLeft: 15,
        height: 45,
        width: 200
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    textFields2: {
        color: 'white',
    },
    textLabel: {
        color: 'white'
    },
    textInput: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        border: "1px solid #ccc",
        borderRadius: '8px',
        padding: 8,
        fontSize: 16,
        width: 100
    },
    paymentItem: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 10,
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        height: 700,
    },
    topButton: {
        flex: 1
    },
    formError: {
        color: "#e84e4f"
    },
    title: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    title2: {
        color: 'white',
        fontSize: 16,
        marginBottom: 10
    },
    highlighted: {
        color: '#45C4D6',
    },
    appBarButton: {
        width: 200
    },
    border: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        padding: "10px",
        marginBottom: 20,
    },
    border2: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingBottom: 5,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#1C1C1E',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
}));

export default ReceivePayment;
