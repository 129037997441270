import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../context/GlobalContext';

export default function useFetch<T extends keyof API>(
	endpoint: T,
	verb: HTTPVerbs,
	condition: boolean,
	initPayload?: APIRequestBody<T>
) {
	const { userData } = useContext(GlobalContext);
	const token = userData?.firebase_token;

	const [refetch, setRefetch] = useState(0);

	const [payload, setPayload] = useState(initPayload ? initPayload : null);

	const [fetched, setFetched] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<boolean | string>(false);
	const [data, setData] = useState<null | API[T]['response']>(null);

	useEffect(() => {
		if (!token || !condition) return;

		setLoading(true);
		setError(false);

		const url = new URL(endpoint);
		if (payload && verb === 'GET') Object.keys(payload).forEach((key) => url.searchParams.append(key, (payload as any)[key]));
		fetch(url, {
			method: verb,
			...(payload && verb !== 'GET' && { body: JSON.stringify(payload) }),
			headers: {
				Authorization: `Bearer ${token}`,
				...(verb !== 'GET' && { 'Content-Type': 'application/json' }),
				...(userData?.master_store?.id && { 'X-Master-Store-Email': userData.master_store.id }),
				...(userData?.email && { 'X-User-Email': userData.email })
			}
		})
			.then((response) => {
				if (!response.ok) throw new Error();
				else return response.json();
			})
			.then((data: API[T]['response'] | { success: boolean; status: string }) => {
				if (typeof data === 'object' && 'success' in data && !data.success) {
					if (data?.status && typeof data.status === 'string') setError(data?.status);
					else setError('Ha ocurrido un error. Intente más tarde.');
				} else {
					setData(typeof data === 'object' && (('data' in data) as any) ? (data as any).data : data);
				}
			})
			.catch(() => setError('Ha ocurrido un error. Intente más tarde.'))
			.finally(() => {
				setLoading(false);
				setFetched(true);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload, condition, refetch]);

	return { loading, data, error, setPayload, payload, fetched, refetch: () => setRefetch((value) => value + 1) };
}

type HTTPVerbs = 'POST' | 'GET' | 'PUT' | 'DELETE';

export interface API {
	'https://api.krece.app/storeprofile/brand/?GET': {
		request: { email: string };
		response: {
			brand: string;
			instagram: string;
			whatsapp: string;
		}[];
	};
	'https://api.krece.app/storeprofile/brand/?POST': {
		request: {
			email: string;
			brands: {
				brand: string;
				instagram: string;
				whatsapp: string;
			}[];
		};
		response: any;
	};
	'https://api.krece.app/storeprofile/branch/?GET': {
		request: { email: string };
		response: {
			[key: string]: {
				info: {
					instagram: string;
					whatsapp: string;
					logo?: string;
				};
				sucursales: {
					store_name: string;
					store_email: string;
					hours_of_operation: string;
					location: string;
					city: string;
					is_active: boolean;
				}[];
			};
		};
	};
	'https://api.krece.app/storeprofile/branch/?POST+PUT': {
		request: {
			email: string;
			store_email: string;
			brand: string;
			store_name: string;
			city: string;
			location: string;
			hours_of_operation: string;
		};
		response: any;
	};
	'https://api.krece.app/storeprofile/branch/?DELETE': {
		request: { email: string; store_email: string };
		response: any;
	};
	'https://api.krece.app/storeprofile/store_payment_methods/': {
		request: { email: string };
		response: {
			transferBs?: {
				rif: string;
				type: string;
				bank: string;
				account: string;
				titular: string;
			};
			pagomovil?: {
				bank: string;
				rif: string;
				phoneNumber: string;
			};
			apolo_client_id?: string;
		};
	};
	'https://api.krece.app/storeprofile/store_payment_methods/?PUT': {
		request: {
			master_store_email: string;
			transferBs?: {
				rif: string;
				type: string;
				bank: string;
				account: string;
				titular: string;
			};
			pagomovil?: {
				bank: string;
				rif: string;
				phoneNumber: string;
			};
			apolo_client_id?: string;
		};
		response: any;
	};
	'https://api.krece.app/tables/payments/': {
		request: TableRequest;
		response: TableResponse;
	};
	'https://api.krece.app/tables/devices/': {
		request: TableRequest;
		response: TableResponse;
	};
	'https://api.krece.app/tables/deleted_devices/': {
		request: TableRequest;
		response: TableResponse;
	};
	'https://api.krece.app/tables/transactions/': {
		request: TableRequest;
		response: TableResponse;
	};
	'https://api.krece.app/tables/delinquent_users/': {
		request: TableRequest;
		response: TableResponse;
	};
	'https://api.krece.app/tables/devicespagados/': {
		request: TableRequest;
		response: TableResponse;
	};
	'https://api.krece.app/tables/storesummarydata/': {
		request: { master_store_email: string; filter_by?: [{ column_name: 'sub_store'; value: string }] };
		response: {
			column_name: 'hoy' | 'ayer' | 'semana actual' | 'semana anterior';
			ventas: number;
			monto_financiado: number;
			pagos_en_tienda: number;
			percent_diff_monto_financiado: number;
			percent_diff_pagos_en_tienda: number;
			percent_diff_ventas: number;
		}[];
	};
}

type IfHasRequest<T> = 'request' extends keyof T ? T['request'] : undefined;
type APIRequestBody<T extends keyof API> = IfHasRequest<API[T]>;

export type APIEndpoints = keyof API;

export interface TableRequest {
	master_store_email: string;
	last_object_id: null | string;
	filter_by?: {
		column_name: string;
		value: string | boolean;
	}[];
	order_by?: {
		column_name: string;
		column_order: 'ASC' | 'DESC';
	};
	search_by?: {
		column_name: string;
		value: string;
	};
	date_range?:
		| { from_date: string; to_date: string }
		| {
				custom_range: 'Ver todo' | 'Hoy' | 'Ayer' | 'Semana actual' | 'Última semana' | 'Este mes a la fecha' | 'Últimos 30 días';
		  };
	batch_size?: number;
	download_file?: boolean;
}

export interface TableResponse {
	last_object_id: null | string;
	column_names: string[];
	rows: string[][];
	order_by: {
		column_name: string;
		column_order: 'ASC' | 'DESC';
	};
	total_count: number;
	download_link?: string;
}
