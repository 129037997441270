import classNames from 'classnames';
import { Search, X } from 'lucide-react';
import React, { useState, ReactElement, useRef, ChangeEvent } from 'react';
import { numbers_dot, numbers_letters_dot_comma } from '../utils/validate';

export default function SearchInput({
	disabled,
	allowedChars,
	placeholder,
	onChange,
	onSubmit,
	className = '',
	Icon
}: {
	disabled?: boolean;
	allowedChars: 'numeric' | 'alphanumeric';
	placeholder: string;
	onSubmit?: (value: string) => void;
	onChange?: (value: string) => void;
	className?: string;
	Icon?: ReactElement;
}) {
	const [inputValue, setInputValue] = useState<string>('');
	const inputEl = useRef<null | HTMLInputElement>(null);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;

		if (allowedChars) {
			value = allowedChars === 'numeric' ? value.replaceAll(numbers_dot, '') : value.replaceAll(numbers_letters_dot_comma, '');
		}
		setInputValue(value);

		if (onChange) {
			onChange(value);
		}
	};

	const clearInput = () => {
		setInputValue('');
		inputEl.current?.focus();

		if (onChange) onChange('');
		if (onSubmit) onSubmit('');
	};

	return (
		<div
			data-disabled={disabled || false}
			className={classNames(
				'flex h-10 items-center justify-between gap-1 overflow-hidden rounded-lg bg-neutral-950 p-0 px-3 has-[:focus]:[box-shadow:inset_0_0_0_1.5px_hsl(var(--twc-neutral-800))] data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-60',
				className
			)}
		>
			{Icon && <div className="shrink-0">{Icon}</div>}

			<input
				type="text"
				className="peer w-full bg-transparent text-sm outline-none placeholder:text-neutral-300"
				placeholder={placeholder}
				ref={inputEl}
				value={inputValue}
				onChange={handleOnChange}
				onKeyUp={(event) => {
					if (event.key === 'Enter' && onSubmit) onSubmit(inputValue);
				}}
			/>

			<button
				tabIndex={-1}
				onClick={clearInput}
				className={classNames('mr-0.5 flex h-full shrink-0 items-center justify-center gap-1', {
					'pointer-events-none opacity-0': !inputValue.trim()
				})}
			>
				<X className="size-[1.125rem]" />
				<div className="h-1/2 w-[1.25px] bg-neutral-800"></div>
			</button>

			<button
				onClick={() => {
					if (onSubmit) onSubmit(inputValue);
				}}
				className="flex shrink-0 items-center justify-center bg-neutral-950 transition-colors hover:text-primary-300"
			>
				<Search className="size-5" strokeWidth={2.5} />
			</button>
		</div>
	);
}
