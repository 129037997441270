import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { InsertChart, Home, ExitToApp, Payment, Edit, KeyboardArrowDownOutlined, Receipt, AccountBalanceWallet, Person } from '@material-ui/icons';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MenuButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickItem1 = () => {
        //props.onHomeClick()
        props.onItem1Click()
        handleClose()
    };
    const handleClickItem2 = () => {
        // props.onChartClick()
        props.onItem2Click()
        handleClose()
    };
    const handleClickItem3 = () => {
        // props.onLogOut()
        props.onItem3Click()
        handleClose()
    };
    const handleClickItem4 = () => {
        // props.onPaymentClick()
        props.onItem4Click()
        handleClose()
    };
    const handleClickItem5 = () => {
        // props.onPaymentClick()
        props.onItem5Click()
        handleClose()
    };
    const handleClickItem6 = () => {
        // props.onPaymentClick()
        props.onItem6Click()
        handleClose()
    };

    const handleClickItem7 = () => {
        // props.onPaymentClick()
        props.onItem7Click()
        handleClose()
    };

    const handleClickItem8 = () => {
        // props.onPaymentClick()
        props.onItem8Click()
        handleClose()
    };


    return (
        <div style={{ marginTop: 20, marginLeft: 20 }}>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                style={{
                    backgroundColor: '#1C1C1E',
                    color: '#fff',
                    marginBottom: 5,
                    marginRight: 100
                }}
                endIcon={<KeyboardArrowDownOutlined />}
            >
                Menu
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {props.label1 && <MenuItem onClick={handleClickItem1} disableRipple>
                    {props.showIcon && <Home />}
                    {props.label1}
                </MenuItem>}
                {props.label2 && <MenuItem onClick={handleClickItem2} disableRipple>
                    {props.showIcon && <InsertChart />}
                    {props.label2}
                </MenuItem>}
                {props.label7 && <MenuItem onClick={handleClickItem7} disableRipple>
                    {props.showIcon && <AccountBalanceWallet />}
                    {props.label7}
                </MenuItem>}
                {props.label8 && <MenuItem onClick={handleClickItem8} disableRipple>
                    {props.showIcon && <Person />}
                    {props.label8}
                </MenuItem>}
                {props.showPaymentBut && <MenuItem onClick={handleClickItem4} disableRipple>
                    {props.showIcon && <Payment />}
                    {props.label4}
                </MenuItem>}
                {props.label6 && <MenuItem onClick={handleClickItem6} disableRipple>
                    {props.showIcon && <Receipt />}
                    {props.label6}
                </MenuItem>}
                {props.label5 && <MenuItem onClick={handleClickItem5} disableRipple>
                    {props.showIcon && <Edit />}
                    {props.label5}
                </MenuItem>}
                {props.label3 && <MenuItem onClick={handleClickItem3} disableRipple>
                    {props.showIcon && <ExitToApp />}
                    {props.label3}
                </MenuItem>}
            </StyledMenu>
        </div>
    );
}