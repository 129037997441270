export function validate_email(email: string) {
	if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) return false;
	return true;
}
export function validate_rif(rif: string) {
	if (rif.length < 9 || rif.length > 12) return false;
	if (!rif.match(/^[A-Za-z]-?\d{8,10}$/)) return false;
	return true;
}
export const numbers_only = /[^0-9]/g;
export const numbers_dot = /[^0-9.]/g;
export const numbers_letters_dot_comma = /[^a-zA-Z0-9.,\sáéíóúüÁÉÍÓÚÜÑñ]/g;
