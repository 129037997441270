import * as Accordion from '@radix-ui/react-accordion';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as Switch from '@radix-ui/react-switch';
import classNames from 'classnames';
import {
	BarChart3,
	ChevronDown,
	ExternalLink,
	Home,
	LayoutGrid,
	LogOut,
	LucideProps,
	Menu,
	MoonStar,
	Plus,
	Sheet,
	SlidersHorizontal,
	SunMedium,
	UserPen,
	UserSearch,
	X
} from 'lucide-react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Link, Location, useLocation } from 'react-router-dom';
import Logo from '../components/Logo';
import useUserAlerts from '../hooks/userAlerts';

const DesktopNavigation = ({
	isOpen,
	setOpen,
	location,
	theme,
	setTheme,
	masterUser,
	selfFinanced,
	country
}: {
	location: Location;
	isOpen: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	theme: 'dark' | 'light';
	setTheme: React.Dispatch<React.SetStateAction<'dark' | 'light'>>;
	masterUser: boolean;
	selfFinanced: boolean;
	country: string | null;
}) => {
	const MenuCategory = ({
		label,
		children,
		Icon
	}: {
		label: string;
		Icon: React.ForwardRefExoticComponent<Omit<LucideProps, 'ref'>>;
		children: ReactNode;
	}) => (
		<Accordion.Item className="w-full select-none space-y-1.5" value={label}>
			<Accordion.Trigger className="group flex w-full cursor-pointer items-center justify-between text-[1.075rem] font-medium tracking-tight text-neutral-0">
				<div className="flex items-center justify-center gap-2 rounded-lg font-semibold transition-colors group-hover:text-primary-200 group-data-[state=open]:text-primary-200">
					<Icon className="size-5" strokeWidth={2.25} />
					<span>{label}</span>
				</div>

				<ChevronDown
					className="size-4 text-primary-200 transition-transform group-data-[state=closed]:-rotate-90"
					strokeWidth={3}
				/>
			</Accordion.Trigger>
			<Accordion.Content className="overflow-hidden data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown">
				<ul className="text-[0.9rem] text-neutral-200">{children}</ul>
			</Accordion.Content>
		</Accordion.Item>
	);

	const MenuItem = ({ label, path }: { label: string; path: string }) => (
		<li>
			<Link
				onClick={() => setOpen(false)}
				to={path}
				className={classNames(
					'block w-full rounded-lg px-2 py-1 transition-colors hover:bg-primary-500/50 hover:text-neutral-0',
					{
						'bg-primary-500/50 py-1 font-semibold text-neutral-0':
							location.pathname === path || (label === 'Inicio' && location.pathname === '/template')
					}
				)}
			>
				{label}
			</Link>
		</li>
	);

	const MenuCategories = ['Menu Principal', 'Ajustes', 'Tienda', 'Reportes', 'Administración'];

	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollAreaRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (scrollAreaRef.current) {
				setScrollPosition(scrollAreaRef.current.scrollTop);
			}
		};

		const currentScrollArea = scrollAreaRef.current;
		if (currentScrollArea) {
			currentScrollArea.scrollTop = scrollPosition;
			currentScrollArea.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (currentScrollArea) {
				currentScrollArea.removeEventListener('scroll', handleScroll);
			}
		};
	});

	return (
		<nav
			className={classNames(
				'ease-[cubic-bezier(0.87,0,0.13,1)] fixed right-0 z-50 flex h-svh shrink-0 translate-x-64 flex-col items-center justify-start bg-foreground transition-transform duration-0 md:!right-auto md:left-0 md:-translate-x-64 xl:relative xl:z-0 xl:translate-x-0 xl:transition-none',
				{
					'!translate-x-0 !duration-500': isOpen
				}
			)}
			onClick={(e) => e.stopPropagation()}
		>
			<div className="flex h-20 w-full items-center justify-between border-b border-neutral-800 px-6 xl:justify-center xl:px-0">
				<Link to={'/'}>
					<Logo size="full" className="w-28" />
				</Link>
				<X
					strokeWidth={2.66}
					className="size-7 cursor-pointer text-primary-200 transition-opacity hover:opacity-70 xl:hidden"
					onClick={(e) => {
						e.stopPropagation();
						setOpen(false);
					}}
				/>
			</div>

			<ScrollArea.Root className="h-full w-64 overflow-hidden">
				<ScrollArea.Viewport ref={scrollAreaRef} className="h-full w-full">
					<Accordion.Root type="multiple" defaultValue={MenuCategories} className="w-full space-y-8 p-6">
						<MenuCategory label="Menu Principal" Icon={LayoutGrid}>
							<MenuItem label="Inicio" path="/" />
							<MenuItem label="Nuevo registro" path="/newSale" />
							{/* 							<MenuItem label="Registrar pago" path="/receivePayment" /> */}
							<MenuItem label="Registrar pago" path="/buscarcliente" />
							<MenuItem label="Simulador" path="/simulator" />
						</MenuCategory>

						{masterUser && (
							<MenuCategory label="Ajustes" Icon={UserPen}>
								<MenuItem label="Sucursales" path="/sucursales" />
								{country === 'venezuela' && selfFinanced && <MenuItem label="Métodos de pago" path="/metodos-de-pago" />}
							</MenuCategory>
						)}

						{/* <MenuCategory label="Tienda" Icon={ShoppingCart}>
							<MenuItem label="Mis productos" path="#" />
							<MenuItem label="Ventas online" path="#" />
							<MenuItem label="Promociones" path="#" />
							<MenuItem label="Facturas" path="#" />
						</MenuCategory> */}

						<MenuCategory label="Reportes" Icon={BarChart3}>
							{!selfFinanced || !masterUser ? null : (
								<>
									<MenuItem label="Cuentas por cobrar" path="/cpc" />
									<MenuItem label="Cuotas" path="/reportes/cuotas" />
								</>
							)}
							<MenuItem label="Transacciones" path="/reportes/transacciones" />
							<MenuItem label="Ventas" path="/reportes/ventas" />
							{/* {selfFinanced && masterUser && (
								<MenuItem label="Ventas finalizadas" path="/reportes/ventas-finalizadas" />
							)} */}
							<MenuItem label="Registros eliminados" path="/reportes/registros-eliminados" />
							{masterUser && <MenuItem label="Clientes en mora" path="/reportes/delincuentes" />}
						</MenuCategory>

						<MenuCategory label="Administración" Icon={SlidersHorizontal}>
							<MenuItem label="Eliminar venta" path="/deleteSale" />
							<MenuItem label="Cambiar correo" path="/changeEmail" />
							<MenuItem label="Cambiar precio de venta" path="/changePrice" />
							<MenuItem label="Eliminar Pago" path="/deletePayment" />
							<MenuItem label="Cambio por garantía" path="/warranty" />
							{/* <MenuItem label="Acciones" path="#" /> */}
						</MenuCategory>
					</Accordion.Root>
				</ScrollArea.Viewport>
				<ScrollArea.Scrollbar
					className="duration-[160ms] flex touch-none select-none p-0.5 ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
					orientation="vertical"
				>
					<ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
				</ScrollArea.Scrollbar>
			</ScrollArea.Root>

			<form className="flex h-20 w-full items-center justify-end border-t border-neutral-800 px-4">
				<div className="hidden items-center justify-center gap-1.5">
					<MoonStar className={classNames({ 'text-neutral-0': theme === 'dark', 'text-neutral-500': theme === 'light' })} />
					<Switch.Root
						onCheckedChange={(checked) => setTheme(checked ? 'light' : 'dark')}
						className="relative h-[25px] w-[42px] cursor-pointer rounded-full outline-none [-webkit-tap-highlight-color:rgba(0,0,0,0)] data-[state=checked]:bg-yellow-500 data-[state=unchecked]:bg-neutral-900"
					>
						<Switch.Thumb className="block h-[21px] w-[21px] translate-x-0.5 rounded-full bg-neutral-50 transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[19px]" />
					</Switch.Root>
					<SunMedium className={classNames({ 'text-neutral-0': theme === 'light', 'text-neutral-500': theme === 'dark' })} />
				</div>
				<Link
					to={'/logout'}
					className="flex items-center justify-center gap-1 rounded-lg border-[1.5px] border-neutral-700 bg-neutral-800 p-1.5 text-sm text-neutral-200 transition-opacity hover:opacity-75"
				>
					Salir
					<LogOut className="size-4" strokeWidth={2.5} />
				</Link>
			</form>
		</nav>
	);
};

const TabletNavigation = ({
	setOpen,
	location,
	theme,
	setTheme
}: {
	location: Location;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	theme: 'dark' | 'light';
	setTheme: React.Dispatch<React.SetStateAction<'dark' | 'light'>>;
}) => {
	const MenuItem = ({
		label,
		path,
		Icon
	}: {
		label: string;
		path: string;
		Icon: React.ForwardRefExoticComponent<Omit<LucideProps, 'ref'>>;
	}) => (
		<li>
			<Link
				to={path}
				className={classNames(
					'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200',
					{
						'!text-primary-200': location.pathname === path
					}
				)}
			>
				<div
					className={classNames(
						'flex size-8 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-0',
						{
							'!bg-primary-500/50 !text-neutral-0': location.pathname === path
						}
					)}
				>
					<Icon className="size-6" strokeWidth={2.5} />
				</div>
				{label}
			</Link>
		</li>
	);

	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollAreaRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (scrollAreaRef.current) {
				setScrollPosition(scrollAreaRef.current.scrollTop);
			}
		};

		const currentScrollArea = scrollAreaRef.current;
		if (currentScrollArea) {
			currentScrollArea.scrollTop = scrollPosition;
			currentScrollArea.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (currentScrollArea) {
				currentScrollArea.removeEventListener('scroll', handleScroll);
			}
		};
	});

	return (
		<ScrollArea.Root className="hidden h-full w-16 overflow-hidden md:block xl:hidden">
			<ScrollArea.Viewport ref={scrollAreaRef} className="h-full w-full">
				<nav className="flex h-svh w-full shrink-0 flex-col items-center justify-between gap-8 bg-foreground">
					<Link to={'/'} className="flex h-20 w-full shrink-0 items-center justify-center border-b border-neutral-800">
						<Logo size="min" className="size-9" />
					</Link>
					<ul className="flex shrink-0 flex-col gap-2">
						<li>
							<Link
								to={'/'}
								className={classNames(
									'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 hover:bg-primary-500/50 hover:text-neutral-0',
									{
										'!bg-primary-500/50 !text-neutral-0': location.pathname === '/' || location.pathname === '/template'
									}
								)}
							>
								<Home className="size-6" />
							</Link>
						</li>
						<li
							className="flex size-9 cursor-pointer items-center justify-center rounded-lg text-neutral-100 transition-colors hover:text-primary-200"
							onClick={(e) => {
								e.stopPropagation();
								setOpen(true);
							}}
						>
							<LayoutGrid className="size-6" />
						</li>
					</ul>

					<ul className="flex shrink-0 flex-col gap-5 text-[0.7rem] font-semibold tracking-tight">
						<MenuItem label="Registro" path="/newSale" Icon={Plus} />
						<MenuItem label="Ventas" path="/reportes/ventas" Icon={Sheet} />
						<MenuItem label="Clientes" path="/buscarcliente" Icon={UserSearch} />
					</ul>

					<div className="flex w-full shrink-0 flex-col items-center justify-center gap-4 border-t border-neutral-800 py-4">
						<button
							className={classNames('hidden size-9 items-center justify-center gap-2 rounded-lg', {
								'bg-indigo-500/40': theme === 'dark',
								'bg-yellow-400/20': theme === 'light'
							})}
							onClick={(e) => {
								e.stopPropagation();
								setTheme(theme === 'dark' ? 'light' : 'dark');
							}}
						>
							<MoonStar className={classNames('size-6 cursor-pointer text-indigo-200', { hidden: theme === 'light' })} />
							<SunMedium className={classNames('size-6 cursor-pointer text-yellow-100', { hidden: theme === 'dark' })} />
						</button>

						<Link
							to={'/logout'}
							className="flex size-9 items-center justify-center gap-2 rounded-lg bg-neutral-800 transition-colors hover:bg-neutral-700"
						>
							<LogOut className="size-6 text-neutral-300" />
						</Link>
					</div>
				</nav>
			</ScrollArea.Viewport>
			<ScrollArea.Scrollbar
				className="duration-[160ms] flex touch-none select-none p-0.5 ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
				orientation="vertical"
			>
				<ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
			</ScrollArea.Scrollbar>
		</ScrollArea.Root>
	);
};

const MobileNavigation = ({
	setOpen,
	location
}: {
	location: Location;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	return (
		<nav className="flex w-full shrink-0 items-center justify-between bg-foreground px-4 py-2 md:hidden">
			<ul className="flex gap-6 text-[0.7rem] text-xs font-semibold tracking-tight min-[400px]:gap-8">
				<li>
					<Link
						to={'/'}
						className={classNames(
							'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200',
							{
								'!text-primary-200': location.pathname === '/' || location.pathname === '/template'
							}
						)}
					>
						<div
							className={classNames(
								'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100',
								{
									'!bg-primary-500/50 !text-neutral-0': location.pathname === '/' || location.pathname === '/template'
								}
							)}
						>
							<Logo size="min" className="size-6" fill="fill-neutral-100" />
						</div>
						Inicio
					</Link>
				</li>
				<li>
					<Link
						to={'/newSale'}
						className={classNames(
							'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200',
							{
								'!text-primary-200': location.pathname === '/newSale'
							}
						)}
					>
						<div
							className={classNames(
								'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100',
								{
									'!bg-primary-500/50 !text-neutral-0': location.pathname === '/newSale'
								}
							)}
						>
							<Plus className="size-6 scale-110" strokeWidth={2.5} />
						</div>
						Registro
					</Link>
				</li>
				<li>
					<Link
						to={'/reportes/ventas'}
						className={classNames(
							'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200',
							{
								'!text-primary-200': location.pathname === '/reportes/ventas'
							}
						)}
					>
						<div
							className={classNames(
								'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100',
								{
									'!bg-primary-500/50 !text-neutral-0': location.pathname === '/reportes/ventas'
								}
							)}
						>
							<Sheet className="size-6" />
						</div>
						Ventas
					</Link>
				</li>
				<li>
					<Link
						to={'/buscarcliente'}
						className={classNames(
							'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200',
							{
								'!text-primary-200': location.pathname === '/buscarcliente'
							}
						)}
					>
						<div
							className={classNames(
								'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100',
								{
									'!bg-primary-500/50 !text-neutral-0': location.pathname === '/buscarcliente'
								}
							)}
						>
							<UserSearch className="size-6" strokeWidth={2.25} />
						</div>
						Clientes
					</Link>
				</li>
			</ul>

			<button
				onClick={(e) => {
					e.stopPropagation();
					setOpen(true);
				}}
			>
				<Menu className="size-9 text-neutral-0 transition-colors hover:text-primary-200" />
			</button>
		</nav>
	);
};

const Overlay = () => (
	<div className={classNames('fixed z-40 size-full animate-[fadeOverlayIn_350ms_ease-in_forwards] bg-black opacity-0')}></div>
);

export default function Navigation({
	masterUser,
	selfFinanced,
	country
}: {
	masterUser: boolean;
	selfFinanced: boolean;
	country: string | null;
}) {
	const location = useLocation();

	const [theme, setTheme] = useState<'dark' | 'light'>('dark');
	const [isOpen, setOpen] = useState(false);

	const { shouldAddBranches, shouldAddPaymentMethods } = useUserAlerts();

	// Hide menu on outside click
	useEffect(() => {
		const page = document.getElementById('page');
		if (!page) return;

		const handleClick = () => setOpen(false);
		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	});

	return (
		<>
			<DesktopNavigation
				country={country}
				masterUser={masterUser}
				selfFinanced={selfFinanced}
				isOpen={isOpen}
				setOpen={setOpen}
				location={location}
				theme={theme}
				setTheme={setTheme}
			/>
			<TabletNavigation setOpen={setOpen} location={location} theme={theme} setTheme={setTheme} />
			<MobileNavigation setOpen={setOpen} location={location} />
			{isOpen && <Overlay />}

			{(shouldAddBranches || shouldAddPaymentMethods) && (
				<div className="fixed bottom-[5.25rem] right-[1rem] z-20 flex w-full select-none flex-col items-end justify-end gap-2 font-medium md:bottom-[3rem] md:right-[3rem]">
					{shouldAddPaymentMethods && (
						<span className="w-80 rounded-2xl border-[1.5px] border-red-400 bg-red-500/70 p-4">
							<Link to={'/metodos-de-pago'} className="flex cursor-pointer items-center gap-2">
								<ExternalLink className="size-4" /> ¡Debe añadir un método de pago!
							</Link>
						</span>
					)}
					{shouldAddBranches && (
						<span className="w-80 rounded-2xl border-[1.5px] border-red-400 bg-red-500/70 p-4">
							<Link to={'/sucursales'} className="flex cursor-pointer items-center gap-2">
								<ExternalLink className="size-4" /> ¡Debe añadir una sucursal!
							</Link>
						</span>
					)}
				</div>
			)}
		</>
	);
}
