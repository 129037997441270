import React, { useState } from 'react';

import ArrowDownIcon from '@heroicons/react/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';

import Stack from '@mui/material/Stack';

import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Divider,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';




const Dashboard = ({ storeName }) => {
    const [difference, setdifference] = useState('');
    const [positive, setpositive] = useState('');
    return (
        <div>
            <>
                <Card>
                    <CardContent>
                        <Stack
                            alignItems="flex-start"
                            direction="row"
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Stack spacing={1}>
                                <Typography
                                    color="text.secondary"
                                    variant="overline"
                                >
                                    Budget
                                </Typography>
                                <Typography variant="h4">
                                    2
                                </Typography>
                            </Stack>
                            <Avatar
                                sx={{
                                    backgroundColor: 'error.main',
                                    height: 56,
                                    width: 56
                                }}
                            >
                                <SvgIcon>
                                    <CurrencyDollarIcon />
                                </SvgIcon>
                            </Avatar>
                        </Stack>
                        {difference && (
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={0.5}
                                >
                                    <SvgIcon
                                        color={positive ? 'success' : 'error'}
                                        fontSize="small"
                                    >
                                        {positive ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                    </SvgIcon>
                                    <Typography
                                        color={positive ? 'success.main' : 'error.main'}
                                        variant="body2"
                                    >
                                        {difference}%
                                    </Typography>
                                </Stack>
                                <Typography
                                    color="text.secondary"
                                    variant="caption"
                                >
                                    Since last month
                                </Typography>
                            </Stack>
                        )}
                    </CardContent>
                </Card>
            </>
        </div>
    );
};
export default Dashboard;