import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { Building2, Clock, Edit, ImageUp, Info, Mail, Map, MapPin, Plus, Power, Store, Trash2 } from 'lucide-react';
import { Button } from '../shadcn/Button';
import { Dialog } from '../components/Dialog';
import Input from '../components/Input';
import Select from '../components/Select';
import { validate_email } from '../utils/validate';
import useFetch from '../hooks/api';
import firestore from '../firestore';
import Spinner, { ContainerSpinner } from '../components/Spinner';
import { Instagram, WhatsApp } from '@material-ui/icons';
import ErrorRedirectDialog from '../components/ErrorRedirectDialog';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames';
import Firebase from '../firebase_setup';

export default function Sucursales() {
	const { userData } = useContext(GlobalContext);
	const { master_store } = userData!;

	if (!userData?.master_user) Navigate({ to: '/' });

	const cities = GetCities(userData?.country || null);

	const { data, loading, error, refetch } = useFetch('https://api.krece.app/storeprofile/branch/?GET', 'GET', true, {
		email: master_store.id
	});

	const [selectedBranch, setSelectedBranch] = useState<Sucursal>();
	const [deleteBranch, setDeleteBranch] = useState<{ email: string; is_active: boolean } | null>(null);
	const [reactivateBranch, setReactivateBranch] = useState<{ email: string } | null>(null);

	const [selectedBrand, setSelectedBrand] = useState<Brand>();

	const [uploadingImage, setUploadingImage] = useState<false | string>(false);
	const [uploadImageError, setUploadingImageError] = useState<null | string>(null);

	const UpsertSucursalDialog = ({ isNew = false }) => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch(
			'https://api.krece.app/storeprofile/branch/?POST+PUT',
			!isNew ? 'PUT' : 'POST',
			submit
		);

		const [validationError, setValidationError] = useState(false);
		const emailValidation = (value: string) => validate_email(value.trim());
		const nameValidation = (value: string) => value.trim().length >= 3;
		const addressValidation = (value: string) => value.trim().includes(' ') && value.trim().length >= 7;
		const operatingHoursValidation = (value: string) => value.trim().length >= 20;
		const cityValidation = (value: string) => value.trim();

		function submitChanges() {
			if (!selectedBranch) return;

			if (
				!emailValidation(selectedBranch.store_email) ||
				!nameValidation(selectedBranch.store_name) ||
				!addressValidation(selectedBranch.location) ||
				!operatingHoursValidation(selectedBranch.hours_of_operation) ||
				!cityValidation(selectedBranch.city)
			) {
				setValidationError(true);
			} else {
				setPayload({ email: master_store.id, ...selectedBranch });
				setSubmit(true);
			}
		}

		useEffect(() => {
			if (fetched && !error) {
				setSelectedBranch(undefined);
				refetch();
				// window.location.reload();
			}
		}, [fetched, error]);

		if (!selectedBranch) return null;

		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!emailValidation(selectedBranch.store_email) && <li className="list-inside list-disc">Email</li>}
							{!nameValidation(selectedBranch.store_name) && <li className="list-inside list-disc">Nombre</li>}
							{!cityValidation(selectedBranch.city) && <li className="list-inside list-disc">Ciudad</li>}
							{!addressValidation(selectedBranch.location) && <li className="list-inside list-disc">Dirección</li>}
							{!operatingHoursValidation(selectedBranch.hours_of_operation) && <li className="list-inside list-disc">Horario</li>}
						</ul>
					</Dialog>
				)}
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title={isNew ? 'Nueva Sucursal' : 'Editar Sucursal'} closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<form
						className="space-y-6"
						onSubmit={(e) => {
							e.preventDefault();
						}}
					>
						<div className="flex flex-col gap-3">
							<Input
								maxLength={255}
								readonly={!isNew}
								value={selectedBranch.store_email.trim()}
								placeholder="Email..."
								Icon={<Mail className="size-4" />}
								validation={emailValidation}
								onChange={(value) => (selectedBranch.store_email = value.trim())}
							/>
							<Input
								maxLength={255}
								readonly={!isNew}
								value={selectedBranch.store_name.trim()}
								placeholder="Nombre de la sucursal..."
								Icon={<Store className="size-4" />}
								validation={nameValidation}
								onChange={(value) => (selectedBranch.store_name = value.trim())}
							/>
							<Select
								className="w-full bg-neutral-950"
								options={cities.map((e) => ({ label: e, value: e }))}
								defaultValue={selectedBranch.city || undefined}
								onSelectedChange={(value) => (selectedBranch.city = typeof value === 'string' ? value.trim() : '')}
								Icon={<Map className="size-4" />}
								placeholder="Ciudad..."
							/>
							{/* <div className="flex select-none items-center gap-1">
							<span className="mr-1 text-neutral-400">De</span>
							<Select
								className="h-8 !gap-0.5 bg-neutral-950 !px-2"
								options={[{ label: 'Lunes', value: 'Lunes' }]}
								defaultValue="Lunes"
								onSelectedChange={() => {}}
							/>
							<span className="mx-1 text-neutral-400">a</span>
							<Select
								className="h-8 !gap-0.5 bg-neutral-950 !px-2"
								options={[{ label: 'Viernes', value: 'Viernes' }]}
								defaultValue="Viernes"
								onSelectedChange={() => {}}
							/>
							<span className="w-4 text-neutral-400"></span>
							<span className="mr-1 text-neutral-400">De</span>
							<Select
								className="h-8 !gap-0.5 bg-neutral-950 !px-2"
								options={[{ label: '8', value: '8' }]}
								defaultValue="8"
								onSelectedChange={() => {}}
							/>
							<span className="font-medium">AM</span>
							<span className="mx-1 text-neutral-400">a</span>
							<Select
								className="h-8 !gap-0.5 bg-neutral-950 !px-2"
								options={[{ label: '5', value: '5' }]}
								defaultValue="5"
								onSelectedChange={() => {}}
							/>
							<span className="font-medium">PM</span>
						</div> */}
							<Input
								maxLength={255}
								placeholder="Dirección..."
								value={selectedBranch.location.trim()}
								Icon={<MapPin className="size-4" />}
								validation={addressValidation}
								onChange={(value) => (selectedBranch.location = value.trim())}
							/>
							<Input
								maxLength={255}
								placeholder="Horario..."
								value={selectedBranch.hours_of_operation.trim()}
								Icon={<Clock className="size-4" />}
								validation={operatingHoursValidation}
								onChange={(value) => (selectedBranch.hours_of_operation = value.trim())}
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<span className="flex shrink-0 select-none items-center gap-1 text-neutral-500">
									<Info className="size-3.5" />
									Ej.
								</span>
								Lunes - Sábado 830 AM a 5 PM, Domingo 830 AM a 5 PM
							</p>
						</div>
						<div className="flex justify-end gap-2">
							<Button
								variant="default"
								className="bg-neutral-900 hover:bg-neutral-900/70"
								onClick={() => setSelectedBranch(undefined)}
							>
								Cancelar
							</Button>
							<Button type="submit" variant="default" className="bg-sky-600 hover:bg-sky-800" onClick={submitChanges}>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	const UpsertBrandDialog = ({ isNew = false, image = false }) => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch('https://api.krece.app/storeprofile/brand/?POST', 'POST', submit);

		const [validationError, setValidationError] = useState(false);
		const nameValidation = (value: string) => value.trim().length >= 4;
		const phoneValidation = (value: string) => value.trim().length >= 7;
		const instagramValidation = (value: string) => value.trim().length >= 4;

		function submitChanges() {
			if (!selectedBrand || !data) return;

			if (
				!phoneValidation(selectedBrand.whatsapp) ||
				!nameValidation(selectedBrand.brand) ||
				!instagramValidation(selectedBrand.instagram)
			) {
				setValidationError(true);
			} else {
				const brands = Object.keys(data).map((brand) => ({
					brand,
					whatsapp: data[brand].info.whatsapp,
					instagram: data[brand].info.instagram,
					logo: data[brand].info?.logo || null
				}));
				brands.forEach((brand, idx) => {
					if (brand.brand === selectedBrand.brand) {
						brands[idx] = selectedBrand;
					}
				});
				if (!brands.find((brand) => brand.brand === selectedBrand.brand)) brands.push(selectedBrand);

				setPayload({ email: master_store.id, brands });
				setSubmit(true);
			}
		}

		useEffect(() => {
			if (fetched && !error) {
				setSelectedBrand(undefined);
				refetch();
				if (image) setUploadingImage(false);
				// window.location.reload();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [fetched, error]);

		useEffect(() => {
			if (image) submitChanges();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		if (!selectedBrand) return null;
		if (image) return null;

		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!nameValidation(selectedBrand.brand) && <li className="list-inside list-disc">Nombre</li>}
							{!phoneValidation(selectedBrand.whatsapp) && <li className="list-inside list-disc">WhatsApp</li>}
							{!instagramValidation(selectedBrand.instagram) && <li className="list-inside list-disc">Instagram</li>}
						</ul>
					</Dialog>
				)}
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title={isNew ? 'Nuevo Grupo' : 'Editar Grupo'} closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
						<div className="flex flex-col gap-3">
							<Input
								allowedChars="alphanumeric"
								maxLength={255}
								readonly={!isNew}
								value={selectedBrand.brand.trim()}
								placeholder="Nombre..."
								Icon={<Building2 className="size-4" />}
								validation={nameValidation}
								onChange={(value) => (selectedBrand.brand = value.trim())}
							/>
							<Input
								allowedChars="numbers_only"
								maxLength={255}
								placeholder="WhatsApp..."
								value={selectedBrand.whatsapp.trim()}
								Icon={<WhatsApp className="!text-[1.2rem]" />}
								validation={phoneValidation}
								onChange={(value) => (selectedBrand.whatsapp = value.trim())}
							/>
							<Input
								maxLength={255}
								placeholder="Instagram..."
								value={selectedBrand.instagram.trim()}
								Icon={<Instagram className="!text-[1.2rem]" />}
								validation={instagramValidation}
								onChange={(value) => (selectedBrand.instagram = value.trim())}
							/>
						</div>
						<div className="flex justify-end gap-2">
							<Button
								variant="default"
								className="bg-neutral-900 hover:bg-neutral-900/70"
								onClick={() => setSelectedBrand(undefined)}
							>
								Cancelar
							</Button>
							<Button type="submit" variant="default" className="bg-sky-600 hover:bg-sky-800" onClick={submitChanges}>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	const DeleteSucursalDialog = () => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, fetched } = useFetch('https://api.krece.app/storeprofile/branch/?DELETE', 'DELETE', submit, {
			store_email: deleteBranch!.email,
			email: master_store.id
		});

		useEffect(() => {
			if (fetched && !error) {
				setDeleteBranch(null);
				refetch();
				// window.location.reload();
			}
		}, [fetched, error]);

		return (
			<>
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title={deleteBranch?.is_active ? 'Desactivar Sucursal' : 'Eliminar Sucursal'} closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<div className="space-y-6">
						<p className="mb-2">
							¿Está seguro que desea {deleteBranch?.is_active ? 'desactivar la sucursal ' : 'eliminar la sucursal y su cuenta '}
							asociada con el email <strong>{deleteBranch?.email}</strong>?
						</p>
						{deleteBranch?.is_active ? (
							<span className="rounded-lg border border-yellow-400/90 bg-yellow-900/20 p-1.5 text-sm font-bold text-yellow-400">
								Podrá reactivarla más adelante si desea
							</span>
						) : (
							<span className="rounded-lg border border-red-400/90 bg-red-900/20 p-1.5 text-sm font-bold text-red-400">
								¡Esta acción es irreversible!
							</span>
						)}
						<div className="flex justify-end gap-2">
							<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setDeleteBranch(null)}>
								Cancelar
							</Button>
							<Button
								type="submit"
								variant="default"
								className={classNames({
									'bg-red-500 text-red-100 hover:bg-red-700': !deleteBranch?.is_active,
									'bg-yellow-500/80 text-yellow-100 hover:bg-yellow-700': deleteBranch?.is_active
								})}
								onClick={() => setSubmit(true)}
							>
								{deleteBranch?.is_active ? 'Desactivar' : 'Eliminar'}
							</Button>
						</div>
					</div>
				</Dialog>
			</>
		);
	};

	const ReactivateSucursalDialog = () => {
		// const [submit, setSubmit] = useState(false);
		// const { loading, error, fetched } = useFetch('https://api.krece.app/storeprofile/branch/?DELETE', 'DELETE', submit, {
		// 	store_email: deleteBranch!.email,
		// 	email: master_store.id
		// });

		// useEffect(() => {
		// 	if (fetched && !error) {
		// 		// setDeleteBranchEmail('');
		// 		// refetch();
		// 		window.location.reload();
		// 	}
		// }, [fetched, error]);

		// return (
		// 	<>
		// 		{error && (
		// 			<Dialog title="¡Error!" zIndex={52}>
		// 				<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
		// 			</Dialog>
		// 		)}
		// 		<Dialog title={deleteBranch?.is_active ? 'Desactivar Sucursal' : 'Eliminar Sucursal'} closable={false}>
		// 			{loading && (
		// 				<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
		// 					<ContainerSpinner size={2.75} />
		// 				</div>
		// 			)}
		// 			<div className="space-y-6">
		// 				<p className="mb-2">
		// 					¿Está seguro que desea {deleteBranch?.is_active ? 'desactivar la sucursal ' : 'eliminar la sucursal y su cuenta '}
		// 					asociada con el email <strong>{deleteBranch?.email}</strong>?
		// 				</p>
		// 				{deleteBranch?.is_active ? (
		// 					<span className="rounded-lg border border-yellow-400/90 bg-yellow-900/20 p-1.5 text-sm font-bold text-yellow-400">
		// 						Podrá reactivarla más adelante si desea
		// 					</span>
		// 				) : (
		// 					<span className="rounded-lg border border-red-400/90 bg-red-900/20 p-1.5 text-sm font-bold text-red-400">
		// 						¡Esta acción es irreversible!
		// 					</span>
		// 				)}
		// 				<div className="flex justify-end gap-2">
		// 					<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setDeleteBranch(null)}>
		// 						Cancelar
		// 					</Button>
		// 					<Button
		// 						type="submit"
		// 						variant="default"
		// 						className={classNames({
		// 							'bg-red-500 text-red-100 hover:bg-red-700': !deleteBranch?.is_active,
		// 							'bg-yellow-500/80 text-yellow-100 hover:bg-yellow-700': deleteBranch?.is_active
		// 						})}
		// 						onClick={() => setSubmit(true)}
		// 					>
		// 						{deleteBranch?.is_active ? 'Desactivar' : 'Eliminar'}
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Dialog>
		// 	</>
		// );

		return (
			<Dialog title="Reactivar Sucursal" closable={false}>
				<div className="space-y-6">
					<p className="mb-2">Para reactivar esta sucursal, por favor contacte al equipo de Krece.</p>

					<div className="flex justify-end gap-2">
						<Button
							variant="default"
							className="bg-neutral-900 hover:bg-neutral-900/70"
							onClick={() => setReactivateBranch(null)}
						>
							Salir
						</Button>
					</div>
				</div>
			</Dialog>
		);
	};

	const Sucursal = ({ data }: { data: Sucursal }) => (
		<li>
			<article
				className={classNames('flex items-center justify-between gap-8 rounded-xl p-4', {
					'bg-neutral-950 md:bg-neutral-925/40': data?.is_active,
					'select-none bg-neutral-950/50 md:bg-neutral-925/20': !data?.is_active
				})}
			>
				<header
					className={classNames('flex w-full select-none items-center gap-3 md:w-56 md:shrink-0 min-[1120px]:w-72', {
						'opacity-65 grayscale': !data?.is_active
					})}
				>
					<div className="flex size-9 shrink-0 items-center justify-center rounded-full bg-primary-500 text-xl font-bold sm:size-11">
						{data.store_name[0].toUpperCase()}
					</div>
					<hgroup>
						<h1 className="overflow-hidden overflow-ellipsis text-nowrap text-lg leading-snug tracking-tight md:text-[1.066rem] min-[1120px]:text-lg">
							{data.store_name}
						</h1>
						<h2 className="text-sm text-neutral-300">{data.city}</h2>
					</hgroup>
				</header>
				<ul
					className={classNames('hidden w-full space-y-1 text-sm text-neutral-200/90 md:block', {
						'opacity-65 grayscale': !data?.is_active
					})}
				>
					<li className="flex items-center gap-2">
						<Mail className="size-4 shrink-0 text-neutral-600" />
						<p className="overflow-hidden overflow-ellipsis text-nowrap">{data.store_email}</p>
					</li>
					<li className="flex items-center gap-2">
						<MapPin className="size-4 shrink-0 text-neutral-600" />
						<p className="overflow-hidden overflow-ellipsis text-nowrap">{data.location}</p>
					</li>
					<li className="flex items-center gap-2">
						<Clock className="size-4 shrink-0 text-neutral-600" />
						<p className="overflow-hidden overflow-ellipsis text-nowrap">{data.hours_of_operation}</p>
					</li>
				</ul>
				<div className="flex shrink-0 items-center justify-end gap-2 md:flex-col min-[1120px]:flex-row">
					{data.is_active ? (
						<>
							<button
								className="flex size-10 shrink-0 items-center justify-center rounded-lg bg-neutral-900 transition-opacity hover:opacity-60 md:size-9 md:bg-neutral-950 min-[1120px]:size-10"
								onClick={() =>
									setSelectedBranch({
										store_email: data.store_email,
										city: data.city,
										hours_of_operation: data.hours_of_operation,
										location: data.location,
										store_name: data.store_name,
										brand: data.brand
									})
								}
							>
								<Edit className="size-5" />
							</button>
							<button
								className={classNames(
									`flex size-10 shrink-0 items-center justify-center rounded-lg bg-green-500/60 transition-all hover:bg-yellow-500/70 md:size-9 min-[1120px]:size-10`
								)}
								onClick={() => setDeleteBranch({ email: data.store_email, is_active: true })}
							>
								<Power className="size-5" strokeWidth={2.5} />
							</button>
						</>
					) : (
						<>
							<button
								className={classNames(
									`flex size-10 shrink-0 items-center justify-center rounded-lg bg-red-500/70 transition-opacity hover:opacity-60 md:size-9 min-[1120px]:size-10`
								)}
								onClick={() => setDeleteBranch({ email: data.store_email, is_active: false })}
							>
								<Trash2 className="size-5" />
							</button>
							<button
								className={classNames(
									`flex size-10 shrink-0 items-center justify-center rounded-lg bg-foreground bg-yellow-500/70 transition-all hover:bg-green-500/60 md:size-9 min-[1120px]:size-10`
								)}
								onClick={() => setReactivateBranch({ email: data.store_email })}
							>
								<Power className="size-5" strokeWidth={2.5} />
							</button>
						</>
					)}
				</div>
			</article>
		</li>
	);

	const Brand = ({
		brand,
		whatsapp,
		instagram,
		sucursales,
		logo
	}: Brand & {
		sucursales: Sucursal[];
	}) => (
		<section key={brand} className="overflow-hidden rounded-xl bg-neutral-900/50 shadow md:bg-neutral-900">
			<header className="flex shrink-0 items-center justify-between bg-neutral-950 p-4 sm:p-6 md:bg-neutral-925/80">
				<h1 className="flex items-center gap-2 sm:gap-4">
					{
						<div
							onClick={(e) => (e.currentTarget.lastElementChild as HTMLInputElement).click()}
							className={classNames(
								'group relative flex size-12 shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-neutral-900 transition-colors hover:bg-primary-500 sm:size-16',
								{ 'pointer-events-none': uploadingImage === brand, 'hover:!bg-black': logo }
							)}
						>
							{uploadingImage === brand && <Spinner />}
							{uploadingImage !== brand && (
								<>
									{logo && (
										<img
											src={logo}
											alt="Logo del Grupo"
											className="size-full object-cover transition-opacity group-hover:opacity-40"
										/>
									)}
									<ImageUp
										className={classNames('size-7 transition-all group-hover:scale-110', {
											'absolute opacity-0 group-hover:opacity-100': logo
										})}
									/>
								</>
							)}

							<input
								type="file"
								accept="image/*"
								className="hidden"
								onChange={async (e) => {
									if (uploadingImage) return;
									setUploadingImage(brand);

									const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/tiff', 'image/webp'];

									try {
										const image = e.currentTarget.files?.[0];
										if (!image) throw new Error();

										const is_image = image.size && IMAGE_TYPES.includes(image.type);
										const extension = image.name.split('.')?.pop()?.toLowerCase();
										const size = image.size;

										if (!is_image || !extension) {
											setUploadingImageError('Debe subir una imagen válida.');
											throw new Error();
										} else if (size > 3000000) {
											setUploadingImageError('El tamaño de la imagen seleccionada no puede superar los 3MB.');
											throw new Error();
										}

										const storage = Firebase.storage();
										const storageRef = storage.ref();
										const fileName = `${brand}_${Date.now()}.${extension}`;
										const fileRef = storageRef.child(fileName);
										await fileRef.put(image);

										const downloadURL = await fileRef.getDownloadURL();
										if (downloadURL) {
											setSelectedBrand({
												brand,
												instagram,
												whatsapp,
												logo: downloadURL
											});
										} else {
											throw new Error();
										}
									} catch (error) {
										setUploadingImage(false);
									}
								}}
							/>
						</div>
					}
					<div className="flex flex-col gap-0 sm:gap-1">
						<div className="flex items-center gap-2 text-xl font-bold text-neutral-0 3xl:text-2xl">{brand}</div>
						<div className="flex items-center gap-1.5 text-sm tracking-tighter text-neutral-300 sm:gap-3">
							<p className="flex items-center gap-0.5 sm:gap-1">
								<Instagram className="!text-[1rem]" />
								<span className="truncate">{instagram}</span>
							</p>
							<p className="flex items-center gap-0.5 sm:gap-1">
								<WhatsApp className="!text-[1rem]" />
								<span className="truncate">{whatsapp}</span>
							</p>
						</div>
					</div>
				</h1>
				<div className="flex items-center gap-2">
					<Button
						variant="default"
						className="!size-10 bg-neutral-925 !p-0 md:bg-neutral-950"
						onClick={() =>
							setSelectedBrand({
								brand,
								instagram,
								whatsapp,
								logo
							})
						}
					>
						<Edit className="size-5" strokeWidth={2.25} />
					</Button>
					<Button
						variant="default"
						className="size-10 gap-1.5 bg-neutral-925 md:size-auto md:h-10 md:bg-neutral-950"
						onClick={() =>
							setSelectedBranch({
								store_email: '',
								city: '',
								hours_of_operation: '',
								location: '',
								store_name: '',
								brand
							})
						}
					>
						<Plus className="size-4 scale-[2.75] md:scale-100" strokeWidth={2.5} />
						<span className="hidden md:inline-flex">Sucursal</span>
					</Button>
				</div>
			</header>
			<ol className="flex flex-col gap-4 p-4">
				{sucursales.map((branch) => (
					<Sucursal key={branch.store_email} data={branch} />
				))}
				{!sucursales.length && <p className="text-center text-neutral-300">No hay sucursales aún...</p>}
			</ol>
		</section>
	);

	return (
		<>
			{loading && <ContainerSpinner size={3} />}
			{error && <ErrorRedirectDialog />}

			{selectedBrand && <UpsertBrandDialog isNew={selectedBrand.brand ? false : true} image={uploadingImage ? true : false} />}
			{selectedBranch && <UpsertSucursalDialog isNew={selectedBranch.store_name ? false : true} />}
			{deleteBranch && <DeleteSucursalDialog />}
			{reactivateBranch && <ReactivateSucursalDialog />}

			{data && !loading && !error && (
				<div className="mx-auto h-full !max-w-5xl space-y-6 p-4 md:h-auto md:p-6">
					<div className="flex items-center justify-between">
						<h1 className="flex items-center gap-2 text-2xl font-bold text-neutral-0 3xl:text-3xl">Sucursales</h1>
						<Button
							variant="default"
							className="gap-1.5"
							onClick={() =>
								setSelectedBrand({
									brand: '',
									whatsapp: '',
									instagram: '',
									logo: null
								})
							}
						>
							<Plus className="size-4" strokeWidth={2.5} />
							Añadir Grupo
						</Button>
					</div>
					{Object.keys(data).map((brand) => (
						<Brand
							key={brand}
							brand={brand}
							whatsapp={data[brand].info.whatsapp}
							instagram={data[brand].info.instagram}
							sucursales={data[brand].sucursales.map((e) => ({ ...e, brand }))}
							logo={data[brand].info.logo || null}
						/>
					))}
				</div>
			)}

			{uploadImageError && (
				<Dialog title="" onClose={() => setUploadingImageError(null)}>
					{uploadImageError}
				</Dialog>
			)}
		</>
	);
}

function GetCities(country: string | null) {
	const [cities, setCities] = useState<string[]>([]);

	useEffect(() => {
		if (!country) return;
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
			if (document?.[country]?.cities?.length) setCities(document?.[country]?.cities);
		})();
	}, [country]);

	return cities;
}

type Sucursal = {
	brand: string;
	store_email: string;
	store_name: string;
	city: string;
	location: string;
	hours_of_operation: string;
	is_active?: boolean;
};

type Brand = {
	brand: string;
	whatsapp: string;
	instagram: string;
	logo: string | null;
};
