import React, { useState, useEffect, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Spinner from './Spinner';
import { GlobalContext } from '../context/GlobalContext';
import firebase from 'firebase/compat/app';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    card: {
        flex: '1',
        borderRadius: 15,
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#1C1C1E',
    },
    title: {
        color: 'white',
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
    },
    number: {
        fontSize: '3vw',
        color: '#45C4D6',
    },
    icon: {
        position: 'absolute',
        top: '50%',
        right: theme.spacing(2),
        transform: 'translateY(-50%)',
        fontSize: 32,
        color: '#45C4D6', // Set the icon color
    },
}));

function CustomCard({ title, number, icon, isCurrency }) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} variant="body2">
                    {title}
                </Typography>
                <Typography className={classes.number} variant="h4">
                    {isCurrency ? `$${number}` : number}
                </Typography>
                {icon}
            </CardContent>
        </Card>
    );
}

function SummaryCardsPorCobrar(props) {
    const classes = useStyles();
    const [token, setToken] = useState();
    const [AmountInStreet, setAmountInStreet] = useState("");
    const [DevicesInStreet, setDevicesInStreet] = useState("");
    const [InvestedCapital, setInvestedCapital] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { userData } = useContext(GlobalContext);


    useEffect(() => {
        const requestToken = async () => {
            const loginData = {
                username: process.env.REACT_APP_TOKEN_USERNAME,
                password: process.env.REACT_APP_TOKEN_PASSWORD
            }
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(loginData)
                });

                const responseData = await response.json();
                return responseData
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const sendPostRequest = async (data, endpoint, token2) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                        'x-master-store-email': props.storeEmail,
                        'x-user-email': null
                    },
                    body: JSON.stringify({ ...data, country: userData.country })
                });
                const responseData = await response.json();
                if (response.status === '401') {
                    const tokenData = await requestToken()
                    await setToken('Token ' + tokenData.token)

                    const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                    return resData
                }
                return responseData;
            } catch (error) {
                console.error('Error:', error.message);
                return null
            }
        };

        const get_todays_summary_data = async () => {
            try {
                const data = {
                    store_email: props.storeEmail,
                    sub_store: "all",
                }
                const response = await sendPostRequest(data, 'summary_amounts_due_cards/', token) //status, success, data
                if (response?.success) {
                    console.log(response)
                    setAmountInStreet(response.data["amount_in_street"])
                    setDevicesInStreet(response.data["devices_in_street"])
                    setInvestedCapital(response.data["invested_capital"])
                } else {
                    console.error('Estamos teniendo problemas para conectarnos.');
                }
                setIsLoading(false)
            } catch (error) {
                console.error('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false)
            }
        };
        // Call the function when the component loads
        get_todays_summary_data();
    }, []);

    return (
        <>
            {isLoading ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                    <Spinner size={2.5} />
                </div> : <div className={classes.cardContainer}>
                    <CustomCard title="Monto por cobrar" number={AmountInStreet} icon={<MonetizationOnOutlinedIcon className={classes.icon} />} isCurrency />
                    <CustomCard title="Monto financiado" number={InvestedCapital} icon={<AccountBalanceIcon className={classes.icon} />} isCurrency />
                    <CustomCard title="Equipos en la calle" number={DevicesInStreet} icon={<PhoneAndroidOutlinedIcon className={classes.icon} />} />
                </div>}
        </>
    );
}

export default SummaryCardsPorCobrar;