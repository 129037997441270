// src/context/GlobalContext.js
import React, { createContext, ReactNode } from 'react';
import useAuth, { UserData } from '../hooks/auth';

const GlobalContext = createContext<{ authenticated: boolean; userData: UserData | null; shouldLogin: boolean }>({
	authenticated: false,
	userData: null,
	shouldLogin: false
});

function GlobalProvider({ children }: { children: ReactNode }) {
	const { authenticated, userData, shouldLogin } = useAuth();

	return <GlobalContext.Provider value={{ authenticated, userData, shouldLogin }}>{children}</GlobalContext.Provider>;
}

export { GlobalContext, GlobalProvider };
