
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { makeStyles } from '@material-ui/core/styles';

import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { GlobalContext } from '../context/GlobalContext';
import firebase from 'firebase/compat/app';

import Chart from 'chart.js/auto';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Spinner from './Spinner';
const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    card: {
        flexGrow: '1',
        borderRadius: 15,
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#1C1C1E',
    },
    title: {
        color: 'white',
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        fontWeight: '600',
    },
    number: {
        fontSize: '3vw',
        color: '#45C4D6',
    },
    icon: {
        position: 'absolute',
        top: '50%',
        right: theme.spacing(2),
        transform: 'translateY(-50%)',
        fontSize: 32,
        color: '#45C4D6', // Set the icon color
    },
    title2: {
        color: 'white', // Title text color
        marginLeft: 10,
        marginBottom: 10,
        textAlign: 'left',
    },
    row_data: {
        color: 'white', // Title text color
        textAlign: 'left',
    },
}));


const InteractiveBarChart = (props) => {
    const classes = useStyles();
    const [token, setToken] = useState();
    const [ChartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Dollars',
                data: [], // Example data, replace with your actual data
                backgroundColor: '#00C5DE',
                borderColor: '#00C5DE',
                borderWidth: 1,
            },
        ],
    });
    const [isLoading, setIsLoading] = useState(true);
    const { userData } = useContext(GlobalContext);

    useEffect(() => {
        const requestToken = async () => {
            const loginData = {
                username: process.env.REACT_APP_TOKEN_USERNAME,
                password: process.env.REACT_APP_TOKEN_PASSWORD
            }
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(loginData)
                });

                const responseData = await response.json();
                return responseData
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const sendPostRequest = async (data, endpoint, token2) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                        'x-master-store-email': props.storeEmail,
                        'x-user-email': null
                    },
                    body: JSON.stringify({ ...data, country: userData.country })
                });
                const responseData = await response.json();
                if (response.status === '401') {
                    const tokenData = await requestToken()
                    await setToken('Token ' + tokenData.token)

                    const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                    return resData
                }
                return responseData;
            } catch (error) {
                console.error('Error:', error.message);
                return null
            }
        };

        const summary_amounts_due_data = async () => {
            try {
                const data = {
                    store_email: props.storeEmail,
                    sub_store: "all",
                }
                const response = await sendPostRequest(data, 'summary_amounts_due/', token) //status, success, data
                if (response?.success) {
                    setChartData({
                        labels: response.data["weekly_chart_dates"],
                        datasets: [
                            {
                                label: 'Dollars',
                                data: response.data["weekly_chart_pending_payments"], // Example data, replace with your actual data
                                backgroundColor: '#00C5DE',
                                borderColor: '#00C5DE',
                                borderWidth: 1,
                            },
                        ],
                    })
                    setIsLoading(false)

                } else {
                    console.error('Estamos teniendo problemas para conectarnos.');
                    setIsLoading(false)
                }
            } catch (error) {
                console.error('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false)
            }
        };
        // Call the function when the component loads
        summary_amounts_due_data();

    }, []);

    // You can use useEffect to fetch data asynchronously and update the chartData accordingly

    return (
        <Card className={classes.card} style={{ maxWidth: '550px' }}>
            <h2 className={classes.title2} style={{ padding: '20px', marginTop: '10px' }}>Cuotas por Cobrar ($)</h2>
            {isLoading ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                    <Spinner size={2.5} />
                </div>
                : <div style={{ minHeight: '300px', marginLeft: '20px' }}>
                    <Bar
                        data={ChartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    ticks: {
                                        color: 'white', // Set x-axis labels color
                                    },
                                },
                                y: {
                                    beginAtZero: true,
                                    ticks: {
                                        color: 'white', // Set y-axis labels color
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false, // Hide the legend
                                },
                            },
                        }}
                    />
                </div>}
        </Card>
    );
};

const PendingSummary = (props) => {
    const classes = useStyles();
    const [token, setToken] = useState();
    const [CuotasRecibidas, setCuotasRecibidas] = useState(null)
    const [CuotasTardias, setCuotasTardias] = useState(null)
    const [Fee, setFee] = useState(null)
    const [CuotasRecuperadas, setCuotasRecuperadas] = useState(null)
    const [Total, setTotal] = useState(null)
    const [Semanas, setSemanas] = useState(null)
    const [DataTableData, setDataTableData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const { userData } = useContext(GlobalContext);

    useEffect(() => {
        const requestToken = async () => {
            const loginData = {
                username: process.env.REACT_APP_TOKEN_USERNAME,
                password: process.env.REACT_APP_TOKEN_PASSWORD
            }
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(loginData)
                });

                const responseData = await response.json();
                return responseData
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const sendPostRequest = async (data, endpoint, token2) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                        'x-master-store-email': props.storeEmail,
                        'x-user-email': null
                    },
                    body: JSON.stringify({ ...data, country: userData.country })
                });
                const responseData = await response.json();
                if (response.status === '401') {
                    const tokenData = await requestToken()
                    await setToken('Token ' + tokenData.token)

                    const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                    return resData
                }
                return responseData;
            } catch (error) {
                console.error('Error:', error.message);
                return null
            }
        };

        const summary_amounts_due_data = async () => {
            try {
                const data = {
                    store_email: props.storeEmail,
                    sub_store: "all",
                }
                const response = await sendPostRequest(data, 'summary_amounts_due/', token) //status, success, data
                if (response?.success) {
                    setDataTableData(response.data.data_table)
                    setSemanas(response.data.semana)
                    setFee(response.data.krece_fee)
                    setCuotasTardias(response.data.cuotas_tardias)
                    setCuotasRecuperadas(response.data.cuotas_recuperadas)
                    setTotal(response.data.total)
                    setCuotasRecibidas(response.data.cuotas_recibidas)
                    setIsLoading(false)
                } else {
                    console.error('Estamos teniendo problemas para conectarnos.');
                    setIsLoading(false)
                }
            } catch (error) {
                console.error('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false)
            }
        };
        // Call the function when the component loads
        summary_amounts_due_data();
    }, []);


    return (
        <Card className={classes.card}>
            <CardContent>
                {isLoading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                        <Spinner size={2.5} />
                    </div>
                    : <CardContent>
                        <div style={{ height: 500, maxWidth: 2000, overflow: 'auto' }}>
                            <h2 className={classes.title2} style={{ padding: '20px', marginTop: '70px' }}>Cuotas Cobradas($)</h2>

                            <div>
                                {DataTableData ? (
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className={classes.table}>
                                                <TableCell className={classes.title} style={{ minWidth: 100 }}>Semana</TableCell>
                                                <TableCell className={classes.title} style={{ minWidth: 100 }}>Recibidas ($)</TableCell>
                                                <TableCell className={classes.title} style={{ minWidth: 100 }}>Tardías ($)</TableCell>
                                                <TableCell className={classes.title} style={{ minWidth: 100 }}>Recuperadas ($)</TableCell>
                                                <TableCell className={classes.title} style={{ minWidth: 100 }}>Fee de Krece ($)</TableCell>
                                                <TableCell className={classes.title} style={{ minWidth: 100 }}>Total a pagar ($)</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DataTableData.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.row_data}>{row.semana}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.cuotas_recibidas}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.cuotas_tardias}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.cuotas_recuperadas}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.krece_fee}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.total}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    // Render loading indicator or placeholder
                                    <div>Loading...</div>
                                )}
                            </div>
                        </div>
                    </CardContent>}
            </CardContent>
        </Card>
    );
};

export { PendingSummary, InteractiveBarChart };