import React, { useState, useEffect, useRef, useContext } from 'react';
import { Typography, Container, Button, TextField, makeStyles, withStyles } from '@material-ui/core';
import { ArrowBack, CheckCircle } from '@material-ui/icons';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';
import { GlobalContext } from '../context/GlobalContext';
import { useNavigate } from "react-router-dom";
import { BeatLoader } from 'react-spinners';

// Initialize Firebase
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

const CustomTextField = withStyles({
    root: {
        '& input[type="file"]': {
            display: 'none', // Hide the default file input
        },
        '& label': {
            backgroundColor: '#45C4D6', // Background color for the button
            color: '#2A2E32', // Text color for the button
            padding: '10px 15px', // Adjust padding as needed
            borderRadius: 4,
            cursor: 'pointer',
            width: 150
        },
    },
})(TextField);

const ChangeEmail = () => {
    const classes = useStyles();
    const { authenticated, userData } = useContext(GlobalContext);
    const navigate = useNavigate();
    //User creation data
    const [formError, setFormError] = useState('');

    // device registration data
    const [loginError, setLoginError] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);

    //webapp data
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [infoMessage, setInfoMessage] = useState('');
    const [showTokenSent, setShowTokenSent] = useState(false);
    const [showChangeEmail, setShowChangeEmail] = useState(false);

    //changeEmail data
    const [phoneNumberForAuthCode, setPhoneNumberForAuthCode] = useState('');
    const [emailForAuthCode, setEmailForAuthCode] = useState('');
    const [cedulaForAuthCode, setCedulaForAuthCode] = useState('');
    const [authCodeForAuthCode, setAuthCodeForAuthCode] = useState('');
    const [emailToChange, setEmailToChange] = useState('');
    const [emailToChange2, setEmailToChange2] = useState('');

    const fileInputRef = useRef(null);

    var currentDay = new Date().getDate();
    if (currentDay === 31) {
        currentDay = 30;
    }

    useEffect(() => {
        setIsLoading(true);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                const storeRef = firestore.collection('Stores').doc(user.email);
                storeRef.get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.cobranza_access) {
                            navigate('/');
                            setIsLoading(false);
                            return;
                        }
                        const masterStoreRef = data.master_store;
                        const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
                        // const userName = data.store_name ? data.store_name : "Master"
                        // const storeRef = firestore.collection('Stores').doc(masterStore);
                        masterStoreRef.get()
                            .then((doc) => {
                                const data = doc.data();
                                setCountry(data.country)
                                const storeName = data.store_name;
                                setIsLoading(false);
                            });
                        setIsLoading(false);
                    });
            } else {
                // User is signed out
                // ...
                navigate("/login");
                console.log("user is logged out");
                setIsLoading(false);
            }
        });

    }, [])

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': userData.master_store.id,
                    'x-user-email': userData.email
                },
                body: JSON.stringify({ ...data, country: country })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)
                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const backToWelcomePage = () => {
        window.location.reload();
    };

    const retrievePhoneNumber = async () => {
        try {
            const usersCollection = firestore.collection('Users');

            const queryUser = usersCollection
                .where('identification_number', '==', cedulaForAuthCode)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                    setPhoneNumberForAuthCode(doc.data()['phone_number'])
                    setEmailForAuthCode(doc.id)
                    sendAuthCodeToUser(doc.data()['phone_number']);
                })
            }).catch((err) => {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            })
        } catch (err) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const sendAuthCodeToUser = async (phoneNumberForAuthCode) => {
        try {
            const codeData = {
                phone_number: phoneNumberForAuthCode,
                code_type: 'sms'
            }
            const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token) //status, success, data
            if (response?.success) {
                setShowTokenSent(true)
                setIsLoading(false);
            } else if (response?.success === false) {
                setFormError(response.status);
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const confirmAuthCodeFromUser = async () => {
        setFormError('');
        if (!authCodeForAuthCode) {
            setFormError('Llene todos los datos para continuar');
            return;
        }
        setIsLoading(true);
        try {
            const codeData = {
                confirmation_code: authCodeForAuthCode
            }
            const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token) //status, success, data
            if (response?.success) {
                setShowChangeEmail(true)
                setIsLoading(false);
            } else if (response?.success === false) {
                setFormError(response.status);
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const checkCedula = async () => {
        setFormError('');
        if (!cedulaForAuthCode) {
            setFormError('Llene todos los datos para continuar');
            return;
        }
        setIsLoading(true);
        try {
            const cedulaData = {
                identification_number: cedulaForAuthCode
            }
            const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token) //status, success, data 
            if (response?.success) {
                if (response.data.is_user_registered) {
                    await retrievePhoneNumber();
                } else {
                    await setLoginError('No encontramos a cliente asociado a esta cédula. Por favor contactar a soporte técnico.');
                    setIsLoading(false);
                }
            } else if (response?.success === false) {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const confirmNewEmail = async () => {
        setFormError('');
        setIsLoading(true);
        //do all the email change logic
        if (!emailToChange || !emailToChange2) {
            setFormError('Por favor rellene todos los datos.');
            setIsLoading(false);
            return;
        }
        if (emailToChange !== emailToChange2) {
            setFormError('Los correos introducidos no coinciden.');
            setIsLoading(false);
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailToChange)) {
            setFormError('Por favor ingrese un email válido.');
            setIsLoading(false);
            return;
        }
        //now we need to change the email and see if we can change the auth email so the user doesn't have to create a new
        try {
            changeUserEmail();
        } catch (err) {
            setFormError('Ocurrion un problema, intente más tarde.');
            setIsLoading(false);
            return;
        }
    }

    const changeUserEmail = async () => {
        setIsLoading(true)
        setFormError('');
        try {
            const usersCollection = firestore.collection('Users');
            const paymentsCollection = firestore.collection('Payments');
            const devicesCollection = firestore.collection('Devices');
            const remindersCollection = firestore.collection('Reminders');
            const transactionsCollection = firestore.collection('Transactions');

            const queryUser = usersCollection
                .where('identification_number', '==', cedulaForAuthCode.trim())
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                    const dataToClone = doc.data();

                    if (!dataToClone) {
                        setFormError("Documento no encontrado o sin data.");
                        setIsLoading(false)
                        return;
                    }

                    // Step 4: Write data to the new document in the destination collection
                    if (doc.id === emailToChange) {
                        setFormError("El correo introducido es igual al anterior, por favor introduzca un correo distinto.");
                        setIsLoading(false)
                        return;
                    }
                    const destinationDocumentRef = firestore.collection('Users').doc(emailToChange.toLowerCase().trim()).get()
                        .then(async (doc2) => {
                            if (doc2.exists) {
                                // Document exists
                                setFormError("Ya existe un usuario con este email.");
                                setIsLoading(false)
                                return;
                            } else {
                                await doc2.ref.set(dataToClone);
                                if (dataToClone['registration_status'] === "device_enrolled") {
                                    //change "user" field in Devices for this user
                                    const deviceQuery = devicesCollection
                                        .where('user', '==', usersCollection.doc(doc.id))

                                    await deviceQuery.get().then((querySnapshot) => {
                                        querySnapshot.forEach((doc) => {
                                            doc.ref.update({
                                                user: usersCollection.doc(emailToChange.toLowerCase().trim())
                                            })
                                        })
                                    })
                                    //change payment "user" field to the new user
                                    const paymentQuery = paymentsCollection
                                        .where('user', '==', usersCollection.doc(doc.id))
                                    await paymentQuery.get().then((querySnapshot) => {
                                        querySnapshot.forEach((doc) => {
                                            doc.ref.update({
                                                user: usersCollection.doc(emailToChange.toLowerCase().trim())
                                            })
                                        })
                                    })
                                    //change the Reminders "user" field to the new user
                                    const reminderQuery = remindersCollection
                                        .where('user', '==', usersCollection.doc(doc.id))
                                    await reminderQuery.get().then((querySnapshot) => {
                                        querySnapshot.forEach((doc) => {
                                            doc.ref.update({
                                                user: usersCollection.doc(emailToChange.toLowerCase().trim())
                                            })
                                        })
                                    })
                                    //change the Transactions "user" field to the new user
                                    const transactionQuery = transactionsCollection
                                        .where('user', '==', usersCollection.doc(doc.id))
                                    await transactionQuery.get().then((querySnapshot) => {
                                        querySnapshot.forEach((doc) => {
                                            doc.ref.update({
                                                user: usersCollection.doc(emailToChange.toLowerCase().trim())
                                            })
                                        })
                                    })
                                    doc.ref.delete();
                                    setInfoMessage('El email se cambió exitosamente.')
                                    setIsLoading(false)
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 5000);
                                } else {
                                    doc.ref.delete();
                                    setInfoMessage('El email se cambió exitosamente.')
                                    setIsLoading(false)
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                }
                            }
                        })
                        .catch((error) => {
                            setFormError('Ocurrió un problema, intente de nuevo más tarde.')
                            setIsLoading(false);
                        });;
                })
            }).catch((error) => {
                setFormError('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            });
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    if (infoMessage) {
        return (
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
                marginTop: 100
            }}>
                <p style={{ textAlign: 'center', color: "white", fontSize: 16 }}>{infoMessage}<CheckCircle style={{ color: "#45C4D6" }} /></p>
            </Container>
        )
    }

    //Logic to hide pago en tienda 
    return (
        <div>
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
                marginTop: 100
            }}>
                <>
                    <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                        Cambiar Correo
                    </Typography>
                    {!showTokenSent && !showChangeEmail && <>
                        <p className={classes.approvalMsg}>Por favor ingresa la cédula del cliente que desea cambiar su correo</p>
                        <TextField
                            label="Cédula del cliente"
                            name="cedula"
                            value={cedulaForAuthCode}
                            onChange={(e) => setCedulaForAuthCode(e.target.value.toLowerCase().trim())}
                            id="outlined-number"
                            margin="normal"
                            className={classes.textFields}
                            fullWidth
                            error={loginError !== ''}
                            helperText={loginError}
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />
                        <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={checkCedula} fullWidth>
                            Siguiente
                        </Button></>}
                    {showTokenSent && !showChangeEmail && <>
                        <> <p className={classes.approvalMsg}>{"El email actual del cliente es " + emailForAuthCode}</p>
                            <p className={classes.approvalMsg}>{"Le enviamos un código de verificación por SMS al cliente al *******" + phoneNumberForAuthCode.slice(-4)}</p> </>
                        <TextField
                            label="Código de verificación"
                            name="authCode"
                            value={authCodeForAuthCode}
                            onChange={(e) => setAuthCodeForAuthCode(e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        ></TextField>
                        <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={confirmAuthCodeFromUser} fullWidth>
                            Siguiente
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            className={classes.welcomeButtons}
                        >
                            Salir
                        </Button>
                    </>}
                    {showChangeEmail && <>
                        <TextField
                            label="Nuevo Email"
                            name="emailToChange"
                            value={emailToChange}
                            onChange={(e) => setEmailToChange(e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        ></TextField>
                        <TextField
                            label="Confirmar Nuevo Email"
                            name="emailToChange2"
                            value={emailToChange2}
                            onChange={(e) => setEmailToChange2(e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        ></TextField>
                        <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={confirmNewEmail} fullWidth>
                            Enviar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            className={classes.welcomeButtons}
                        >
                            Salir
                        </Button>
                    </>}
                    {formError && <p className={classes.formError}>{formError}</p>}
                    {isLoading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
                        <BeatLoader color="#45C4D6" />
                    </div>}
                </>
            </Container>
        </div >
    );
};

const useStyles = makeStyles((theme) => ({
    approvalMsg: {
        textAlign: 'center', color: "silver", fontSize: 16
    },

    switch_track: {
        backgroundColor: "gray",
    },
    switch_base: {
        color: "gray",
        "&.Mui-disabled": {
            color: "gray"
        },
        "&.Mui-checked": {
            color: "gray"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "gray",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#65C466",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#65C466",
        },
    },
    totalAmt: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtons2: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 15,
        marginLeft: 15,
        height: 45,
        width: 200
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    textFields2: {
        color: 'white',
    },
    textLabel: {
        color: 'white'
    },
    textInput: {
        backgroundColor: '#2A2E32',
        color: 'white',
        border: "1px solid #ccc",
        borderRadius: '8px',
        padding: 8,
        fontSize: 16,
        width: 100
    },
    paymentItem: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 10,
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        height: 700,
    },
    topButton: {
        flex: 1
    },
    formError: {
        color: "#e84e4f"
    },
    title: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    title2: {
        color: 'white',
        fontSize: 16,
        marginBottom: 10
    },
    highlighted: {
        color: '#45C4D6',
    },
    appBarButton: {
        width: 200
    },
    border: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        padding: "10px",
        marginBottom: 20,
    },
    border2: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingBottom: 5,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#1C1C1E',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
}));

export default ChangeEmail;
