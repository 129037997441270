import * as RSelect from '@radix-ui/react-select';
import classNames from 'classnames';
import { CheckIcon, ChevronDown, ChevronUp } from 'lucide-react';
import { ReactElement, useState } from 'react';

export default function Select({
	options,
	defaultValue,
	onSelectedChange,
	title,
	disabled,
	className = '',
	Icon,
	placeholder
}: {
	options: { label: string; value: string | boolean }[];
	defaultValue?: string | boolean;
	onSelectedChange: (selected: string | boolean | null) => any;
	title?: string;
	disabled?: boolean;
	className?: string;
	Icon?: ReactElement;
	placeholder?: string;
}) {
	const [open, setOpen] = useState(false);

	const [selectedOption, setSelectedOption] = useState(defaultValue);

	return (
		<RSelect.Root
			open={open}
			onOpenChange={setOpen}
			value={selectedOption?.toString()}
			onValueChange={(selected) => {
				const selectedOption = selected === 'true' ? true : selected === 'false' ? false : selected;
				setSelectedOption(selectedOption);
				onSelectedChange(selected === defaultValue?.toString() ? null : selectedOption);
			}}
		>
			<div className="space-y-1">
				{title && <h3 className="font-medium tracking-tight text-neutral-300">{title}</h3>}
				<RSelect.Trigger
					disabled={disabled}
					onPointerUp={() => setOpen((open) => !open)}
					onPointerDown={(e) => e.preventDefault()}
					className={classNames(
						'inline-flex h-10 shrink-0 select-none items-center justify-between gap-2 text-nowrap rounded-lg bg-background px-3 text-sm font-medium outline-none transition-colors hover:bg-primary-600 disabled:pointer-events-none disabled:opacity-60',
						className,
						{ 'font-normal text-neutral-300': selectedOption === undefined }
					)}
				>
					<div className="flex items-center gap-1">
						{Icon && <div className="shrink-0">{Icon}</div>}
						<RSelect.Value placeholder={placeholder} />
					</div>
					<div className="flex items-center justify-end gap-0.5">
						<RSelect.Icon className="shrink-0">
							<ChevronDown className="size-4 shrink-0 !text-neutral-100" strokeWidth={2.5} />
						</RSelect.Icon>
					</div>
				</RSelect.Trigger>
			</div>
			<RSelect.Portal>
				<RSelect.Content className="z-50 overflow-hidden rounded-lg bg-primary-600 text-sm font-medium">
					<RSelect.ScrollUpButton className="flex h-6 cursor-pointer items-center justify-center bg-primary-500 text-primary-50">
						<ChevronUp className="size-4" strokeWidth={2.5} />
					</RSelect.ScrollUpButton>
					<RSelect.Viewport className="p-[5px]">
						{options.map((option, idx) => (
							<RSelect.Item
								key={idx}
								value={option.value.toString()}
								className="relative flex h-8 cursor-pointer select-none items-center rounded pl-[25px] pr-[35px] text-primary-200 data-[highlighted]:bg-primary-500 data-[highlighted]:text-primary-50 data-[state='checked']:text-primary-50 data-[highlighted]:outline-none"
							>
								<RSelect.ItemText>{option.label}</RSelect.ItemText>
								<RSelect.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
									<CheckIcon className="size-4" strokeWidth={2.5} />
								</RSelect.ItemIndicator>
							</RSelect.Item>
						))}
					</RSelect.Viewport>
					<RSelect.ScrollDownButton className="flex h-6 cursor-pointer items-center justify-center bg-primary-500 text-primary-50">
						<ChevronDown className="size-4" strokeWidth={2.5} />
					</RSelect.ScrollDownButton>
				</RSelect.Content>
			</RSelect.Portal>
		</RSelect.Root>
	);
}
