import React, { useState, useEffect, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { GlobalContext } from '../context/GlobalContext';
import firebase from 'firebase/compat/app';

import { DateRange } from '@material-ui/icons';
import Spinner from './Spinner';

const useStyles = makeStyles((theme) => ({
    card: {
        flex: '1',
        borderRadius: 15,
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#1C1C1E',
    },
    leftCard: {
        flex: '0 0 66.6%',
    },
    rightCard: {
        flex: '0 0 33.3%',
    },
    table: {
        minWidth: 250,
        maxHeight: '100%', // Set a maximum height for the table container
        maxWidth: '100%', // Set a maximum width for the table container
        backgroundColor: '#1C1C1E',
    },
    title: {
        color: 'white', // Title text color
        fontWeight: '600',

    },
    title2: {
        color: 'white', // Title text color
        marginLeft: 10,
        marginBottom: 10,
        textAlign: 'left',
    },
    row_data: {
        color: 'white', // Title text color
    },
    icon: {
        fontSize: 32,
        color: '#45C4D6', // Set the icon color
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 30,
        marginBottom: 20
    },
}));



function PendientePorCobrarTable(props) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
    const [devicesTableData, setDevicesTableData] = useState(null)
    const [MaxCount, setMaxCount] = useState(330)
    const [isLoading, setIsLoading] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const { userData } = useContext(GlobalContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDate = (date) => {

    }

    const downloadCuotas = async () => {
        console.log(fromDate)
        console.log(toDate)
        if (!fromDate || !toDate) {
            alert('Seleccione un rango de fecha para la descarga')
            return;
        }
        setIsLoading(true);
        try {
            const data = {
                store_email: props.storeEmail,
                start_date: fromDate,
                end_date: toDate
            }
            console.log(data)
            const response = await sendPostRequest(data, 'download_store_payments/', token) //status, success, data
            console.log(response)
            if (response?.success) {
                window.open(response.download_link, '_blank');
            } else {
                console.error('Estamos teniendo problemas para conectarnos.');
            }
            setIsLoading(false)
        } catch (err) {
            alert('Ocurrió un error intente más tarde.');
            setIsLoading(false)
        }
    }

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': props.storeEmail,
                    'x-user-email': null
                },
                body: JSON.stringify({ ...data, country: userData.country })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)

                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    useEffect(() => {


        const get_store_devices = async () => {
            try {
                let lastObjectID = null;
                const paymentsData = [];
                let counter = 0;

                const data = {
                    store_email: props.storeEmail,
                    last_object_id: lastObjectID,
                }
                const response = await sendPostRequest(data, 'get_store_payments/', token) //status, success, data

                if (response?.success) {
                    const temp = JSON.parse(response.data);
                    paymentsData.push(...temp); // Append data to the result
                    setDevicesTableData(paymentsData);
                    setMaxCount(response.count);
                    counter++
                    // Update lastObjectID for the next iteration
                    lastObjectID = response.last_object_id;
                    setIsLoading(false)
                } else {
                    console.error('Estamos teniendo problemas para conectarnos.');
                    setIsLoading(false)
                }

                while (lastObjectID !== null) {

                    const data = {
                        store_email: props.storeEmail,
                        last_object_id: lastObjectID,
                    }

                    const response = await sendPostRequest(data, 'get_store_payments/', token) //status, success, data

                    if (response?.success) {
                        const temp = JSON.parse(response.data);
                        paymentsData.push(...temp); // Append data to the result
                        setDevicesTableData(paymentsData);
                        counter++
                        if (counter >= 10) {
                            break
                        }
                        // Update lastObjectID for the next iteration
                        lastObjectID = response.last_object_id;
                    } else {
                        console.error('Estamos teniendo problemas para conectarnos.');
                        break; // Exit the loop on an error
                    }

                }
            } catch (error) {
                console.error('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false)
            }

        };
        // Call the function when the component loads
        get_store_devices();
    }, []);

    return (

        <Card className={classes.card}>
            {isLoading ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                    <Spinner size={2.5} />
                </div> :
                <CardContent>
                    <h2 className={classes.title2}>Cuotas recientes</h2>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', margin: '20px 0', marginLeft: '20px' }}>
                        <TextField

                            label="Desde"
                            name="desde"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            margin="normal"
                            InputProps={{
                                startIcon: <DateRange sx={{ color: 'white' }} />,
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white',
                                    borderTop: '1px solid white',
                                    borderLeft: '1px solid white',
                                    borderRight: '1px solid white',
                                    borderRadius: '5px',
                                    marginRight: '10px',
                                    borderWidth: '1px',

                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                                shrink: true
                            }}
                        />
                        <TextField
                            label="Hasta"
                            name="hasta"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            margin="normal"
                            InputProps={{
                                startIcon: <DateRange sx={{ color: 'white' }} />,
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white',
                                    borderTop: '1px solid white',
                                    borderLeft: '1px solid white',
                                    borderRight: '1px solid white',
                                    borderRadius: '5px',
                                    marginRight: '5px',
                                    borderWidth: '1px',

                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                                shrink: true
                            }}
                        />
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            onClick={downloadCuotas}
                            style={{ marginTop: '20px', marginLeft: 20 }}
                        >
                            Descargar Cuotas
                        </Button>
                    </div>

                    <div style={{ height: 450, maxWidth: 3000, overflow: 'auto' }}>
                        <div>
                            {devicesTableData ? (
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow className={classes.table}>
                                            <TableCell className={classes.title} style={{ minWidth: 140 }}>Dia</TableCell>
                                            <TableCell className={classes.title} style={{ minWidth: 140 }}>Monto de la cuota</TableCell>
                                            <TableCell className={classes.title} style={{ minWidth: 140 }}>Monto Pagado</TableCell>
                                            <TableCell className={classes.title} style={{ minWidth: 140 }}>Comisión de Krece</TableCell>
                                            <TableCell className={classes.title} style={{ minWidth: 140 }}>Identificación</TableCell>
                                            <TableCell className={classes.title} style={{ minWidth: 140 }}>Usuario</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {devicesTableData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.row_data}>{row.dueDate}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.actualAmount}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.amountPaid}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.krece_fee}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.identification_number}</TableCell>
                                                    <TableCell className={classes.row_data}>{row.user}</TableCell>

                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                // Render loading indicator or placeholder
                                <div>Loading...</div>
                            )}
                        </div>
                    </div>

                    {devicesTableData ? (
                        <TablePagination
                            component="div"
                            count={MaxCount}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ color: '#45C4D6' }}
                        />
                    ) : (<div>Cargando...</div>)}
                </CardContent>}
        </Card>
    );
}

export default PendientePorCobrarTable;