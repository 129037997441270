import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Button, TextField, makeStyles, MenuItem, CircularProgress, withStyles, Switch, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward, Delete, CheckCircle, Search, VerifiedUserOutlined, AddAPhoto } from '@material-ui/icons';
import { BeatLoader, HashLoader } from 'react-spinners';
import qrCode from '../images/enrollment_qr.png';
import downloadQr from '../images/QR_app.png';
import paso1 from '../images/paso1.png';
import paso1dot2 from '../images/paso1.2.png';
import paso1dot1 from '../images/paso1.1.png';
import paso2 from '../images/paso2.png';
import paso3 from '../images/paso3.png';
import paso4 from '../images/paso4.png';
import paso5 from '../images/paso5.png';
import paso6 from '../images/paso6.png';
import paso7 from '../images/paso7.png';
import paso8 from '../images/paso8.png';
import paso13 from '../images/paso13.png';
import paso16 from '../images/paso16.png';
import idIcon from '../images/photoId2.png';
import faceId from '../images/faceId2.png';
import homeScreen from '../images/kreceScreen.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';
// import Terms from '../components/Terms';

// Initialize Firebase
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

// el flow de cuando la persona va a financiar un segundo celular: 
//      no dejar que financie un segundo celular cuando ya tiene financiado uno.
//      Lo primero que se va a hacer es agregar la cedula y mandarla a registration/usercheck el cual va a devolver satus, success, data { is_user_registered: tru or false, can_be_financed, true or false }.
//          if is_user_registered === false --> entonce ssigue as usual
//          if is_user_registered === true && can_be_financed === false --> entonces se le da un mensaje que ya tiene un celular financiado
//          if is_user_registered === true && can_be_financed === true --> entonces se le rellenan los datos para que confirme y después se sigue as usual
//Tambien cuando hay un telefono con garantia:
//          Hay un boton especial que va a decir "celular aplicar garantia"
//          Lo primero que se va a hacer es agregar la cedula y mandarla a registration/usercheck y va a devolver satus, success, data { is_user_registered: tru or false, can_be_financed, true or false }.
//          if is_user_registered === true && can_be_financed === false --> entonces lo manda a un screen para registrar el device nuevo y solo se le va a pedir el IMEI y llama a replace_old_device. 
//              replace_old_device va a devolver lo mismo que devuelve el device_registration endpoint
//          if is_user_registered === true && can_be_financed === true --> entonces se le rellenan los datos para que confirme y después se sigue as usual

const CustomTextField = withStyles({
  root: {
    '& input[type="file"]': {
      display: 'none', // Hide the default file input
    },
    '& label': {
      backgroundColor: '#45C4D6', // Background color for the button
      color: '#1C1C1E', // Text color for the button
      padding: '10px 15px', // Adjust padding as needed
      borderRadius: 4,
      cursor: 'pointer',
      width: 150
    },
  },
})(TextField);

const AdminPortal = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [storeEmail, setStoreEmail] = useState('');
  const [storeName, setStoreName] = useState('');
  const [securityProvider, setSecurityProvider] = useState('');
  const [applicationApprovalData, setApplicationApprovalData] = useState({
    threeMonthAmount: '',
    sixMonthAmount: '',
  });
  const [maxLoan3MonthsKrecePlus, setMaxLoan3MonthsKrecePlus] = useState('');
  const [cneData, setCneData] = useState({});
  const [downPaymentPerc, setDownPaymentPerc] = useState(null);
  //User creation data
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [cedula, setCedula] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [gender, setGender] = useState('');
  const [formError, setFormError] = useState('');
  const [cedulaPhoto, setCedulaPhoto] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [cedulaVerified, setCedulaVerified] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isContinueApplication, setIsContinueApplication] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [storeUser, setStoreUser] = useState('');
  const [userName, setUserName] = useState('');
  const [isMaster, setIsMaster] = useState(false);
  const [needsLocking, setNeedsLocking] = useState(false);
  const [allowAnyProduct, setAllowAnyProduct] = useState(false);
  const [allowIphone, setAllowIphone] = useState(false);
  const [allowLaptop, setAllowLaptop] = useState(false);
  const [selfFinanced, setSelfFinanced] = useState(false);
  const [city, setCity] = useState('');
  const [anexoAccepted, setAnexoAccepted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [messType, setMessType] = useState('');

  //user application data
  const [monthlySalary, setMonthlySalary] = useState('');
  const [savings, setSavings] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [subStore, setSubStore] = useState('');
  const [subStoreList, setSubStoreList] = useState([]);
  const [subStorePhoneNumberMap, setSubStorePhoneNumberMap] = useState({});
  const [showTokenField, setShowTokenField] = useState(false);
  const [phoneToken, setPhoneToken] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);
  const [level, setLevel] = useState('level_1');
  const [cneIdentified, setCneIdentified] = useState(false);

  // device registration data
  const [loginError, setLoginError] = useState('');
  const [price, setPrice] = useState('');
  const [timeToPay, setTimeToPay] = useState(3);
  const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
  const [interestFactor, setInterestFactor] = useState(0.1);
  const [deviceID, setDeviceID] = useState('');
  const [imeiNumber, setImeiNumber] = useState('');
  const [confNumber, setConfNumber] = useState('');
  const [extraInitial, setExtraInitial] = useState(0);
  const [buyingChip, setBuyingChip] = useState(false);
  const [imeiToRegister, setImeiToRegister] = useState('');
  const [imeiToRegister2, setImeiToRegister2] = useState('');
  const [appToken, setAppToken] = useState('');
  const [tokenGenerated, setTokenGenerated] = useState(false);

  //webapp data
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState('')
  const [loadingText, setLoadingText] = useState(false);
  const [loadingSubText, setLoadingSubText] = useState(false);
  const [showImei, setShowImei] = useState(false);
  const [showConfNumber, setShowConfNumber] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [page, setPage] = useState(2);
  const [amountSlider, setAmountSlider] = useState(50);
  const [limitReached, setLimitReached] = useState(false);
  const [userExistsCanBeFinanced, setUserExistsCanBeFinanced] = useState(false);
  const [productElegibility, setProductElegibility] = useState(false);
  const [productType, setProductType] = useState('');
  const [productName, setProductName] = useState('');
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchChecked2, setSwitchChecked2] = useState(false);
  const [blockNuovo, setBlockNuovo] = useState(false);
  const [allowOnline, setAllowOnline] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [documentType, setDocumentType] = useState('')
  const [countrySettings, setCountrySettings] = useState('')
  const [activateAnexoListener, setActivateAnexoListener] = useState(false)
  const [isInfinix, setIsInfinix] = useState(false)

  //changeEmail data
  const [isAccountInactive, setIsAccountInactive] = useState('');

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  var currentDay = new Date().getDate();
  if (currentDay === 31) {
    currentDay = 30;
  }

  function listenToAnexoDocumentByIdentificationNumber() {
    const unsubscribe = firestore.collection("Anexos")
      .where("identification_number", "==", cedula)
      .where('terms_accepted', '==', true)
      .where('related_device', '==', null)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          console.log("Snapshot received, setting anexoAccepted to true");
          setAnexoAccepted(true);
        } else {
          console.log("No matching documents found.");
        }
      });

    // return unsubscribe; // make sure to return this if you need it for cleanup
  }

  useEffect(() => {
    setIsLoading(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const storeRef = firestore.collection('Stores').doc(user.email);
        setStoreUser(user.email)
        storeRef.get()
          .then((doc) => {
            const data = doc.data();
            if (data.cobranza_access) {
              navigate('/internal');
              setIsLoading(false);
              return;
            }
            const masterStoreRef = data.master_store;
            const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
            // const userName = data.store_name ? data.store_name : "Master"
            if (masterStoreRef.id === user.email) {
              setIsMaster(true);
            } else {
              setSubStoreList([data.store_name]);
            }
            setStoreEmail(masterStoreRef.id);
            // const storeRef = firestore.collection('Stores').doc(masterStore);
            masterStoreRef.get()
              .then((doc) => {
                const data = doc.data();
                setCountry(data.country);
                setSelfFinanced(data.self_financed === true ? true : false)
                const ref = firestore.collection('AppSetting').doc('CountrySettings');
                ref.get()
                  .then((doc) => {
                    const dat = doc.data();
                    setCountrySettings(dat[data.country]);
                    setInterestFactor(dat[data.country].payment_options.level_1[0].factor)
                    setTimeToPay(dat[data.country].payment_options.level_1[0].installments / 2)
                  })
                const storeName = data.store_name;
                if (data.allow_any_product === true) {
                  setAllowAnyProduct(true);
                }
                if (data.allow_iphone === true) {
                  setAllowIphone(true);
                }
                if (data.allow_laptop === true) {
                  setAllowLaptop(true);
                }
                setBlockNuovo(data.only_allow_tonic === true ? true : false);
                setAllowOnline(data.allow_online === true ? true : false);
                setIsAccountInactive(!data.is_active);
                setUserName(storeName + " - " + userName);
                setSecurityProvider(data.security_provider);
                setStoreName(storeName);
                if (masterStoreRef.id === user.email) {
                  setSubStoreList(data.sub_stores);
                  setSubStorePhoneNumberMap(data.subStoreAdminPhoneNumbers);
                } else {
                  if (data.subStoreAdminPhoneNumbers.hasOwnProperty('Administrador')) {
                    setSubStorePhoneNumberMap({ [userName]: data.subStoreAdminPhoneNumbers[userName], Administrador: data.subStoreAdminPhoneNumbers['Administrador'] });
                  } else {
                    setSubStorePhoneNumberMap({ [userName]: data.subStoreAdminPhoneNumbers[userName] });
                  }
                }
                setIsLoading(false);
                // setDownPaymentPerc(data['down_payment']);
              });
            setIsLoading(false);
          });
      } else {
        // User is signed out
        // ...
        navigate("/login");
        console.log("user is logged out");
        setIsLoading(false);
      }
    });

    // if (activateAnexoListener) {  // Only start listening if we have a valid ID
    //   // const unsubscribe = listenToAnexoDocumentByIdentificationNumber();
    //   firestore.collection("Anexos")
    //     .where("identification_number", "==", cedula)
    //     .where('terms_accepted', '==', true)
    //     .where('related_device', '==', null)
    //     .onSnapshot((snapshot) => {
    //       if (!snapshot.empty) {
    //         console.log("Snapshot received, setting anexoAccepted to true");
    //         setAnexoAccepted(true);
    //       } else {
    //         console.log("No matching documents found.");
    //       }
    //     });
    //   // Clean up the listener on component unmount or when identificationNumber changes
    //   // return () => unsubscribe();
    // }

  }, [])

  function getNextXDates(x) {
    const dates = [];
    let currentDate = new Date();

    // Calculate the first date as 15 days from the current date
    currentDate.setDate(currentDate.getDate() + 15);

    for (let i = 0; i < x; i++) {
      // Adjust weekends to the following Monday
      while (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      dates.push([currentDate.getDate(), getMonthName(currentDate.getMonth() + 1)]);

      // Move ahead by 15 days
      currentDate.setDate(currentDate.getDate() + 15);
    }
    return dates;
  }

  const getMonthName = (monthNumber) => {
    const date = new Date(2000, monthNumber - 1); // Set any year, and subtract 1 from the month number
    const monthName = date.toLocaleString('es-ES', { month: 'short' }); // Get the full month name

    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  }

  const sendPostRequest = async (data, endpoint, token2) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
          'x-master-store-email': storeEmail,
          'x-user-email': storeUser
        },
        body: JSON.stringify({ ...data, country: country })
      });
      const responseData = await response.json();
      if (response.status === '401') {
        const tokenData = await requestToken()
        await setToken('Token ' + tokenData.token)
        const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
        return resData
      }
      return responseData;
    } catch (error) {
      console.error('Error:', error.message);
      return null
    }
  };

  const requestToken = async () => {
    const loginData = {
      username: process.env.REACT_APP_TOKEN_USERNAME,
      password: process.env.REACT_APP_TOKEN_PASSWORD
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData)
      });

      const responseData = await response.json();
      return responseData
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCheckCedula = async () => {
    if (isAccountInactive) {
      alert('Esta cuenta esta inactiva para realizar ventas con Krece por los momentos, por favor contacte al equipo de Krece para entender el motivo.');
      return;
    }
    if (!cedula) {
      alert('Por favor introduzca la cédula');
      return;
    }
    const cedulaRegex = /^[0-9]+$/;
    if (!cedulaRegex.test(cedula)) {
      alert('Por favor ingrese un numero de cédula válido (sólo números).');
      return;
    }
    setIsLoading(true);
    setLoginError('')

    const storeRef = await firestore.collection('Stores').doc(storeEmail)
    storeRef.get()
      .then(async (doc) => {
        setLimitReached(doc.data()['limit_reached']);
        if (doc.data()['limit_reached']) {
          alert('Se ha llegado al límite diario. Este portal le permitirá completar la aplicación pero la venta debe ser completada en otro momento.')
        }
        const usersCollection = firestore.collection('Users');
        const queryUser = usersCollection
          .where('identification_number', '==', cedula)
          .limit(1);
        await queryUser.get().then((querySnapshot) => {
          if (querySnapshot.docs.length == 0) {
            setIsLoading(false);
            if (!allowAnyProduct && (country === "venezuela" || country === "")) {
              alert('Este usuario es elegible para adquirir celulares Android únicamente.');
            }
            setNeedsLocking(true);
            setPage(16);
            return;
          }
          querySnapshot.forEach(async (doc) => {
            if (doc.exists) {
              const data = doc.data()
              setEmail(doc.id)
              if (data.hasOwnProperty('max_loan_approved_3months')) {
                setApplicationApprovalData({
                  threeMonthAmount: doc.get('max_loan_approved_3months'),
                })
                //change after black friday
                // if (country === "venezuela") {
                //   setApplicationApprovalData({
                //     threeMonthAmount: 1125,
                //   })
                // }
                setLevel(data.level)
              }

              if (data.hasOwnProperty('max_loan_approved_3months_krece_plus')) {
                setMaxLoan3MonthsKrecePlus(data.max_loan_approved_3months_krece_plus)
              }

              // if (data.hasOwnProperty('percent_down_approved')) {
              //   setDownPaymentPerc(data.percent_down_approved)
              // }
              setDownPaymentPerc(countrySettings.down_payment[data.level])

              setRegistrationStatus(data.registration_status)
              if (data.registration_status === "registered" || data.registration_status === "application_reviewed" || data.registration_status === "device_registered") {
                if (!allowAnyProduct && (country === "venezuela" || country === "")) {
                  alert('Este usuario es elegible para adquirir CELULARES ANDROID ÚNICAMENTE.');
                }
                setNeedsLocking(true);
                setIsContinueApplication(true);
                setIsLoading(false)
                setPage(17)
              } else if (data.registration_status === "device_enrolled") {
                const cedulaData = {
                  identification_number: cedula,
                }
                const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token) //status, success, data
                if (response.success) {
                  setApplicationApprovalData({
                    threeMonthAmount: response.data.available_loan_approved,
                  });
                  //change after black friday
                  // if (country === "venezuela") {
                  //   setApplicationApprovalData({
                  //     threeMonthAmount: 1125,
                  //   })
                  // }
                  setMaxLoan3MonthsKrecePlus(response.max_loan_approved_3months_krece_plus)

                  if (response.data.can_be_financed) {
                    const response = await sendPostRequest(cedulaData, 'registration/productelegibility/', token)
                    if (response.success) {
                      if (response.elegible) {
                        if (!allowAnyProduct && (country === "venezuela" || country === "")) {
                          alert('Este usuario es elegible para CELULARES ANDROID y OTROS PRODUCTOS a cuotas!');
                        }
                      } else {
                        if (!allowAnyProduct && (country === "venezuela" || country === "")) {
                          alert('Este usuario es elegible para CELULARES ANDROID ÚNICAMENTE: ' + response.status);
                        }
                        setNeedsLocking(true);
                      }
                      setProductElegibility(response.elegible);
                      setIsContinueApplication(true);
                      setIsLoading(false);
                      setPage(17);
                    }
                  } else {
                    setLoginError(response.status)
                    setIsLoading(false);
                  }
                } else {
                  setLoginError('Ocurrio un problema: ' + response.status)
                  setIsLoading(false);
                }
              } else {
                setLoginError('Ocurrio un problema por favor contacte a servicio tecnico.')
                setIsLoading(false);
              }
            }
          })
        })
      });
  }

  const handleFaceSubmit = async (userPhoto) => {

    setFormError('')
    if (!userPhoto) {
      setFormError('Por favor suba una imagen.');
      setShowSpinner(false)
      return;
    }

    // Simulating API call delay
    const infoData = {
      identification_number: cedula,
      photo_id_url: userPhoto,
      existing_user: userExistsCanBeFinanced || isContinueApplication
    }
    const response = await sendPostRequest(infoData, 'authentication/face-identification/', token)
    if (storeEmail === 'test@store.com' || storeEmail === 'testrd@store.com') {
      response.success = true;
    }
    if (response?.success) {
      if (isContinueApplication) {
        if (registrationStatus === 'registered') {
          setCedulaVerified(true)
          setShowSpinner(false)
          setTimeout(() => {
            setPage(8)
            setCedulaVerified(false)
          }, 4000);
        } else if (registrationStatus === 'application_reviewed' || registrationStatus === 'device_enrolled') {
          setCedulaVerified(true)
          setShowSpinner(false)
          setTimeout(() => {
            setPage(4)
            setCedulaVerified(false)
          }, 4000);
        } else if (registrationStatus === 'device_registered') {
          setCedulaVerified(true)
          setShowSpinner(false)
          setTimeout(() => {
            setPage(4)
            setCedulaVerified(false)
          }, 4000);
        }
      } else {
        setCedulaVerified(true)
        setShowSpinner(false)
        if (response.gender) {
          setGender(response.gender)
        }
        setTimeout(() => {
          setPage(3)
          setCedulaVerified(false)
        }, 4000);
      }
      setShowSpinner(false)
    } else if (response?.success === false) {
      setFormError(response.status);
      setShowSpinner(false)
    } else {
      setFormError('Estamos teniendo problemas para conectarnos. Por favor chequee su conexión');
      setShowSpinner(false)
    }
  }

  function convertDateFormat(dateString) {
    // Split the input string into day, month, and year components
    const [day, month, year] = dateString.split('/');

    // Create a new Date object with the parsed components
    const parsedDate = new Date(`${year}-${month}-${day}`);

    // Extract the year, month, and day components in yyyy-MM-dd format
    const formattedDate = parsedDate.toISOString().split('T')[0];

    return formattedDate;
  }

  function getUserInput() {
    function getInputRecursive() {
      const userInput = window.prompt('No pudimos identificar la cédula por favor introduzca el número aquí:');
      if (userInput === null) {
        // User clicked cancel or closed the prompt
        return getInputRecursive(); // Ask again
      } else if (!/^[0-9]+$/.test(userInput)) {
        // Invalid input (doesn't match the regex)
        window.alert('Por favor introduzca un número de cédula válido.');
        return getInputRecursive(); // Ask again
      } else {
        // Valid input
        return userInput;
      }
    }

    return getInputRecursive();
  }

  const handleIdentificationSubmit = async (cedulaPhoto) => {

    setFormError('')
    if (!cedulaPhoto) {
      setFormError('Por favor suba una imagen.');
      setShowSpinner(false)
      return;
    }
    if (!documentType) {
      setFormError('Seleccione el tipo de documento.');
      setShowSpinner(false)
      return;
    }

    const infoData = {
      identification_id_url: cedulaPhoto,
      document_type: documentType.code,
      document_country: documentType.country
    }
    const response = await sendPostRequest(infoData, 'authentication/document-identification/', token)
    if (response?.success) {
      const userData = response.data
      if (response.data.identification_number === false || !response.identity_verified) {
        const userInput = getUserInput();
        response.data.identification_number = userInput;
        if (userInput !== cedula) {
          setFormError('La cedula que se subio no coincide con el número de cedula escrito en la pantalla anterior.')
          setShowSpinner(false);
          return;
        }
        setCedula(userInput);
        userData.identification_number = userInput;
      } else {
        if (cedula !== userData.identification_number.replaceAll('-', '')) {
          setFormError('La cedula que se subio no coincide con el número de cedula escrito en la pantalla anterior.')
          setShowSpinner(false);
          return;
        }
      }

      if (userData.cne_identified) {
        setCneIdentified(true)
        setCneData({
          centro: userData.centro,
          direccion: userData.direccion,
          estado: userData.estado,
          municipio: userData.municipio,
          parroquia: userData.parroquia
        })
      }
      setFirstName(userData.first_name ? userData.first_name : "") //setFirstName(userData.first_name)
      setLastName(userData.last_name ? userData.last_name : "") //setLastName(userData.last_name)
      setBirthDate(userData.date_of_birth ? convertDateFormat(userData.date_of_birth) : "") //setBirthDate(userData.date_of_birth) convert to javascript date
      const expDate = userData.expiration_date !== false ? (userData.expiration_date.split('/').length === 3 ? userData.expiration_date.split('/')[2] : userData.expiration_date.split('/')[1]) : false
      if (userData.expiration_date !== false && (parseInt(new Date().getFullYear()) - expDate.split('/')[1]) > 2) {
        setFormError('La cédula esta expirada más de dos años. No podemos seguir con este registro.');
        setShowSpinner(false);
        return;
      }
      setPage(17);
      setShowSpinner(false);
    } else if (response?.success === false) {
      setFormError(response.status);
      setShowSpinner(false);
    } else {
      setFormError('Estamos teniendo problemas para conectarnos. Por favor chequee su conexión');
      setShowSpinner(false);
    }
  }

  const handleInformationSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !email || !email2 || !cedula || !phoneNumber || !birthDate || !address || (!cedulaPhoto && storeEmail != "test@store.com") || (!userPhoto && storeEmail != "test@store.com") || !gender) {
      setFormError('Por favor complete todas las casillas.');
      return;
    }

    if (isUnder18(birthDate)) {
      setFormError('El cliente tiene menos de 18 años y no es elegible para Krece, si creen que hay un error ajuste la fecha de nacimiento.');
      return;
    }

    if (email !== email2) {
      setFormError('Los emails intoducidos no coinciden.');
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormError('Por favor ingrese un email válido.');
      return;
    }

    // Validate phone number format
    const phoneRegex = /^\d{10,11}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setFormError('Por favor ingrese un número de teléfono de 10 digitos.');
      return;
    }

    // Validate birth date format
    const birthDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!birthDateRegex.test(birthDate)) {
      setFormError('Por favor ingrese una fecha válida.');
      return;
    }

    //validate cedula format
    const cedulaRegex = /^[0-9]+$/;
    if (!cedulaRegex.test(cedula)) {
      setFormError('Por favor ingrese un numero de cédula válido (sólo números).');
      return;
    }

    if (!tokenVerified) {
      setFormError('Por favor realice la verificación de Token para poder continuar.');
      return;
    }

    // Form validation passed, submit the form or perform other actions
    setFormError('');
    // ...
    setIsLoading(true);
    // Simulating API call delay
    const infoData = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      date_of_birth: birthDate,
      sex: gender,
      address: address,
      identification_number: cedula,
      photo_id_url: userPhoto,
      identification_id_url: cedulaPhoto,
      phone_number: phoneNumber,
      seller_store: storeEmail,
      store_user: storeUser,
      cne_identified: cneIdentified,
      ...cneData
    }
    const response = await sendPostRequest(infoData, 'registration/userregistration/', token)
    if (response?.success) {
      if (!response.user_exists || userExistsCanBeFinanced) {
        setIsLoading(false);
        setPage(8)
      } else {
        setFormError("Ya existe un usuario con este email.");
        setIsLoading(false);
      }
    } else if (response?.success === false) {
      setFormError(response.status);
      setTokenVerified(false);
      setShowTokenField(true);
      setIsLoading(false);
    } else {
      setFormError('Estamos teniendo problemas para conectarnos. Por favor chequee su conexión');
      setIsLoading(false);
    }
  };

  function isUnder18(dateString) {
    // Convert the date string to a Date object
    const birthDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year
    const hasBirthdayOccurred =
      currentDate.getMonth() > birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() >= birthDate.getDate());

    // Determine if the person is under 18
    const isUnderage = age < 18 || (age === 18 && !hasBirthdayOccurred);

    return isUnderage;
  }

  const handleRegistrationSubmit = (e) => {
    e.preventDefault();
    setExtraInitial(0);
    if (!subStore || !price || !timeToPay || ((productElegibility || allowAnyProduct) && !productType) || (productType === 'other' && !productName)) {
      setFormError('Por favor complete todas las casillas.');
      return;
    }
    // if (timeToPay === 2) {
    //   setInterestFactor(0.15)
    //   if (level === 'level_3') {
    //     setInterestFactor(0.1375)
    //   } else if (level === 'level_4') {
    //     setInterestFactor(0.13)
    //   }
    // } else {
    //   setInterestFactor(0.1167)
    //   if (level === 'level_3') {
    //     setInterestFactor(0.1084)
    //   } else if (level === 'level_4') {
    //     setInterestFactor(0.1034)
    //   }
    // }

    const escapedMin = countrySettings.price_min.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const escapedMax = countrySettings.price_max.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create the regex string
    const priceRegex = new RegExp(`^(${escapedMin}|[1-9]\\d{0,${escapedMax.length - 1}}|${escapedMax})$`);

    // const priceRegex = /^(3[5-9]|[4-9]\d|[1-9]\d{2}|1[0-4]\d{2}|1500)$/;
    if (!priceRegex.test(price)) {
      setFormError('Por favor ingrese el precio correcto del dispositivo.');
      return;
    }

    // if (switchChecked2 && price < 120) {
    //   setFormError('El precio para la promoción debe ser mayor a 120.');
    //   return;
    // }

    if (!switchChecked && (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) < price) {
      setPrice(Math.round((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))
      setExtraInitial(parseInt(price) - Math.round((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))
    }

    if (allowAnyProduct && productType === 'other') {
      if (!switchChecked && maxLoan3MonthsKrecePlus / (1 - 0.5) < price) {
        setPrice(Math.round(maxLoan3MonthsKrecePlus / (1 - 0.5)))
        setExtraInitial(parseInt(price) - Math.round(maxLoan3MonthsKrecePlus / (1 - 0.5)))
      }
    }

    // Form validation passed, submit the form or perform other actions
    setFormError('');
    setPage(6);
  }

  const handleApplicationSubmit = async (e) => {
    e.preventDefault();
    if (!educationLevel || !monthlySalary || !savings || !city) {
      setFormError('Por favor complete todas las casillas.');
      return;
    }

    // Validate monthly salary format
    const salaryRegex = /^[0-9]+$/;
    if (!salaryRegex.test(monthlySalary)) {
      setFormError('Por favor ingrese un salario válido (sólo números).');
      return;
    }

    // Form validation passed, submit the form or perform other actions
    setFormError('');
    // ...
    setIsLoading(true);
    const applicationData = {
      user_document_id: email,
      monthly_income_dollars: monthlySalary,
      savings: savings,
      education_level: educationLevel,
      seller_store: storeEmail,
      store_user: storeUser,
      city: city
    }

    const response = await sendPostRequest(applicationData, 'registration/reviewuserapplication/', token) //status, success, data

    if (response?.success) {
      setApplicationApprovalData({
        threeMonthAmount: response.data.max_loan_approved_3months,
        sixMonthAmount: response.data.max_loan_approved_6months,
      })
      //change after black friday
      // if (country === "venezuela") {
      //   setApplicationApprovalData({
      //     threeMonthAmount: 1125,
      //   })
      // }

      setMaxLoan3MonthsKrecePlus(response.max_loan_approved_3months_krece_plus);
      setLimitReached(response.data.limit_reached);
      // setInterestFactor(response.data.interest_rate)
      // setInterestFactor(0.1167);
      setDownPaymentPerc(countrySettings.down_payment.level_1);
      setIsLoading(false);
      setPage(4);
    } else if (response?.success === false) {
      setFormError(response.status);
      setIsLoading(false);
    } else {
      setFormError('Estamos teniendo problemas para conectarnos. ');
      setIsLoading(false);
    }
  };

  const sendAppToken = async (e) => {
    setFormError('')
    setIsLoading(true);
    const data = {
      identification_number: cedula,
      code_type: 'in_app'
    }
    const resp2 = await sendPostRequest(data, 'notifications/requestauthcode/', token); //to-do: Necesito mandar la cedula? ese token como se va a guardar en la base de datos?
    if (resp2?.success) {
      alert('Token generado en app exitosamente.')
      setTokenGenerated(true);
      setIsLoading(false);
    } else if (resp2.success === false) {
      setFormError(resp2.status);
      setIsLoading(false);
    } else {
      setFormError("Ocurrió un problema generando el token de confirmación del app, por favor vuelva a intentar.");
      setIsLoading(false);
    }
  }

  const generateAnexo = async (e) => {
    setFormError('')
    setIsLoading(true);
    // const paymentAmount = Math.round((Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100)) / 100);
    let paymentAmount = Math.round((Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100)) / 100);
    if (switchChecked) {
      paymentAmount = Math.round((parseInt(price) * 0.5) * 1.25 / (timeToPay * 2))
    }
    let amountFinanced = (parseInt(price) + extraInitial) - (Math.round((parseInt(price) * downPaymentPerc) + extraInitial));

    if (switchChecked) {
      amountFinanced = Math.round(parseInt(price) * 0.5);
    }
    const data = {
      user_document_id: email,
      seller_store: storeEmail,
      store_user: storeUser,
      device_type: productType,
      sub_store: subStore,
      device_imei: imeiToRegister,
      loan_duration: timeToPay,
      payment_amount: paymentAmount,
      self_financed: selfFinanced,
      device_price: ((parseInt(price) + extraInitial)).toFixed(2),
      amount_financed: amountFinanced,
      loan_payment_cycle: 15
    }
    const resp2 = await sendPostRequest(data, 'registration/anexoregistration/', token); //to-do: Necesito mandar la cedula? ese token como se va a guardar en la base de datos?
    if (resp2?.success) {
      alert('Contrato generado en app exitosamente. El usuario debe abrir la app Krece para aceptar el contrato')
      setTokenGenerated(true);
      setIsLoading(false);
    } else if (resp2.success === false) {
      setFormError(resp2.status);
      setIsLoading(false);
    } else {
      setFormError("Ocurrió un problema generando el contrato, por favor vuelva a intentar.");
      setIsLoading(false);
    }
  }

  const handleSendDeviceID = async (e) => {
    try {
      setFormError('');
      setLoginError('');
      if (!deviceID) {
        setFormError('Por favor introduzca el código de confirmación.');
        return;
      }
      if (deviceID.length !== 7) {
        setFormError('El número de confirmación debe tener 7 digitos.');
        return;
      }
      setIsLoading(true);
      if (!productElegibility && allowAnyProduct && productType == 'other') {
        const devData = {
          device_imei: imeiToRegister,
          seller_store: storeEmail,
          sub_store: subStore,
          device_management_provider: "nuovo",
          user_document_id: email,
          store_user: storeUser,
          device_id: deviceID,
        }
        const response = await sendPostRequest(devData, 'anclar_device/', token)//status, success, data
        if (response?.success) {
          await registerOtherProduct()
          setIsLoading(false);
          setLoadingText('');
          setLoadingSubText('');
        } else if (response?.success === false) {
          setFormError(response.status);
          setIsLoading(false);
        } else {
          setFormError('Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.');
          setIsLoading(false);
        }
        return;
      }
      const paymentAmount = Math.round((Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100)) / 100);
      const deviceData = {
        user_document_id: email,
        device_id: deviceID,
        seller_store: storeEmail,
        device_price: ((parseInt(price) + extraInitial)).toFixed(2),
        loan_duration: timeToPay,
        payment_data: {
          loan_payment_cycle: 15,
          payment_amount: paymentAmount
        },
        amount_financed: (parseInt(price) + extraInitial) - (Math.round((parseInt(price) * downPaymentPerc) + extraInitial)),
        // amount_financed: Math.round(parseInt(price) * (1 - downPaymentPerc) * 100) / 100,
        sub_store: subStore,
        new_chip_acquired: buyingChip === 'Si' ? true : false,
        store_user: storeUser,
        device_type: 'phone',
        promotional_code: switchChecked ? "promo_samsung" : (switchChecked2 ? "promo_infinix" : ""),
        online_purchase: isOnline
      }
      const response = await sendPostRequest(deviceData, 'registration/deviceregistration/', token)
      if (response?.success) {
        setPage(15);
        setShowConfNumber(false);
        setShowImei(false);
        setIsLoading(false);
      } else if (response?.status.includes("Error getting device")) {
        setFormError("Número de confirmación incorrecto. Vuelva a intentar con otro número.");
        setIsLoading(false);
      } else if (response?.success === false) {
        setFormError(response.status);
        setIsLoading(false);
      } else {
        setFormError('Ocurrió un problema, intente más tarde.');
        setIsLoading(false);
      }
    } catch (error) {
      setFormError('Estamos teniendo problemas para conectarnos.');
      setIsLoading(false);
    }
  }

  const handleNext = () => {
    setFormError('');
    setPage(5);
  };

  const handlePhotoChange = async (event) => {

    try {
      setShowSpinner(true)
      const file = event.target.files[0];

      // Create a storage reference with a unique filename
      const storage = firebase.storage();
      const storageRef = storage.ref();
      const fileName = `${Date.now()}_${file.name}`;
      const fileRef = storageRef.child(fileName);
      await fileRef.put(file)
      // Upload the file to Firebase Storage
      const downloadURL = await fileRef.getDownloadURL();
      setCedulaPhoto(downloadURL);
      handleIdentificationSubmit(downloadURL);
    } catch (error) {
      setCedulaPhoto('not available');
      setShowSpinner(false)
    }
  };

  const handlePhotoChange2 = async (event) => {
    try {
      setShowSpinner(true)
      const file = event.target.files[0];

      // Create a storage reference with a unique filename
      const storage = firebase.storage();
      const storageRef = storage.ref();
      const fileName = `${Date.now()}_${file.name}`;
      const fileRef = storageRef.child(fileName);
      await fileRef.put(file);
      // Upload the file to Firebase Storage
      const downloadURL = await fileRef.getDownloadURL();
      setUserPhoto(downloadURL);
      handleFaceSubmit(downloadURL);
    } catch (error) {
      setUserPhoto('not available');
    }
  };

  const registerOtherProduct = async () => {
    try {
      let paymentAmount = (allowAnyProduct && productType === 'other' ? (((parseInt(price) * (1 - (0.5))) * (1)) / (parseInt(timeToPay) * 2)).toFixed(2) : Math.round(((parseInt(price) * (1 - (downPaymentPerc))) * (interestFactor)) / (parseInt(timeToPay) * 2)))
      // if (allowAnyProduct && productType === 'other') {
      //   paymentAmount = Math.round((Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100)) / 100);
      // }

      const deviceData = {
        user_document_id: email,
        seller_store: storeEmail,
        device_price: ((parseInt(price) + extraInitial)).toFixed(2),
        loan_duration: timeToPay,
        payment_data: {
          loan_payment_cycle: 15,
          payment_amount: paymentAmount
        },
        amount_financed: (parseInt(price) + extraInitial) - (allowAnyProduct && productType === 'other' ? ((parseInt(price) * (0.5)) + extraInitial) : Math.round((parseInt(price) * (downPaymentPerc)) + extraInitial)),
        sub_store: subStore,
        new_chip_acquired: false,
        store_user: storeUser,
        device_type: 'other',
        product_description: productName
      }
      const response = await sendPostRequest(deviceData, 'registration/deviceregistration/', token)
      if (response?.success) {
        setPage(15);
        setShowConfNumber(false);
        setShowImei(false);
        setIsLoading(false);
      } else if (response?.status.includes("Error getting device")) {
        setFormError("Número de confirmación incorrecto. Vuelva a intentar con otro número.");
        setIsLoading(false);
      } else if (response?.success === false) {
        setFormError(response.status);
        setIsLoading(false);
      } else {
        setFormError('Ocurrió un problema, intente más tarde.');
        setIsLoading(false);
      }
    } catch (err) {
      setFormError('Ocurrió un problema, intente más tarde.');
      setIsLoading(false);
    }

  }

  const handleTermsAccepted = async () => { // to-do test this agregar un field del nombre del producto
    if (allowAnyProduct && productType == 'other') {
      setIsLoading(true);
      await registerOtherProduct();
    } else if (productElegibility && productType == 'other') {
      setIsLoading(true);
      await registerOtherProduct();
    } else if (!productElegibility && allowAnyProduct && productType == 'other') { // To-do -> agregar otro else if !productElegibility && allowAnyProduct && productType == 'other' entonces se hace el anclaje del celular y despues se corre el codigo que esta aqui abajo
      const imeiInput = window.prompt('A continuación vamos a anclar el celular Android del cliente, el cual servira como garantia virtual para la compra de este producto, para comenzar introduce el número IMEI del equipo:');
      const imeiData = {
        device_imei: imeiInput
      }
      const response = await sendPostRequest(imeiData, 'anclar_device_check_imei/', token) //to-do ver pq no sirve esto con yoda
      if (response?.success) {
        goToPage(7);
        setIsLoading(false);
      } else if (response?.success === false) {
        alert(response.status);
        setIsLoading(false);
      } else {
        alert('Ocurrió un problema, intente más tarde.');
        setIsLoading(false);
      }
    } else {
      goToPage(7);
    }

  }

  const goToPage = (x) => {
    setFormError('')
    setLoginError('')
    setPage(x);
  }

  const backToWelcomePage = () => {
    window.location.reload();
  };

  const searchConfirmationNumber = async () => {
    setConfNumber('');
    setShowConfNumber(false);
    setLoginError('');
    const data = {
      store_name: storeName,
      imei: imeiNumber
    }
    const response = await sendPostRequest(data, 'registration/finddevice/', token)
    if (response?.success) {
      setConfNumber(response.data.nuovo_id);
      setShowConfNumber(true);
    } else if (response?.success === false) {
      setLoginError(response.status);
      setIsLoading(false);
    } else {
      setLoginError('Estamos teniendo problemas para conectarnos.');
      setIsLoading(false);
    }
  }

  const getFactorByInstallment = (installment, data) => {
    for (const level in data) {
      for (const entry of data[level]) {
        if (entry.installments === installment) {
          return entry.factor;
        }
      }
    }
    return null; // return null if the installment is not found
  };

  function changeTimeToPay(ttp) {
    setTimeToPay(ttp)
    const factor = getFactorByInstallment(ttp * 2, countrySettings.payment_options)
    setInterestFactor(factor)
  }

  const sendPhoneToken = async () => {
    setFormError('');
    setShowTokenField(false);
    setLoadingButton(true);
    try {
      if (!phoneNumber) {
        alert("Por favor introducir número de teléfono.")
        setLoadingButton(false);
        return;
      }
      if (!messType) {
        alert("Por favor seleccionar tipo de mensaje.")
        setLoadingButton(false);
        return;
      }
      const phoneRegex = /^\d{10,11}$/;
      if (!phoneRegex.test(phoneNumber)) {
        alert('Por favor ingrese un número de teléfono de 10 digitos.');
        setLoadingButton(false);
        return;
      }
      const codeData = {
        phone_number: phoneNumber,
        code_type: messType
      }
      const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token) //status, success, data
      if (response?.success) {
        setShowTokenField(true);
        setLoadingButton(false);
        alert('Se ha enviado un token por ' + messType + ' al ' + phoneNumber);
        setTimeLeft(30)
        const timerId = setInterval(() => {
          setTimeLeft((prevTime) => {
            if (prevTime === 1) {
              clearInterval(timerId)
            }
            return prevTime - 1
          });
        }, 1000);

      } else if (response?.success === false) {
        alert(response.status);
        setLoadingButton(false);
      } else {
        alert('Estamos teniendo problemas para conectarnos.');
        setLoadingButton(false);
      }
    } catch (error) {
      alert('Estamos teniendo problemas para conectarnos.');
      setLoadingButton(false);
    }
  }

  const fillToken = (token) => {
    setPhoneToken(token);
    if (token.length === 6) {
      setShowTokenField(false);
      setLoadingButton(true);
      confirmPhoneTokenFromUser(token);
    } else if (token.length > 6) {
      alert("El Token debe ser de 6 digitos.")
    }
  }

  const checkDeviceReady = async () => {
    let breakLoop = false
    let status = 'waiting'

    for (let i = 0; i < 10; i++) {
      if (breakLoop) {
        break;
      }
      const devDat = {
        device_imei: imeiToRegister,
        device_type: productType,
        device_management_provider: productType === "laptop" ? "absolute" : "tonic"
      };
      await new Promise((resolve) => {
        setTimeout(async () => {
          try {
            let resp = await sendPostRequest(devDat, 'registration/imeistatus/', token);
            if (resp?.success || productType === "iphone") {
              if (resp.device_ready || productType === "iphone" || productType === "laptop") {
                setIsLoading(false);
                setLoadingText('');
                setLoadingSubText('');
                setPage(18);
                breakLoop = true
                status = 'success'
              }
            } else if (resp?.success === false) {
              if (resp?.invalid_device) {
                // setFormError("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
                if (switchChecked) {
                  setFormError("Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, si el problema persiste contacte a soporte técnico.");
                  setIsLoading(false);
                  setLoadingText('');
                  setLoadingSubText('');
                  return;
                } else if (blockNuovo) {
                  setFormError("Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, Infinix, Tecno, Realme, Itel, o Motorola, si el problema persiste contacte a soporte técnico.");
                  setIsLoading(false);
                  setLoadingText('');
                  setLoadingSubText('');
                  return;
                }
                setSecurityProvider("nuovo");
                setIsLoading(false);
                setLoadingText('');
                setLoadingSubText('');
                return;
              } else {
                setFormError(resp.status);
              }
              setIsLoading(false);
              setLoadingText('');
              setLoadingSubText('');
              breakLoop = true
              status = 'fail'
            } else {
              setFormError("Estamos teniendo problemas para conectarnos.");
              setIsLoading(false);
              setLoadingText('');
              setLoadingSubText('');
              breakLoop = true
              status = 'fail'
            }
          } catch (error) {
            console.error('Error occurred:', error);
            setFormError("Error al realizar la solicitud.");
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
            breakLoop = true
            status = 'fail'
          }
          resolve();
        }, 15000);
      });
    }
    if (status === "waiting") {
      setFormError("Se ha alcanzado el tiempo máximo de espera, por favor vuelva a intentar o contacte a servicio técnico.");
      setIsLoading(false);
      setLoadingText('');
      setLoadingSubText('');
    }
  };

  const registerWithTonic = async () => {
    setFormError('')
    if (!imeiToRegister || !imeiToRegister2) {
      setFormError('Por favor rellene todas las casillas para continuar.')
      return;
    }
    if (productType !== "laptop" && (imeiToRegister.length < 15 || imeiToRegister2.length < 15)) {
      setFormError('IMEI ivalido, asegurese de que el IMEI tenga 15 caracteres.')
      return;
    }
    if (imeiToRegister !== imeiToRegister2) {
      setFormError('Los IMEIs introducidos no coinciden.')
      return;
    }
    try {
      setIsLoading(true);
      setLoadingText('Registrando equipo');
      setLoadingSubText(productType === 'laptop' ? 'Esto puede tardar unos segundos' : 'Esto puede tardar unos segundos, por favor NO encienda el teléfono aún.');
      const devData = {
        device_imei: imeiToRegister,
        device_type: productType,
        device_management_provider: productType === "laptop" ? "absolute" : "tonic"
      }
      let response
      if (switchChecked) {
        //register_promo_imei , post, input device_imei
        response = await sendPostRequest(devData, 'registration/promo/', token)
      } else if (switchChecked2) {
        response = await sendPostRequest(devData, 'registration/promo2/', token)
      } else {
        response = await sendPostRequest(devData, 'registration/registerimei/', token)
      }
      if (response?.success) {
        await checkDeviceReady();
      } else if (response?.success === false) {
        setFormError(response.status)
        setIsLoading(false);
        setLoadingText('');
        setLoadingSubText('');
      } else {
        setFormError("Estamos teniendo problemas para conectarnos.")
        setIsLoading(false);
        setLoadingText('');
        setLoadingSubText('');
      }
    } catch (err) {
      setFormError("Error: " + err.message);
      setIsLoading(false);
      setLoadingText('');
      setLoadingSubText('');
    }
  }

  const callDevReg = async () => {
    try {
      setIsLoading(true);
      setLoadingText('Finalizando registro');
      if (!productElegibility && allowAnyProduct && productType == 'other') {
        let devData = {
          device_imei: imeiToRegister,
          seller_store: storeEmail,
          sub_store: subStore,
          device_management_provider: "nuovo",
          user_document_id: email,
          store_user: storeUser,

        }
        if (securityProvider === "tonic") {
          devData = {
            device_imei: imeiToRegister,
            seller_store: storeEmail,
            sub_store: subStore,
            device_management_provider: "tonic",
            user_document_id: email,
            store_user: storeUser
          }
        }

        const response = await sendPostRequest(devData, 'anclar_device/', token)//status, success, data
        if (response?.success) {
          await registerOtherProduct();
          setIsLoading(false);
          setLoadingText('');
          setLoadingSubText('');
        } else if (response?.success === false) {
          setFormError(response.status);
          setIsLoading(false);
        } else {
          setFormError('Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.');
          setIsLoading(false);
        }
        return;
      }
      let paymentAmount = Math.round((Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100)) / 100);
      if (switchChecked) {
        paymentAmount = Math.round((parseInt(price) * 0.5) * 1.25 / (timeToPay * 2))
      }
      let amountFinanced = (parseInt(price) + extraInitial) - (Math.round((parseInt(price) * downPaymentPerc) + extraInitial))

      if (switchChecked) {
        amountFinanced = Math.round(parseInt(price) * 0.5)
      }
      const deviceData = {
        user_document_id: email,
        device_imei: imeiToRegister,
        seller_store: storeEmail,
        device_price: ((parseInt(price) + extraInitial)).toFixed(2),
        loan_duration: timeToPay,
        payment_data: {
          loan_payment_cycle: 15,
          payment_amount: paymentAmount
        },
        device_management_provider: "tonic",
        amount_financed: amountFinanced,
        sub_store: subStore,
        new_chip_acquired: buyingChip === 'Si' ? true : false,
        store_user: storeUser,
        device_type: 'phone',
        promotional_code: switchChecked ? "promo_samsung" : (switchChecked2 ? "promo_infinix" : ""),
        online_purchase: isOnline
      }
      const response = await sendPostRequest(deviceData, 'registration/deviceregistration/', token)//status, success, data
      if (response?.success) {
        setPage(15);
        setIsLoading(false);
        setLoadingText('');
        setLoadingSubText('');
      } else if (response?.status.includes("Error getting device")) {
        setFormError("Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.");
        setIsLoading(false);
        setLoadingText('');
        setLoadingSubText('');
      } else if (response?.success === false) {
        setFormError(response.status);
        setIsLoading(false);
        setLoadingText('');
        setLoadingSubText('');
      } else {
        setFormError("Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.");
        setIsLoading(false);
        setLoadingText('');
        setLoadingSubText('');
      }
    } catch (err) {
      setFormError("Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.");
      setIsLoading(false);
      setLoadingText('');
      setLoadingSubText('');
    }
  }

  const checkTonicStatus = async () => {
    setFormError('');
    let breakLoop = false;
    let status = 'waiting';
    setIsLoading(true);
    setLoadingText('Chequeando estatus del equipo');
    setLoadingSubText('Esto puede tardar unos minutos.');
    for (let i = 0; i < 10; i++) {
      if (breakLoop) {
        break;
      }
      const devDat = {
        device_imei: imeiToRegister,
        device_management_provider: productType === "laptop" ? "absolute" : "tonic",
        device_type: productType
      };
      await new Promise((resolve) => {
        setTimeout(async () => {
          try {
            let resp = await sendPostRequest(devDat, 'registration/activeimei/', token);
            if (resp?.success || productType === "iphone") {
              if (resp.device_active || productType === "iphone") {
                setIsLoading(false);
                setLoadingText('');
                setLoadingSubText('');
                setPage(19);
                status = "success";
                breakLoop = true;
              }
            } else if (resp?.success === false) {
              if (resp?.invalid_device) {
                setFormError("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
              } else {
                setFormError(resp.status);
              }
              setIsLoading(false);
              setLoadingText('');
              setLoadingSubText('');
              breakLoop = true;
              status = "fail";
            } else {
              setFormError("Estamos teniendo problemas para conectarnos.");
              setIsLoading(false);
              setLoadingText('');
              setLoadingSubText('');
              breakLoop = true;
              status = "fail";
            }
          } catch (error) {
            console.error('Error occurred:', error);
            setFormError("Error al realizar la solicitud.");
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
            breakLoop = true;
            status = "fail";
          }
          resolve();
        }, 15000);
      });
    }
    if (status === "waiting") {
      // setFormError("Error: El celular no fue encendido o configurado correctamente. Si cree que es un error vuelva a hacer clic en Siguiente, de lo contrario debe formatear el equipo de fabrica, prenderlo, configurarlo, y luego hacer clic en Siguiente.");
      setSecurityProvider('nuovo');
      setPage(10);
      setIsLoading(false);
      setLoadingText('');
      setLoadingSubText('');
      alert('El equipo no se pudo configurar correctamente. Porfavor reseteelo de fabrica y prosiga con la instalación con QR.')
    }
  }

  function renderDownPayments(start) {
    const result = [];
    for (let i = start; i <= 50; i += 5) {
      result.push(i);
    }
    return result;
  }

  const confirmPhoneTokenFromUser = async (ptoken) => {
    setFormError('');
    if (!ptoken) {
      setFormError('Llene todos los datos para continuar');
      return;
    }
    try {
      const codeData = {
        confirmation_code: parseInt(ptoken)
      }
      const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token)
      if (response?.success) {
        setLoadingButton(false);
        setTokenVerified(true);
        alert('Token confirmado exitosamente');
      } else if (response?.success === false) {
        alert(response.status);
        setLoadingButton(false);
        setShowTokenField(true);
      } else {
        alert('Estamos teniendo problemas para conectarnos.');
        setLoadingButton(false);
        setShowTokenField(true);
      }
    } catch (error) {
      alert('Estamos teniendo problemas para conectarnos.');
      setLoadingButton(false);
      setShowTokenField(true);
    }
  }

  const extractLevelNumber = (levelString) => {
    const match = levelString.match(/level_(\d+)/);
    return match ? parseInt(match[1], 10) : null;
  };

  const renderPaymentOptions = (userLevel, data) => {
    const levelNum = extractLevelNumber(userLevel)
    let componentsToRender = [];

    for (let level = 1; level <= levelNum; level++) {
      const levelKey = `level_${level}`;
      if (data[levelKey]) {
        data[levelKey].forEach(item => {
          componentsToRender.push(
            <MenuItem key={`${levelKey}-${item.installments}`} value={item.installments / 2}>{item.installments}</MenuItem>
          );
        });
      }
    }

    return componentsToRender;
  };

  return (
    <div>
      <Container maxWidth="sm" style={{
        marginHorizontal: 10,
        boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
        paddingTop: 35,
        paddingBottom: 35,
        marginBottom: 30,
        borderRadius: '8px',
        paddingRight: 50,
        paddingLeft: 50,
        backgroundColor: '#1C1C1E',
        marginTop: 100
      }}>

        {isAuthenticated && page === 2 && !isLoading && (
          <div className={classes.containerWelcome}>
            <div className={classes.topButton}>
              <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                {"Nuevo Registro"}
              </Typography>
              <p style={{ textAlign: 'center', color: "silver", fontSize: 16 }}>Por favor ingresa la cédula del cliente realizando la compra</p>
              <> <TextField
                label="Cédula del cliente"
                name="cedula"
                value={cedula}
                onChange={(e) => setCedula(e.target.value)}
                // variant="outlined"
                className={classes.textFields}
                margin="normal"
                fullWidth
                error={loginError !== ''}
                helperText={loginError}
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}></TextField>
                <Button variant="contained" fullWidth size="small" margin="normal" className={classes.welcomeButtons} onClick={handleCheckCedula}>
                  Continuar
                </Button> </>
            </div>
          </div>
        )}
        {
          isAuthenticated && page === 16 && !isLoading && (
            <>
              <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                Verificación de identidad
              </Typography>
              {cedulaVerified ? <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <VerifiedUserOutlined align="center" style={{ color: "#45C4D6", alignSelf: 'center', fontSize: 80 }} />
                </div>
                <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                  Verificación de identidad exitosa
                </Typography> </>
                : <>
                  {showSpinner ?
                    <>
                      {/* <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                        Verificando
                      </Typography> */}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                        <HashLoader color="#45C4D6" />
                      </div>
                    </> :
                    <>
                      <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                        Por favor suba una foto del documento de identidad del cliente
                      </Typography>
                      <TextField
                        select
                        label="Tipo de documento"
                        name="documentType"
                        value={documentType}
                        onChange={(e) => setDocumentType(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth
                        InputProps={{
                          style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: '#D3D3D3', // Label color
                          },
                        }}
                      >
                        {countrySettings.id_types.map((item, index) => (
                          <MenuItem value={item}>{item.name}</MenuItem>
                        ))}
                      </TextField>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <img style={{ width: 250, marginBottom: 10, marginTop: 10, alignSelf: 'center' }} src={idIcon} alt="idIcon" />
                      </div>
                      <div style={{ marginBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomTextField
                          accept="image/*"
                          onChange={handlePhotoChange}
                          InputProps={{
                            endAdornment: (
                              <Button style={{ color: '#2A2E32', backgroundColor: '#45C4D6', width: 200 }} endIcon={<AddAPhoto />} onClick={handleButtonClick}>
                                Subir Imagen
                              </Button>
                            ),
                          }}
                          inputProps={{
                            ref: fileInputRef,
                            type: 'file',
                            style: { display: 'none' },
                          }}
                        />
                      </div>

                      {formError && <p className={classes.formError}>{formError}</p>}
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton
                          // variant="contained"
                          // startIcon={<ArrowBack />}
                          onClick={backToWelcomePage}
                          className={classes.welcomeButtonsNoBorder}
                        // color="secondary"
                        >
                          <ArrowBack />
                        </IconButton>
                      </div>
                    </>}
                </>}
            </>
          )
        }
        {
          isAuthenticated && page === 19 && !isLoading && (
            <>
              <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                Aceptar Términos y Condiciones
              </Typography>
              {productType === "phone" && <><Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                {'Ahora proceda a descargar la app Krece en el equipo en venta escaneando este QR. (Este paso NO es necesario para equipos SAMSUNG)'}
              </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img style={{ width: 200, marginRight: 50, marginBottom: 20 }} src={downloadQr} alt="Descargar" />
                  <Typography variant="h5" color="white" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                    {'O entrando a este enlace https://krece.app/apk'}
                  </Typography>
                </div></>}
              {productType === "iphone" && <><Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                {'Ahora proceda a descargar la app Krece en la AppStore en el equipo en venta.'}
              </Typography></>}
              {!isOnline && <>
                <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                  Por último, haga clic en "Generar Contrato", el cliente debe abrir la app Krece y aceptar el contrato.
                </Typography>
                {!tokenGenerated && <Button variant="contained" className={classes.welcomeButtons} onClick={generateAnexo}>
                  Generar Contrato
                </Button>}
                {anexoAccepted &&
                  <div style={{ display: 'flex' }}><Typography align="center" style={{
                    color: 'white',
                    fontSize: 16,
                    fontWeight: 'bold'
                  }} gutterBottom>
                    Contrato Aceptado
                  </Typography>
                    <CheckCircle style={{ color: "#8BC34A", alignSelf: 'center', marginBottom: 10 }} />
                  </div>
                }
                {tokenGenerated && !anexoAccepted && <Button variant="contained" className={classes.welcomeButtons} onClick={generateAnexo}>
                  Generar Otro Contrato
                </Button>}
              </>}
              {formError && <p className={classes.formError}>{formError}</p>}
              <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={callDevReg}>
                Concretar Venta
              </Button>
              <Button
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={() => setPage(18)}
                className={classes.welcomeButtons}
              >
                Atrás
              </Button>
            </>
          )
        }
        {
          isAuthenticated && page === 18 && !isLoading && (
            <>
              <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                Configuración de seguridad
              </Typography>
              {productType === "phone" && <><Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                Por favor proceda a encender el equipo, unirlo con el WIFI de la tienda, aceptar todas las condiciones y realizar la configuración hasta que vea la siguiente pantalla.
              </Typography>
                <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                  {'IMPORTANTE (NO APLICA PARA SAMSUNG): SI NO SE LOGRA LLEGAR A LA PANTALLA QUE MOSTRAMOS ABAJO, TERMINE DE CONFIGURAR EL CELULAR E INTENTE RESTAURAR DE FABRICA. SI EL EQUIPO LE PERMITE RESTAURAR DE FABRICA QUIERE DECIR QUE NO SE CONFIGURO CORRECTAMENTE Y SE DEBE RESTAURAR DE FABRICA Y VOLVER A COMENZAR. DE LO CONTRARIO, SI SE CONFIGURO CORRECTAMENTE, PUEDE HACER CLIC EN SIGUIENTE.'}
                </Typography>
                <img style={{ height: 448, width: 200, marginBottom: 10, marginTop: 10, alignSelf: 'center' }} src={homeScreen} alt="homeScreen" /></>}
              {productType === "iphone" && <><Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                Por favor proceda a encender el equipo, unirlo con el WIFI de la tienda, aceptar todas las condiciones y realizar la configuración hasta el final.
              </Typography></>}
              {formError && <p className={classes.formError}>{formError}</p>}
              <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={checkTonicStatus}>
                Siguiente
              </Button>
              <Button
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={() => setPage(10)}
                className={classes.welcomeButtons}
              >
                Atrás
              </Button>
            </>
          )
        }
        {
          isAuthenticated && page === 17 && !isLoading && (
            <>
              <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                Verificación Biométrica
              </Typography>
              {cedulaVerified ? <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <VerifiedUserOutlined align="center" style={{ color: "#45C4D6", alignSelf: 'center', fontSize: 80 }} />
                </div>
                <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                  Verificación Biométrica exitosa
                </Typography> </>
                : <>
                  {showSpinner ?
                    <>
                      {/* <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                        Verificando
                      </Typography> */}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                        <HashLoader color="#45C4D6" />
                      </div>
                    </> :
                    <>
                      <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'center', color: "silver", fontSize: 16 }} gutterBottom>
                        Por favor suba una foto tipo pasaporte del cliente
                      </Typography>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <img style={{ width: 150, marginBottom: 10, marginTop: 10, alignSelf: 'center' }} src={faceId} alt="faceId" />
                      </div>
                      <div style={{ marginBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomTextField
                          accept="image/*"
                          onChange={handlePhotoChange2}
                          InputProps={{
                            endAdornment: (
                              <Button style={{ color: '#1C1C1E', backgroundColor: '#45C4D6', width: 200 }} endIcon={<AddAPhoto />} onClick={handleButtonClick}>
                                Subir Imagen
                              </Button>
                            ),
                          }}
                          inputProps={{
                            ref: fileInputRef,
                            type: 'file',
                            style: { display: 'none' },
                          }}
                        />
                      </div>
                      {formError && <p className={classes.formError}>{formError}</p>}
                      <Button
                        variant="contained"
                        startIcon={<ArrowBack />}
                        onClick={backToWelcomePage}
                        className={classes.welcomeButtons}
                      >
                        Atrás
                      </Button>
                    </>}
                </>}
            </>
          )
        }
        {isAuthenticated && page === 3 && !isLoading && (
          <>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Información de usuario
            </Typography>
            <form onSubmit={handleInformationSubmit}>
              <div>
                {/* <mati-button clientid="6551a1651cec0a001caff82f" flowId="6551a1651cec0a001caff82e" metadata='{"key": "value"}' /> */}
              </div>
              <TextField
                label="Nombre"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id="outlined-number"
                margin="normal"
                className={classes.textFields}
                InputProps={{
                  style: {
                    color: 'white',
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3',
                  },
                }}
                fullWidth
              />
              <TextField
                label="Apellidos"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="normal"
                className={classes.textFields}
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                  readOnly: userExistsCanBeFinanced
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              />
              <TextField
                label="Confirmación de email"
                name="email2"
                type="email2"
                value={email2}
                onChange={(e) => setEmail2(e.target.value.toLowerCase().trim())}
                onPaste={(e) => e.preventDefault()}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                  readOnly: userExistsCanBeFinanced
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              />
              <TextField
                label="Fecha de Nacimiento"
                name="birthDate"
                type="date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                  shrink: true
                }}
              />
              <TextField
                select
                label="Género"
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                <MenuItem value="male">Masculino</MenuItem>
                <MenuItem value="female">Femenino</MenuItem>
              </TextField>
              <TextField
                label="Direccion de hogar"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              />
              {/* <TextField
                label="Código postal"
                name="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              /> */}
              <Typography variant="h6" className={classes.title2} style={{ marginTop: 25 }} gutterBottom>
                Verificación de número de teléfono
              </Typography>
              <div className={classes.border2}>
                <div><TextField
                  label="Número de teléfono"
                  name="phoneNumber"
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className={classes.textFields}
                  margin="normal"
                  InputProps={{
                    style: {
                      color: 'white', // Text color
                      borderBottom: '1px solid white'
                    },
                    readOnly: tokenVerified,
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#D3D3D3', // Label color
                    },
                  }}
                />

                  {tokenVerified && !loadingButton ? <CheckCircle style={{ color: "#45C4D6", alignSelf: 'center' }} /> : timeLeft === 0 && <Button
                    variant="contained"
                    onClick={sendPhoneToken}
                    className={classes.welcomeButtons2}
                  >
                    Enviar Token
                  </Button>}
                  {timeLeft > 0 && <Typography variant="h6" className={classes.title2} style={{ marginTop: 25 }} gutterBottom>
                    {"puede enviar otro token en: " + timeLeft}
                  </Typography>}
                  {loadingButton &&
                    <div style={{ marginTop: 15 }}>
                      <CircularProgress color="secondary" />
                    </div>
                  }
                  {showTokenField && <TextField
                    label="Token"
                    name="phoneToken"
                    type="tel"
                    value={phoneToken}
                    onChange={(e) => fillToken(e.target.value)}
                    className={classes.textFields}
                    margin="normal"
                    InputProps={{
                      style: {
                        color: 'white', // Text color
                        borderBottom: '1px solid white'
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#D3D3D3', // Label color
                      },
                    }}
                  />}</div>
                <div style={{ width: 300 }}>
                  <TextField
                    select
                    label="Tipo"
                    name="messType"
                    value={messType}
                    onChange={(e) => setMessType(e.target.value)}
                    className={classes.textFields}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      style: {
                        color: 'white', // Text color
                        borderBottom: '1px solid white'
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#D3D3D3', // Label color
                      },
                    }}
                  >
                    <MenuItem value="sms">SMS</MenuItem>
                    <MenuItem value="whatsapp">Whatsapp</MenuItem>
                  </TextField>
                </div>
              </div>
              {formError && <p className={classes.formError}>{formError}</p>}
              <Button type="submit" variant="contained" className={classes.welcomeButtons} fullWidth>
                Ingresar
              </Button>
              <Button
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={backToWelcomePage}
                className={classes.welcomeButtons}
              >
                Atrás
              </Button>
            </form>
          </>
        )}
        {isAuthenticated && isLoading && (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
              <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                {loadingText}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
              <BeatLoader color="#45C4D6" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
              <Typography variant="h6" className={classes.title2} gutterBottom>
                {loadingSubText}
              </Typography>
            </div>
          </div>
        )}
        {isAuthenticated && page === 8 && !isLoading && (
          <>

            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Datos Adicionales
            </Typography>
            <form onSubmit={handleApplicationSubmit}>
              <TextField
                select
                label={"Ciudad donde vives"}
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                {countrySettings.cities.map((item, index) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
              <TextField
                label={"Salario Mensual (en " + countrySettings.currency_name + ")"}
                name="monthlySalary"
                type="number"
                value={monthlySalary}
                onChange={(e) => setMonthlySalary(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              />
              <TextField
                select
                label={"Ahorros estimados (en " + countrySettings.currency_name + ")"}
                name="savings"
                type="number"
                value={savings}
                onChange={(e) => setSavings(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                {countrySettings.salary_ranges.map((item, index) => (
                  <MenuItem value={item.split('$')[1]}>{item}</MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Nivel de educación"
                name="educationLevel"
                value={educationLevel}
                onChange={(e) => setEducationLevel(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                <MenuItem value="Primaria">Primaria</MenuItem>
                <MenuItem value="Bachillerato">Bachillerato</MenuItem>
                <MenuItem value="Universidad">Universidad</MenuItem>
              </TextField>
              {/* <TextField
                select
                label="Estado Civil"
                name="maritalStatus"
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                <MenuItem value="Casado">Casado</MenuItem>
                <MenuItem value="Soltero">Soltero</MenuItem>
              </TextField> */}
              {formError && <p className={classes.formError}>{formError}</p>}
              <Button type="submit" variant="contained" className={classes.welcomeButtons} fullWidth>
                Ingresar
              </Button>
              <Button
                variant="contained"
                className={classes.welcomeButtons}
                startIcon={<ArrowBack />}
                onClick={backToWelcomePage}
                style={{ marginTop: '10px' }}
              >
                Atrás
              </Button>
            </form>
          </>
        )}
        {isAuthenticated && page === 4 && !isLoading && (
          <>
            {productType !== 'other' && country === 'venezuela' && <div style={{ flexDirection: 'row' }}>
              <span className={classes.approvalMsg}>Activar promoSAMSUNG</span>
              <Switch
                checked={switchChecked}
                onChange={() => {
                  setSwitchChecked(!switchChecked)
                  setSwitchChecked2(false)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                // color="default"
                // classes={{ root: classes.switchColor }}
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                color={switchChecked ? "primary" : "default"}></Switch>
            </div>}
            {/* 
            {productType !== 'other' && country === 'venezuela' && <div style={{ flexDirection: 'row' }}>
              <span className={classes.approvalMsg}>Activar promoINFINIX</span>
              <Switch
                checked={switchChecked2 && isInfinix}
                onChange={() => {
                  setSwitchChecked2(!switchChecked2)
                  setSwitchChecked(false)
                  setDownPaymentPerc(switchChecked2 ? 0.4 : 0.25)
                  setIsInfinix(true)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                // color="default"
                // classes={{ root: classes.switchColor }}
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                color={switchChecked2 ? "primary" : "default"}></Switch>
            </div>}
            {productType !== 'other' && country === 'venezuela' && <div style={{ flexDirection: 'row' }}>
              <span className={classes.approvalMsg}>Activar promoHONOR (Magic 6 Lite)</span>
              <Switch
                checked={switchChecked2 && !isInfinix}
                onChange={() => {
                  setSwitchChecked2(!switchChecked2)
                  setSwitchChecked(false)
                  setDownPaymentPerc(switchChecked2 ? 0.4 : 0.25)
                  setIsInfinix(false)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                // color="default"
                // classes={{ root: classes.switchColor }}
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                color={switchChecked2 ? "primary" : "default"}></Switch>
            </div>} */}
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Decisión de solicitud
            </Typography>
            <div>
              {switchChecked ? <div>
                <div className={classes.border}>
                  <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                    Bienvenido a la promoSAMSUNG!
                  </Typography>
                  <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
                    Con ésta promoción te podrás llevar cualquier equipo SAMSUNG pagando un 50% de inical SIN IMPORTAR LA LINEA DE COMPRA APROBADA.
                  </Typography>
                </div>
              </div> :
                <div className={classes.border}>
                  {switchChecked2 && isInfinix && <><Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                    Bienvenido a la promoINFINIX!
                  </Typography>
                    <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                      Con esta promoción te podrás llevar un Note 40, Note 40 Pro, y GT 20 Pro pagando un 25% de inical SIN IMPORTAR LA LINEA DE COMPRA APROBADA.
                    </Typography></>}
                  {switchChecked2 && !isInfinix && <><Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                    Bienvenido a la promoHONOR!
                  </Typography>
                    <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                      Con esta promoción te podrás llevar un Magic 6 Lite pagando un 25% de inical SIN IMPORTAR LA LINEA DE COMPRA APROBADA.
                    </Typography></>}
                  {!switchChecked2 && <><Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                    ¡Nos complace informarle que ha sido {<Typography variant="h5" color="secondary" className={classes.highlighted}>APROBADO</Typography>} para una linea de compra de {<Typography variant="h5" color="secondary" className={classes.highlighted}>{productType !== 'other' ? (countrySettings.currency_code + (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount)) : (countrySettings.currency_code + maxLoan3MonthsKrecePlus)}</Typography>} después de la inicial, con una cuota inicial del {productType !== 'other' ? (downPaymentPerc * 100) + '%' : 50 + '% SIN INTERESES'}!
                    {/* ¡Nos complace informarle que ha sido {<Typography variant="h5" color="secondary" className={classes.highlighted}>APROBADO</Typography>} para una linea de compra de {<Typography variant="h5" color="secondary" className={classes.highlighted}>ILIMITADA POR BLACK FRIDAY</Typography>} después de la inicial, con una cuota inicial del {productType !== 'other' ? (downPaymentPerc * 100) + '%' : 50 + '% SIN INTERESES'}! */}
                  </Typography>
                    <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
                      Si desea un equipo que requiere una linea de compra mayor deberá pagar la diferencia como parte de la cuota inicial.
                    </Typography></>}
                </div>}
              <div>
                <div>
                  <Typography variant="h5" align="center" color="textPrimery" className={classes.highlighted} gutterBottom>
                    Simule su inicial y cuotas
                  </Typography>
                  <div className={classes.border} style={{ padding: 20 }}>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 20, justifyContent: 'space-between' }}>
                      <TextField
                        select
                        label="Número de cuotas"
                        name="timeToPay"
                        value={timeToPay}
                        onChange={(e) => changeTimeToPay(e.target.value)}
                        className={classes.textFields}
                        style={{ width: 200 }}
                        margin="normal"
                        InputProps={{
                          style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: '#D3D3D3', // Label color
                          },
                        }}
                      >
                        {renderPaymentOptions(level, countrySettings.payment_options)}
                      </TextField>
                      <TextField
                        label="Precio del equipo"
                        name="amountSlider"
                        type="number"
                        value={amountSlider}
                        onChange={(e) => setAmountSlider(e.target.value)}
                        className={classes.textFields}
                        style={{ width: 200 }}
                        margin="normal"
                        error={switchChecked && amountSlider < 100}
                        helperText={switchChecked && amountSlider < 100 ? "El monto debe ser mayor a 100" : ""}
                        InputProps={{
                          style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: '#D3D3D3', // Label color
                          },
                        }}
                      />
                    </div>
                    {!switchChecked && !switchChecked2 && <TextField
                      select
                      label="Inicial deseada"
                      name="downPayment"
                      value={downPaymentPerc}
                      onChange={(e) => setDownPaymentPerc(e.target.value)}
                      className={classes.textFields}
                      style={{ width: 200 }}
                      margin="normal"
                      InputProps={{
                        style: {
                          color: 'white', // Text color
                          borderBottom: '1px solid white'
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: '#D3D3D3', // Label color
                        },
                      }}
                    >
                      {renderDownPayments(countrySettings.down_payment[level] * 100).map((item, index) => (
                        <MenuItem value={item / 100}>{item + "%"}</MenuItem>
                      ))}
                    </TextField>}
                    {!switchChecked ? <div style={{ marginTop: 20, display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between' }}>
                      <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                        {productType !== 'other' ? <>
                          <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{"Cuota inicial (" + (((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider ? (amountSlider * downPaymentPerc) : (((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * downPaymentPerc) + (amountSlider - (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))) * 100 / amountSlider).toFixed(0) + "%):"}</Typography>
                          <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider ? (amountSlider * downPaymentPerc) : (((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * downPaymentPerc) + (amountSlider - (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))).toFixed(0)}</Typography>
                        </> : <>
                          <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{"Cuota inicial (" + ((maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider ? (amountSlider * 0.5) : ((maxLoan3MonthsKrecePlus / (1 - 0.5) * 0.5) + (amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5)))) * 100 / amountSlider).toFixed(0) + "%):"}</Typography>
                          <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + (maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider ? (amountSlider * 0.5) : ((maxLoan3MonthsKrecePlus / (1 - 0.5) * 0.5) + (amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5)))).toFixed(2)}</Typography>
                        </>}
                      </div>
                      <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                        {productType !== 'other' ? <>
                          <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{timeToPay * 2 + ' cuotas quincenales de:'}</Typography>
                          <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + Math.round(((((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider ? (amountSlider * (1 - downPaymentPerc)) : ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * (1 - downPaymentPerc)))) * interestFactor) / (parseInt(timeToPay) * 2)).toFixed(0)}</Typography>
                        </> : <>
                          <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{timeToPay * 2 + ' cuotas quincenales de:'}</Typography>
                          <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + ((((maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider ? (amountSlider * (1 - 0.5)) : (maxLoan3MonthsKrecePlus / (1 - 0.5) * (1 - 0.5))))) / (parseInt(timeToPay) * 2)).toFixed(2)}</Typography>
                        </>}
                      </div>
                    </div> :
                      <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                          <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{"Cuota inicial (50%)"}</Typography>
                          <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + Math.round(amountSlider / 2).toFixed(0)}</Typography>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                          <Typography variant="h6" color="secondary" className={classes.approvalMsg} style={{ marginRight: 15 }}>{timeToPay * 2 + ' cuotas quincenales de:'}</Typography>
                          <Typography variant="h6" color="secondary" align="center" className={classes.approvalMsg} style={{ color: "#45C4D6" }}>{countrySettings.currency_code + Math.round((amountSlider / 2) * (1.25) / (timeToPay * 2))}</Typography>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
              {limitReached || applicationApprovalData.threeMonthAmount == 0 ? <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(2)}>
                Volver al Inicio
              </Button> :
                <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleNext}>
                  Registrar Dispositivo
                </Button>}
              {/* <Typography variant="h6" color="secondary" className={classes.approvalMsg} gutterBottom>
                ¿Cómo funciona Krece?
              </Typography>
              <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
                El cliente siempre deberá pagar una cuota inicial equivalente al {downPaymentPerc * 100}% del costo total del télefono directamente a la tienda.
                El otro {(1 - downPaymentPerc) * 100}% y la tasa de tramitación de Krece serán pagados en partes a Krece en 6 cuotas quincenales a través de los siguientes métodos de pago:
                Pago Movil, Dólares en tienda, y Transferencia bancaria. Para más información puede visitar nuestra <a style={{ color: 'lightblue', textDecoration: 'none' }} href="https://www.krece.app/faq/" target="_blank">página de preguntas frecuentes</a>.
              </Typography> */}
              <Button
                variant="contained"
                className={classes.welcomeButtons}
                startIcon={<ArrowBack />}
                onClick={backToWelcomePage}
                style={{ marginTop: '10px' }}
              >
                Atrás
              </Button>
            </div>
          </>
        )}
        {isAuthenticated && page === 5 && !isLoading && (
          <>
            {productType === 'phone' && country === 'venezuela' && <><span className={classes.approvalMsg}>Activar promoSAMSUNG</span>
              <Switch
                checked={switchChecked}
                onChange={() => {
                  setSwitchChecked(!switchChecked)
                  setSwitchChecked2(false)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                // color="default"
                // classes={{ root: classes.switchColor }}
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                color={switchChecked ? "primary" : "default"}></Switch></>}
            {/* 
            {productType === 'phone' && country === 'venezuela' && <div style={{ flexDirection: 'row' }}>
              <span className={classes.approvalMsg}>Activar promoINFINIX</span>
              <Switch
                checked={switchChecked2 && isInfinix}
                onChange={() => {
                  setSwitchChecked2(!switchChecked2)
                  setSwitchChecked(false)
                  setDownPaymentPerc(switchChecked2 ? 0.4 : 0.25)
                  setIsInfinix(true)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                // color="default"
                // classes={{ root: classes.switchColor }}
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                color={switchChecked2 ? "primary" : "default"}></Switch>
            </div>}
            {productType === 'phone' && country === 'venezuela' && <div style={{ flexDirection: 'row' }}>
              <span className={classes.approvalMsg}>Activar promoHONOR (Magic 6 Lite)</span>
              <Switch
                checked={switchChecked2 && !isInfinix}
                onChange={() => {
                  setSwitchChecked2(!switchChecked2)
                  setSwitchChecked(false)
                  setDownPaymentPerc(switchChecked2 ? 0.4 : 0.25)
                  setIsInfinix(false)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                // color="default"
                // classes={{ root: classes.switchColor }}
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                color={switchChecked2 ? "primary" : "default"}></Switch>
            </div>} */}
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Información del producto
            </Typography>
            {switchChecked && <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
              {"(promoSAMSUNG activada)"}
            </Typography>}
            {switchChecked2 && isInfinix && <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
              {"(promoINFINIX activada)"}
            </Typography>}
            {switchChecked2 && !isInfinix && <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
              {"(promoHONOR activada)"}
            </Typography>}
            <form onSubmit={handleRegistrationSubmit}>
              <TextField
                select
                label="Tipo de producto"
                name="productType"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                <MenuItem value='phone'>Celular Android</MenuItem>
                {allowIphone && (!switchChecked && !switchChecked2) && <MenuItem value='iphone'>Celular iPhone</MenuItem>}
                {(!switchChecked && !switchChecked2) && <MenuItem value='tablet'>Tablet</MenuItem>}
                {allowLaptop && (!switchChecked && !switchChecked2) && <MenuItem value='laptop'>Laptop</MenuItem>}
                {(productElegibility || allowAnyProduct) && (!switchChecked && !switchChecked2) && <MenuItem value='other'>Otro</MenuItem>}
              </TextField>
              {(productElegibility || allowAnyProduct) && <>
                {productType === 'other' && <TextField
                  label="Nombre del producto"
                  name="productName"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  className={classes.textFields}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      color: 'white', // Text color
                      borderBottom: '1px solid white'
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#D3D3D3', // Label color
                    },
                  }}
                >
                </TextField>} </>
              }
              {allowOnline && <TextField
                select
                label="Tipo de venta"
                name="isOnline"
                value={isOnline}
                onChange={(e) => setIsOnline(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                <MenuItem value={true}>Online</MenuItem>
                <MenuItem value={false}>En tienda física</MenuItem>
              </TextField>}
              <TextField
                label={"Precio de venta (en " + countrySettings.currency_name + ")"}
                name="price"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className={classes.textFields}
                margin="normal"
                error={switchChecked && price < 100}
                helperText={switchChecked && price < 100 ? "El monto debe ser mayor a 100" : ""}
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              />
              <TextField
                select
                label="Número de cuotas"
                name="timeToPay"
                value={timeToPay}
                onChange={(e) => changeTimeToPay(e.target.value)}
                className={classes.textFields}
                style={{ width: 200 }}
                margin="normal"
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                {renderPaymentOptions(level, countrySettings.payment_options)}
              </TextField>
              {!switchChecked && !switchChecked2 && <TextField
                select
                label="Inicial deseada"
                name="downPayment"
                value={downPaymentPerc}
                onChange={(e) => setDownPaymentPerc(e.target.value)}
                className={classes.textFields}
                style={{ width: 200 }}
                margin="normal"
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                {renderDownPayments(countrySettings.down_payment[level] * 100).map((item, index) => (
                  <MenuItem value={item / 100}>{item + "%"}</MenuItem>
                ))}
              </TextField>}
              <TextField
                select
                label="Local de venta"
                name="subStore"
                value={subStore}
                onChange={(e) => setSubStore(e.target.value)}
                className={classes.textFields}
                margin="normal"
                fullWidth
                InputProps={{
                  style: {
                    color: 'white', // Text color
                    borderBottom: '1px solid white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#D3D3D3', // Label color
                  },
                }}
              >
                {subStoreList.map((item, index) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
              {formError && <p className={classes.formError}>{formError}</p>}
              <Button type="submit" variant="contained" className={classes.welcomeButtons} fullWidth>
                Siguiente
              </Button>
              <Button
                variant="contained"
                className={classes.welcomeButtons}
                startIcon={<ArrowBack />}
                onClick={() => goToPage(4)}
                style={{ marginTop: '10px' }}
              >
                Atrás
              </Button>
            </form>
          </>
        )}
        {isAuthenticated && page === 6 && !isLoading && (
          <>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Información de pagos
            </Typography>
            {switchChecked && <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} style={{ marginBottom: 30 }} gutterBottom>
              {"(promoSAMSUNG activada)"}
            </Typography>}
            {switchChecked2 && isInfinix && <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
              {"(promoINFINIX activada)"}
            </Typography>}
            {switchChecked2 && !isInfinix && <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
              {"(promoHONOR activada)"}
            </Typography>}
            <Typography variant="h5" color="secondary" style={{ textAlign: 'center', color: "silver", fontSize: 16, marginBottom: 15 }} gutterBottom>
              A continuación puedes observar todas las cuotas y fechas correspondientes.
            </Typography>
            <div className={classes.border}>
              <Typography variant="h5" align="center" color="textPrimary" className={classes.title} gutterBottom>
                {'Inicial + ' + (timeToPay * 2) + ' Cuotas'}
              </Typography>
              <div style={{ marginBottom: 20, display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', paddingLeft: 75, paddingRight: 75 }}>
                <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} style={{ fontWeight: "bold" }} gutterBottom>
                  Cuota inicial (Hoy)
                </Typography>
                {!switchChecked ? <Typography variant="body1" align="center" color="textPrimary" style={{ color: '#45C4D6', fontWeight: "bold" }} gutterBottom>
                  {countrySettings.currency_code + (allowAnyProduct && productType === 'other' ? ((parseInt(price) * (0.5)) + extraInitial).toFixed(2) : Math.round((parseInt(price) * (downPaymentPerc)) + extraInitial).toFixed(2))}
                </Typography> :
                  <Typography variant="body1" align="center" color="textPrimary" style={{ color: '#45C4D6', fontWeight: "bold" }} gutterBottom>
                    {countrySettings.currency_code + Math.round((parseInt(price) * 0.5)).toFixed(2)}
                  </Typography>}
              </div>
              {/* <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
              {"IVA (16%): $xx"}
            </Typography> */}
              {getNextXDates(timeToPay * 2).map((item, index) => (
                <div style={{ marginBottom: 20, display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', paddingLeft: 75, paddingRight: 75 }}>
                  <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
                    {item[0] + " " + item[1]}
                  </Typography>
                  {!switchChecked ? <Typography variant="body1" align="center" color="textPrimary" fontWeight="bold" style={{ color: '#45C4D6' }} gutterBottom>
                    {countrySettings.currency_code + (allowAnyProduct && productType === 'other' ? (((parseInt(price) * (1 - (0.5))) * (1)) / (parseInt(timeToPay) * 2)).toFixed(2) : Math.round(((parseInt(price) * (1 - (downPaymentPerc))) * (interestFactor)) / (parseInt(timeToPay) * 2)).toFixed(2))}
                  </Typography> : <Typography variant="body1" align="center" color="textPrimary" fontWeight="bold" style={{ color: '#45C4D6' }} gutterBottom>
                    {countrySettings.currency_code + Math.round((parseInt(price) * 0.5) * 1.25 / (timeToPay * 2)).toFixed(2)}
                  </Typography>}
                </div>
              ))}
            </div>

            {!(allowAnyProduct && productType === 'other') && <><div style={{ marginBottom: 20, display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', paddingLeft: 15, paddingRight: 75 }}>
              <Typography variant="body1" align="left" color="textPrimary" className={classes.totalAmt} gutterBottom>
                Detalle
              </Typography>
              {/* <Typography variant="body1" align="center" color="textPrimary" style={{ color: '#45C4D6', marginBottom: 20, fontWeight: "bold" }} gutterBottom>
                {countrySettings.currency_code + ((parseInt(price) + extraInitial)).toFixed(2)}
              </Typography> */}
            </div>
              <div className={classes.border}>
                <div style={{ marginBottom: 20, display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', paddingLeft: 75, paddingRight: 75 }}>
                  <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} style={{ fontWeight: "bold" }} gutterBottom>
                    Subtotal
                  </Typography>
                  <Typography variant="body1" align="center" color="textPrimary" style={{ color: '#45C4D6', fontWeight: "bold" }} gutterBottom>
                    {countrySettings.currency_code + ((parseInt(price) + extraInitial)).toFixed(2)}
                  </Typography>
                </div>
                <div style={{ marginBottom: 20, display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', paddingLeft: 75, paddingRight: 75 }}>
                  <Typography variant="body1" align="center" color="textPrimary" className={classes.approvalMsg} gutterBottom>
                    {country === "venezuela" ? "Servicio de Integración Tecnológica y Avalúo Comercial" : "Cargos Administrativos e Intereses"}
                  </Typography>
                  {!switchChecked ? <Typography variant="body1" align="center" color="textPrimary" style={{ color: '#45C4D6' }} gutterBottom>
                    {countrySettings.currency_code + Math.round((Math.round(Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100) / 100) * (parseInt(timeToPay) * 2) + ((parseInt(price) * downPaymentPerc) + extraInitial)) - ((parseInt(price) + extraInitial))).toFixed(2)}
                  </Typography> :
                    <Typography variant="body1" align="center" color="textPrimary" style={{ color: '#45C4D6' }} gutterBottom>
                      {countrySettings.currency_code + Math.round(Math.round((parseInt(price) * 0.5)) + (Math.round((parseInt(price) * 0.5) * 1.25 / (timeToPay * 2)) * timeToPay * 2) - parseInt(price)).toFixed(2)}
                    </Typography>}
                </div>
                <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between', paddingLeft: 75, paddingRight: 75 }}>
                  <Typography variant="body1" align="center" color="textPrimary" className={classes.totalAmt} gutterBottom>
                    Total
                  </Typography>
                  {!switchChecked ? <Typography variant="body1" align="center" color="textPrimary" className={classes.totalAmt} style={{ color: '#45C4D6' }} gutterBottom>
                    {countrySettings.currency_code + Math.round(Math.round(Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100) / 100) * (parseInt(timeToPay) * 2) + ((parseInt(price) * downPaymentPerc) + extraInitial)).toFixed(2)}
                  </Typography> :
                    <Typography variant="body1" align="center" color="textPrimary" className={classes.totalAmt} style={{ color: '#45C4D6' }} gutterBottom>
                      {countrySettings.currency_code + (Math.round(Math.round((parseInt(price) * 0.5)) + (Math.round((parseInt(price) * 0.5) * 1.25 / (timeToPay * 2)) * timeToPay * 2) - parseInt(price)) + (parseInt(price) + extraInitial)).toFixed(2)}
                    </Typography>}
                </div>
              </div></>}
            {formError && <p className={classes.formError}>{formError}</p>}
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleTermsAccepted}>
              {productType === 'other' ? 'Finalizar Venta' : 'Siguiente'}
            </Button>
            <Button
              variant="contained"
              className={classes.welcomeButtons}
              startIcon={<ArrowBack />}
              onClick={() => goToPage(5)}
              style={{ marginTop: '10px' }}
            >
              Atrás
            </Button>
          </>
        )}

        {isAuthenticated && page === 7 && !isLoading && (
          <div style={{ alignItems: 'center' }}>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Configuración de seguridad
            </Typography>
            <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
              Ahora, el encargado(a) en la tienda va a configurar nuestro sistema de bloqueo en el dispositivo, lo cual le dará permiso a Krece para bloquear y desbloquear el equipo en caso de que se atrase con sus pagos.
            </Typography>
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => {
              listenToAnexoDocumentByIdentificationNumber();
              goToPage(10)
            }}>
              Comenzar Configuración
            </Button>
            <Button
              variant="contained"
              className={classes.welcomeButtons}
              startIcon={<ArrowBack />}
              onClick={backToWelcomePage}
              style={{ marginTop: '10px' }}
            >
              Atrás
            </Button>
          </div>
        )}
        {isAuthenticated && page === 10 && !isLoading && (
          <div style={{ alignItems: 'center' }}>
            {securityProvider === "tonic" ?
              <>
                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                  Configuración de seguridad
                </Typography>
                {productType === "laptop" ?
                  <>
                    <Typography>
                      Por favor siga los siguientes pasos ANTES de continuar.
                    </Typography>
                    <Typography style={{ marginTop: 20 }}>
                      1.⁠ ⁠Encienda la laptop.
                    </Typography>
                    <Typography>
                      2.⁠ ⁠Descarga e instala el archivo en https://krece.app/laptop.
                    </Typography>
                    <Typography>
                      3.⁠ ⁠Instale el archivo .exe y siga los pasos de instalación.
                    </Typography>
                    <Typography>
                      4. ⁠Espere 15 MINUTOS.
                    </Typography>
                    <Typography>
                      5. Ingrese el Serial y haga clic en siguiente.
                    </Typography>
                  </> :
                  <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                    Para comenzar, asegurese que el equipo se encuentre apagado.
                  </Typography>}
                <TextField
                  label={productType === "laptop" ? "Código serial" : "IMEI del equipo"}
                  name="imeiToRegister"
                  value={imeiToRegister}
                  type="password"
                  onChange={(e) => setImeiToRegister(e.target.value)}
                  className={classes.textFields}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      color: 'white', // Text color
                      borderBottom: '1px solid white'
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#D3D3D3', // Label color
                    },
                  }}
                />
                <TextField
                  label={productType === "laptop" ? "Confirme el código serial" : "Confirme el IMEI del equipo"}
                  name="imeiToRegister2"
                  value={imeiToRegister2}
                  type="password"
                  onPaste={(e) => e.preventDefault()}
                  onChange={(e) => setImeiToRegister2(e.target.value)}
                  className={classes.textFields}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      color: 'white', // Text color
                      borderBottom: '1px solid white'
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#D3D3D3', // Label color
                    },
                  }}
                />
                {formError && <p className={classes.formError}>{formError}</p>}
                <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={registerWithTonic}>
                  Siguiente
                </Button>
                <Button
                  variant="contained"
                  className={classes.welcomeButtons}
                  startIcon={<ArrowBack />}
                  onClick={() => setPage(7)}
                  style={{ marginTop: '10px' }}
                >
                  Atrás
                </Button>
              </> :
              <>
                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                  Configuración de seguridad
                </Typography>
                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                  1. Encienda el dispositivo nuevo y en la primera pantalla cambie el idioma a Español. En esa misma pantalla, toque en cualquier lugar de la pantalla 6 veces para ver un escáner de código QR.
                </Typography>
                {<img style={{ width: 150, marginRight: 50, marginBottom: 10 }} src={country === "venezuela" ? paso1 : paso1dot1} alt="Paso 1" />}
                <img style={{ width: 150, marginRight: 50 }} src={paso1dot2} alt="Paso 1.2" />
                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                  2. Escanee el siguiente código QR para iniciar la instalación del app de seguridad.
                </Typography>
                <img style={{ width: 200 }} src={qrCode} alt="Enrollment QR" />
                <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(11)}>
                  Seguir
                </Button>
                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                  <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    startIcon={<ArrowBack />}
                    onClick={() => goToPage(7)}
                    style={{ marginTop: '10px' }}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    endIcon={<ArrowForward />}
                    onClick={() => goToPage(14)}
                    style={{ marginTop: '10px' }}
                  >
                    Saltar Instrucciones
                  </Button>
                </div></>}
          </div>
        )}
        {isAuthenticated && page === 11 && !isLoading && (
          <div style={{ alignItems: 'center' }}>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Instrucciones de configuración
            </Typography>
            <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
              3. Haga clic en aceptar cuando se le solicite.
            </Typography>
            <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso2} alt="Paso2" />
            <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso3} alt="Paso3" />
            <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso4} alt="Paso4" />
            <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso5} alt="Paso5" />
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(12)}>
              Seguir
            </Button>
            <Button
              variant="contained"
              className={classes.welcomeButtons}
              startIcon={<ArrowBack />}
              onClick={() => goToPage(10)}
              style={{ marginTop: '10px' }}
            >
              Atrás
            </Button>
          </div>
        )}
        {isAuthenticated && page === 12 && !isLoading && (
          <div style={{ alignItems: 'center' }}>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Instrucciones de configuración
            </Typography>
            <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
              4. Haga clic en "Ajustes adicionales" y después en "PERMITR".
            </Typography>
            <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso6} alt="Paso6" />
            <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso7} alt="Paso7" />
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(13)}>
              Seguir
            </Button>
            <Button
              variant="contained"
              className={classes.welcomeButtons}
              startIcon={<ArrowBack />}
              onClick={() => goToPage(11)}
              style={{ marginTop: '10px' }}
            >
              Atrás
            </Button>
          </div>
        )}
        {isAuthenticated && page === 13 && !isLoading && (
          <div style={{ alignItems: 'center' }}>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Instrucciones de configuración
            </Typography>
            <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
              5. Siga las inctrucciones que se le muestran en la pantalla y cuando esté listo haga clic en "Configuración Completa"
            </Typography>
            <img style={{ width: 200, marginRight: 30, marginBottom: 10 }} src={paso8} alt="Paso8" />
            <img style={{ width: 200, marginBottom: 10 }} src={paso13} alt="Paso13" />
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(14)}>
              Seguir
            </Button>
            <Button
              variant="contained"
              className={classes.welcomeButtons}
              startIcon={<ArrowBack />}
              onClick={() => goToPage(12)}
              style={{ marginTop: '10px' }}
            >
              Atrás
            </Button>
          </div>
        )}
        {isAuthenticated && page === 14 && !isLoading && (
          <div style={{ alignItems: 'center' }}>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              Instrucciones de configuración
            </Typography>
            <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
              6. Por favor introduzca el código de confirmación de 7 digitos en la siguiente casilla.
            </Typography>
            <TextField
              label="Código de confirmación"
              name="deviceID"
              value={deviceID}
              onChange={(e) => setDeviceID(e.target.value)}
              className={classes.textFields}
              margin="normal"
              fullWidth
              InputProps={{
                style: {
                  color: 'white', // Text color
                  borderBottom: '1px solid white'
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#D3D3D3', // Label color
                },
              }}
            />
            <Button variant="text" className={classes.welcomeButtonsNoBorder} fullWidth onClick={() => setShowImei(true)} gutterBottom>
              ¿No encuentra el código de confirmación?
            </Button>
            {showImei && <div className={classes.border}><TextField
              label="Número IMEI del equipo"
              name="imeiNumber"
              value={imeiNumber}
              onChange={(e) => setImeiNumber(e.target.value)}
              error={loginError !== ''}
              helperText={loginError}
              className={classes.textFields}
              margin="normal"
              fullWidth
              InputProps={{
                style: {
                  color: 'white', // Text color
                  borderBottom: '1px solid white'
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#D3D3D3', // Label color
                },
              }}
            />
              <Button
                variant="contained"
                className={classes.welcomeButtons}
                startIcon={<Search />}
                onClick={() => searchConfirmationNumber()}
                style={{ marginTop: '10px' }}
              >
                Buscar
              </Button>
              {showConfNumber && <p style={{ color: 'white' }}>{"El código de confirmación de este equipo es: " + confNumber}</p>}
            </div>}
            <img style={{ width: 200, marginBottom: 10, marginTop: 30 }} src={paso16} alt="Paso16" />
            {!isOnline && <> <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
              7. Por último, haga clic en "Generar Contrato", el cliente debe abrir la app Krece y aceptar el contrato.
            </Typography>
              {!tokenGenerated && <Button variant="contained" className={classes.welcomeButtons} onClick={generateAnexo}>
                Generar Contrato
              </Button>}
              {anexoAccepted &&
                <div style={{ display: 'flex' }}><Typography align="center" style={{
                  color: 'white',
                  fontSize: 16,
                  fontWeight: 'bold'
                }} gutterBottom>
                  Contrato Aceptado
                </Typography>
                  <CheckCircle style={{ color: "#8BC34A", alignSelf: 'center', marginBottom: 10 }} />
                </div>
              }
              {tokenGenerated && !anexoAccepted && <Button variant="contained" className={classes.welcomeButtons} onClick={generateAnexo}>
                Generar Otro Contrato
              </Button>}
            </>}
            {formError && <p className={classes.formError}>{formError}</p>}
            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleSendDeviceID}>
              Concretar Venta
            </Button>
            <Button
              variant="contained"
              className={classes.welcomeButtons}
              startIcon={<ArrowBack />}
              onClick={() => goToPage(13)}
              style={{ marginTop: '10px' }}
            >
              Atrás
            </Button>
          </div>
        )}
        {isAuthenticated && page === 15 && !isLoading && (
          <div style={{ alignItems: 'center' }}>
            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
              ¡Configuración exitosa!
            </Typography>
            <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
              IMPORTANTE: Antes de que el cliente se lleve su celular asegurese que le llegó un email de confirmación a la tienda, eso se utilizará como comprobante en caso de cualquier problema que ocurra.
            </Typography>
            <Button
              variant="contained"
              align="center"
              className={classes.welcomeButtons}
              onClick={backToWelcomePage}
              style={{ marginTop: '10px' }}
            >
              Listo
            </Button>
          </div>
        )}
      </Container>
      {/* {
          isAuthenticated && page === 2 && !isLoading && storeEmail === "test@store.com" && (
            <div>
              <Button
                variant="contained"
                className={classes.welcomeButtons}
                startIcon={<Delete />}
                onClick={deleteTestData}
                style={{ marginTop: '10px', marginLeft: 20 }}
              >
                Delete test data
              </Button>
            </div>
          )
        } */}
    </div >
  );
};

const useStyles = makeStyles((theme) => ({
  approvalMsg: {
    textAlign: 'center', color: "silver", fontSize: 16
  },

  switch_track: {
    backgroundColor: "gray",
  },
  switch_base: {
    color: "gray",
    "&.Mui-disabled": {
      color: "gray"
    },
    "&.Mui-checked": {
      color: "gray"
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "gray",
    }
  },
  switch_primary: {
    "&.Mui-checked": {
      color: "#65C466",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#65C466",
    },
  },
  totalAmt: {
    marginBottom: theme.spacing(2), // Adjust the padding as needed
    textAlign: 'center',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold'
  },
  welcomeButtons: {
    backgroundColor: '#45C4D6',
    color: '#2A2E32',
    marginTop: 10,
    marginBottom: 10
  },
  welcomeButtons2: {
    backgroundColor: '#45C4D6',
    color: '#2A2E32',
    marginTop: 15,
    marginLeft: 15,
    height: 45,
    width: 200
  },
  textFields: {
    backgroundColor: '#1C1C1E',
    color: 'white',
    borderColor: "white",
    borderBottomColor: "white",
    borderRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    paddingLeft: 5
  },
  textFields2: {
    color: 'white',
  },
  textLabel: {
    color: 'white'
  },
  textInput: {
    backgroundColor: '#1C1C1E',
    color: 'white',
    border: "1px solid #ccc",
    borderRadius: '8px',
    padding: 8,
    fontSize: 16,
    width: 100
  },
  paymentItem: {
    width: '100%',
    paddingTop: 5,
    paddingBottom: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 10,
  },
  containerWelcome: {
    display: 'flex',
    flexDirection: 'column',
    // height: 700,
  },
  topButton: {
    flex: 1
  },
  formError: {
    color: "#e84e4f"
  },
  title: {
    color: 'white',
    marginBottom: 20,
    fontSize: 26,
    fontWeight: 'bold'
  },
  title2: {
    color: 'white',
    fontSize: 16,
    marginBottom: 10
  },
  highlighted: {
    color: '#45C4D6',
  },
  appBarButton: {
    width: 200
  },
  border: {
    borderRadius: '8px',
    border: "1px solid #ccc",
    padding: "10px",
    marginBottom: 20,
  },
  border2: {
    borderRadius: '8px',
    border: "1px solid #ccc",
    paddingRight: "15px",
    paddingLeft: "15px",
    paddingBottom: 5,
    marginBottom: 20,
    display: 'flex'
  },
  welcomeButtonsNoBorder: {
    backgroundColor: '#1C1C1E',
    color: '#45C4D6',
    marginTop: 10,
    marginBottom: 10
  },
}));

export default AdminPortal;
