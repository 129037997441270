import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from './utils';
import { buttonVariants } from './Button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn('p-3', className)}
			classNames={{
				months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
				month: 'space-y-4',
				caption: 'flex justify-center pt-1 relative items-center',
				caption_label: 'text-sm font-medium',
				nav: 'space-x-1 flex items-center',
				nav_button: cn(
					buttonVariants({ variant: 'outline' }),
					'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border-neutral-900 border-[1.5px]'
				),
				nav_button_previous: 'absolute left-1',
				nav_button_next: 'absolute right-1',
				table: 'w-full border-collapse space-y-1',
				head_row: 'flex',
				head_cell: 'text-neutral-400 rounded-lg w-9 font-normal text-[0.8rem]',
				row: 'flex w-full mt-2',
				cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-lg-r [&:has([aria-selected].day-outside)]:bg-neutral-950/50 [&:has([aria-selected])]:bg-neutral-950 first:[&:has([aria-selected])]:rounded-lg-l-md last:[&:has([aria-selected])]:rounded-lg-r-md focus-within:relative focus-within:z-20',
				day: cn(buttonVariants({ variant: 'ghost' }), 'h-9 w-9 p-0 font-normal aria-selected:opacity-100 hover:bg-neutral-950'),
				day_range_end: 'scale-105 day-range-end',
				day_range_start: 'scale-105',
				day_selected:
					' bg-neutral-100 font-semibold text-neutral-950 hover:bg-neutral-100 hover:text-neutral-950 focus:bg-neutral-100 focus:text-neutral-950',
				day_today: 'bg-neutral-950 !text-primary-300',
				day_outside:
					'day-outside text-neutral-400 opacity-50 aria-selected:bg-neutral-950/50 aria-selected:text-neutral-400 aria-selected:opacity-30',
				day_disabled: 'text-neutral-400 opacity-50',
				day_range_middle: 'aria-selected:bg-neutral-950 aria-selected:text-neutral-100',
				day_hidden: 'invisible',
				...classNames
			}}
			components={{
				IconLeft: ({ ...props }) => <ChevronLeft className="size-4 text-neutral-100" />,
				IconRight: ({ ...props }) => <ChevronRight className="size-4 text-neutral-100" />
			}}
			{...props}
		/>
	);
}
Calendar.displayName = 'Calendar';

export { Calendar };
