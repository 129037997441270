import React, { useContext } from 'react';
import Table from '../../components/Table';
import { GlobalContext } from '../../context/GlobalContext';
import { Navigate } from 'react-router-dom';

export default function DelinquentUsers() {
	const { userData } = useContext(GlobalContext);

	if (!userData?.master_user || userData.cobranza_access) Navigate({ to: '/' });

	if (!userData?.email) return null;

	return (
		<div className="h-full w-full p-4 pb-0 md:p-6">
			<Table
				userEmail={userData.master_store.id}
				endpoint="https://api.krece.app/tables/delinquent_users/"
				orderBy={{ columnName: 'Cedula', columnOrder: 'DESC' }}
				filters={[
					{
						column: 'Sucursal',
						options: [
							{ label: 'Todas las sucursales', value: 'Todas las sucursales' },
							...[...(userData?.sub_stores || [])].map((e) => ({ label: e, value: e }))
						],
						defaultValue: 'Todas las sucursales'
					}
				]}
				search_by={{ column: 'Cedula', placeholder: 'Buscar Cédula...', type: 'numeric' }}
				hideDateFilters={true}
			/>
		</div>
	);
}
