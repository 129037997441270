import classNames from 'classnames';
import { X } from 'lucide-react';
import React, { useState, ReactElement, useRef, ChangeEvent } from 'react';
import { numbers_dot, numbers_letters_dot_comma, numbers_only } from '../utils/validate';

export default function Input({
	placeholder,
	value = '',
	type = 'text',
	readonly = false,
	disabled,
	validation,
	transformation,
	allowedChars,
	clearable = true,
	maxLength,
	onChange,
	className = '',
	Icon
}: {
	placeholder: string;
	value?: string;
	type?: React.HTMLInputTypeAttribute;
	readonly?: boolean;
	disabled?: boolean;
	validation?: (value: string) => boolean;
	transformation?: (value: string) => string;
	allowedChars?: 'numeric' | 'alphanumeric' | 'numbers_only';
	clearable?: boolean;
	maxLength?: number;
	onSubmit?: (value: string) => void;
	onChange?: (value: string) => void;
	className?: string;
	Icon?: ReactElement;
}) {
	const [inputValue, setInputValue] = useState<string>(value);
	const inputEl = useRef<null | HTMLInputElement>(null);

	const [isValid, setValid] = useState(true);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;

		// Remove disallowed chars
		if (allowedChars) {
			value =
				allowedChars === 'numeric'
					? value.replaceAll(numbers_dot, '')
					: allowedChars === 'numbers_only'
						? value.replaceAll(numbers_only, '')
						: value.replaceAll(numbers_letters_dot_comma, '');
		}

		// Optional validation
		if (!value.trim() || (validation && validation(value.trim()))) setValid(true);
		else if (validation && !validation(value.trim())) setValid(false);

		// Optional transformation
		if (transformation) value = transformation(value);

		setInputValue(value);

		if (onChange) {
			onChange(value);
		}
	};

	const clearInput = () => {
		setInputValue('');
		setValid(true);
		inputEl.current?.focus();

		if (onChange) onChange('');
	};

	return (
		<div
			data-disabled={disabled || false}
			className={classNames(
				'flex h-12 items-center justify-between gap-1 overflow-hidden rounded-lg bg-neutral-950 p-0 px-3 has-[:focus]:[box-shadow:inset_0_0_0_1.5px_hsl(var(--twc-neutral-800))] data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-60',
				className,
				{ '![box-shadow:inset_0_0_0_1.5px_rgb(239_68_68_/_0.6)]': !isValid, '!pointer-events-none !select-none': readonly }
			)}
		>
			{Icon && <div className="shrink-0">{Icon}</div>}

			<input
				maxLength={maxLength}
				readOnly={readonly}
				type={type}
				className={classNames('peer w-full bg-transparent text-sm outline-none placeholder:text-neutral-300', {
					'!pointer-events-none !select-none': readonly
				})}
				placeholder={placeholder}
				ref={inputEl}
				value={inputValue}
				onChange={handleOnChange}
				tabIndex={readonly ? -1 : undefined}
			/>

			{clearable && !disabled && !readonly && (
				<button
					tabIndex={-1}
					onClick={clearInput}
					className={classNames('mr-0.5 flex h-full shrink-0 items-center justify-center gap-1', {
						'pointer-events-none opacity-0': !inputValue.trim()
					})}
				>
					<X className="size-[1.125rem]" />
				</button>
			)}
		</div>
	);
}
